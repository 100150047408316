import React from "react";
import { Navigate } from "react-router-dom";
import { authTokenSelector } from "src/store/features/auth/selectors";
import { useAppSelector } from "src/store/hook";

interface IAppRoute {
  Component: any;
  isAuthProtected: boolean;
}

const AppRoute = ({ Component, isAuthProtected }: IAppRoute) => {
  const token = useAppSelector(authTokenSelector);


  if (token || !isAuthProtected) {
    return <Component />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default AppRoute;
