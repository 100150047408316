import React from "react";
import { IRatingCard } from "./types";
import CountUp from "react-countup";
import RatingBar from "../rating-bar";
import { useAppSelector } from "src/store/hook";
import { userFeedbackDashboardSelector } from "src/store/features/user-feedback/selectors";

const RatingCardComponent = (props: IRatingCard) => {
  const userFeedbackDashboard = useAppSelector(userFeedbackDashboardSelector);

  return (
    <div className="w-full rounded-xl bg-[#1D2939] px-20 py-16">
      <div className="flex items-center justify-between gap-[22px]">
        <div>
          <div className="mb-4 text-48 font-normal leading-60 text-white">
            <CountUp
              end={parseFloat(
                userFeedbackDashboard?.yourPlatformRate?.average_rating,
              )}
              decimals={1}
            />
          </div>
          <div className="text-14 font-normal leading-24 text-white">
            {props?.title}
          </div>
        </div>
        <div className="w-[60%]">
          {userFeedbackDashboard?.yourPlatformRate?.percentages?.map(
            (e: any) => {
              return (
                <RatingBar key={e?.label} label={e?.label} value={e?.value} />
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingCardComponent;
