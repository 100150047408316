import React from "react";
import CustomModal from "src/components/molecules/custom-modal";
import { IEditKeywords } from "./types";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import SelectInput from "src/components/atoms/select-input";
import { filterEmptyTags } from "src/components/molecules/crop-modal-easy/utils";

const EditKeywordsComponent = ({
  toggle,
  isOpen,
  formik,
  loading,
}: IEditKeywords) => {
  return (
    <CustomModal toggle={toggle} isOpen={isOpen} innerClass="xl:w-[580px]">
      <div className="modal-content flex w-full flex-col rounded-xl bg-gray-400">
        <div className="flex items-center justify-between gap-2 rounded-t-xl border-b border-border-color p-24">
          <span className="text-16 font-semibold leading-24 text-white-heading">
            Edit keywords
          </span>
          <button onClick={toggle}>
            <Icon.IcModalClose />
          </button>
        </div>

        <div className="modal-body p-24">
          <div className="flex flex-col gap-4">
            <SelectInput
              label="Keywords"
              name="keywords"
              options={[]}
              containerClassName="tags-input"
              mode="tags"
              showError={Boolean(formik?.touched?.keywords)}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.keywords}
              required={false}
              placeholder="Enter keyword here"
              onChange={(val: any) => {
                formik?.setFieldValue("keywords", filterEmptyTags(val));
              }}
              value={formik?.values?.keywords}
            />
          </div>
        </div>

        <div className="flex flex-col justify-end gap-4 rounded-b-xl border-t border-border-color p-24 sm:flex-row">
          <CustomButton
            title="Cancel"
            variant="secondary"
            type="button"
            onClick={toggle}
          ></CustomButton>
          <CustomButton
            title="Save"
            variant="primary"
            type="submit"
            loading={loading}
            onClick={formik?.handleSubmit}
          ></CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditKeywordsComponent;
