import React from 'react'
import useLoadingError from 'src/hooks/useLoadingError'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { showToast } from 'src/utils'
import { TOASTR_TYPES } from 'src/types'
import { useChangeEmailMutation, useGetCurrentUserMutation } from 'src/services/auth'
import { useAppDispatch, useAppSelector } from 'src/store/hook'
import { setDrawerType } from 'src/store/features/common'
import ChangeEmailComponent from './change-email.component'
import { changeEmailSchema } from 'src/validations/change-email-schema'
import { userSelector } from 'src/store/features/auth/selectors'

const ChangePasswordContainer = () => {

  const { loading, startLoading, stopLoading } = useLoadingError();
  const [changeEmail] = useChangeEmailMutation();
  const [getCurrentUser] = useGetCurrentUserMutation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector)





  const formik = useFormik({
    initialValues: {
     email: user?.email
    },
    validationSchema: changeEmailSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit:  (values) => {


          startLoading()
          changeEmail(values).unwrap().then((res) => {
               console.log(res,"res>>>>>")
               dispatch(setDrawerType(null))
               getCurrentUser({})
               showToast(toast, TOASTR_TYPES.SUCCESS, res?.message)
          }).catch(err => {
              console.log(err,"error response>>>>")
              showToast(toast,TOASTR_TYPES.ERROR,err?.message)
          }).finally(() => {
               stopLoading()
          })



    },
  });


console.log(formik,"formik")
  
  return (
    <ChangeEmailComponent formik={formik} loading={loading}/>
  )
}

export default ChangePasswordContainer