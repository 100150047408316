import axios from "axios";
import { toast } from "react-toastify";
import { SHARE_TYPE } from "src/components/molecules/share-button/types";
import { CONSTANTS, initialBioTitles } from "src/constants";
import { ENDPOINTS } from "src/store/endpoints";
import { TOASTR_TYPES } from "src/types";
import { showToast } from "src/utils";
import { convert } from "html-to-text";

export const loginUserApi = () => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      resolve({
        name: "Test",
        email: "test@gmail.com",
      });
    }, 1000);
  });
};

export const getUserProfileStatus = (
  isPublish: any,
  adminPublishHidden: any,
) => {
  let isPublishUser = "";

  if (adminPublishHidden) {
    if (adminPublishHidden === CONSTANTS.USER_PROFILE_STATUS.PUBLISHED) {
      isPublishUser = "1";
    } else {
      isPublishUser = "0";
    }
  } else {
    isPublishUser = isPublish?.toString();
  }

  return isPublishUser;
};

export const checkForEmptyBioUser = (user: any) => {
  const findSectionDescription = (title: any) => {
    return (
      user?.sections ||
      user?.biography
        ?.find((val: any) => val?.title == title)
        ?.description?.trim()
    );
  };

  const checkForEmptyPredefinedSections = () => {
    return !initialBioTitles?.every((title: any) => {
      return findSectionDescription(title);
    });
  };

  if (
    !user?.first_name ||
    !user?.last_name ||
    !user?.profession ||
    !user?.country
  ) {
    showToast(
      toast,
      TOASTR_TYPES.ERROR,
      "Please fill the required fields in personal info",
    );
    return true;
  } else if (user?.biography?.length == 0) {
    showToast(
      toast,
      TOASTR_TYPES.ERROR,
      "You cannot publish the biography as it is currently empty",
    );
    return true;
  } else if (checkForEmptyPredefinedSections()) {
    showToast(
      toast,
      TOASTR_TYPES.ERROR,
      "Please fill all the required sections in biography",
    );
    return true;
  } else {
    return false;
  }
};

let timerId: NodeJS.Timeout;

let cancelTokenSource = axios.CancelToken.source();

export const checkEmailExists = (data: any) => {
  clearTimeout(timerId);
  // Cancel the previous request
  cancelTokenSource.cancel();

  // Create a new CancelToken source for the current request
  cancelTokenSource = axios.CancelToken.source();

  return new Promise((resolve, reject) => {
    timerId = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL + ENDPOINTS.AUTH.CHECK_EMAIL_EXISTS,
          data,
          {
            cancelToken: cancelTokenSource.token,
          },
        )
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    }, 500);
  });
};

export const makeShareUrl = (
  slug: string | undefined,
  shareType: SHARE_TYPE,
) => {
  if (shareType === SHARE_TYPE.PROFILE) {
    return `${process.env.REACT_APP_WEBSITE_URL}/members/${slug}`;
  } else if (shareType === SHARE_TYPE.ARTICLE) {
    return `${process.env.REACT_APP_WEBSITE_URL_L}/${slug}`;
  } else {
    return `${process.env.REACT_APP_WEBSITE_URL}/posts/${slug}`;
  }
};

export const checkIfProfileHiddenByAdmin = (user: any) => {
  if (user?.admin_publish_hidden == CONSTANTS.USER_PROFILE_STATUS.HIDDEN) {
    showToast(
      toast,
      TOASTR_TYPES.ERROR,
      "Please publish the user's profile before publishing a post",
    );
    return true;
  }

  return false;
};

export const extractTextFromHTML = (html: string, wordLimit?: number) => {
  let text = convert(html);

  if (wordLimit !== undefined) {
    text = text.split(/\s+/).slice(0, wordLimit).join(" ");
  }

  return text;
};

export const hasEmptyArticleSections = (values: any) => {
  return (
    !values?.sections?.every(
      (item: any) => item?.title?.trim() && item?.description?.trim(),
    ) || values?.sections?.length == 0
  );
};
