import React from "react";

const IcSharePost = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M16.5 15.5002C15.9997 15.4994 15.5045 15.5995 15.0438 15.7944C14.5831 15.9893 14.1665 16.2751 13.8187 16.6346L9.49684 13.8596C9.8342 12.9848 9.8342 12.0157 9.49684 11.1409L13.8187 8.36587C14.4267 8.98767 15.2319 9.37882 16.0965 9.47238C16.9611 9.56594 17.8314 9.35609 18.5583 8.87875C19.2852 8.4014 19.8237 7.68625 20.0814 6.85568C20.3392 6.02511 20.3003 5.13077 19.9714 4.32573C19.6424 3.52069 19.0439 2.855 18.2782 2.4426C17.5126 2.03021 16.6274 1.89674 15.7742 2.06505C14.921 2.23335 14.1528 2.69297 13.6011 3.36523C13.0494 4.03749 12.7485 4.88059 12.75 5.75024C12.7515 6.21494 12.8372 6.67552 13.0031 7.10962L8.68121 9.88462C8.16088 9.35005 7.49294 8.98282 6.7628 8.82989C6.03265 8.67696 5.27348 8.74527 4.58237 9.02609C3.89125 9.30691 3.29959 9.78748 2.88305 10.4063C2.46651 11.0252 2.24402 11.7543 2.24402 12.5002C2.24402 13.2462 2.46651 13.9753 2.88305 14.5941C3.29959 15.213 3.89125 15.6936 4.58237 15.9744C5.27348 16.2552 6.03265 16.3235 6.7628 16.1706C7.49294 16.0177 8.16088 15.6504 8.68121 15.1159L13.0031 17.8909C12.8372 18.325 12.7515 18.7855 12.75 19.2502C12.75 19.9919 12.9699 20.7169 13.382 21.3336C13.794 21.9503 14.3797 22.431 15.0649 22.7148C15.7501 22.9986 16.5041 23.0729 17.2316 22.9282C17.959 22.7835 18.6272 22.4263 19.1516 21.9019C19.6761 21.3774 20.0332 20.7093 20.1779 19.9818C20.3226 19.2544 20.2483 18.5004 19.9645 17.8152C19.6807 17.13 19.2 16.5443 18.5833 16.1322C17.9667 15.7202 17.2416 15.5002 16.5 15.5002Z"
        fill="#E0DBDB"
      />
    </svg>
  );
};

export default IcSharePost;
