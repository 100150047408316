import React from 'react'
import { Link } from 'react-router-dom'

const AuthLayoutComponent = ({ children }: any) => {
    return (

        <div className="h-full">
            <div className="xl:grid grid-cols-2 h-full">
                <div className="">
                    <div className="px-20 py-20 xl:py-28 xl:px-54">
                        <Link to={"/"}>
                            <img className="mb-26 pb-2"
                            src="/assets/images/PIO_logo.svg" width={182} height={34} alt="Brand Logo" 
                            />
                        </Link>
                        <h1 className="mb-8 text-white-heading text-24 sm:text-40 font-medium sm:font-semibold leading-32 sm:leading-54">
                            Welcome to the professional networking platform with a focus on “No Compromise“
                        </h1>
                    </div>
                    <div className="image-container hidden xl:block">
                        <img className="w-full" src="/assets/images/map.svg" alt="map"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-center pl-20 pb-20 pr-20 xl:p-0">
                    <div className="mx-auto w-[412px]">{children}</div>
                </div>
            </div>
        </div>
    )
}


export default AuthLayoutComponent