import React from 'react'

const IcSearch = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={21}
  viewBox="0 0 20 21"
  fill="none"
>
  <path
    d="M9.58268 18.0001C13.9549 18.0001 17.4993 14.4557 17.4993 10.0834C17.4993 5.71116 13.9549 2.16675 9.58268 2.16675C5.21043 2.16675 1.66602 5.71116 1.66602 10.0834C1.66602 14.4557 5.21043 18.0001 9.58268 18.0001Z"
    stroke="#8C94A4"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M18.3327 18.8334L16.666 17.1667"
    stroke="#8C94A4"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default IcSearch