import React from "react";
import CustomDatatable from "src/components/molecules/custom-datatable";
import { IDataTable } from "src/types";

const DataTableComponent = (props: IDataTable) => {
  return (
    <div>
      {/* This is a data table Container */}
      <CustomDatatable {...props} />
    </div>
  );
};

export default DataTableComponent;
