import React from 'react'

const IcUsers = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M10.0007 11.6693C12.3018 11.6693 14.1673 9.80379 14.1673 7.5026C14.1673 5.20142 12.3018 3.33594 10.0007 3.33594C7.69946 3.33594 5.83398 5.20142 5.83398 7.5026C5.83398 9.80379 7.69946 11.6693 10.0007 11.6693ZM10.0007 11.6693C6.31875 11.6693 3.33398 13.9078 3.33398 16.6693M10.0007 11.6693C13.6825 11.6693 16.6673 13.9078 16.6673 16.6693"
    stroke="#BAB8B8"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>

  )
}

export default IcUsers