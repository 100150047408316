"use client";
import React from "react";
import { useParams } from "react-router-dom";
import ConfirmDelete from "src/components/organisms/confirm-delete";

import { usePostsContext } from "src/context/posts-context";
const DeletePostComponent = () => {
  const {
    toggleConfirmDelete,
    handleDeletePost,
    isOpenConfirmDelete,
    loadingDeletePost,
  } = usePostsContext();

  const { id: userId } = useParams();

  return (
    <ConfirmDelete
      title="Would you like to delete this post?"
      message="The data you delete cannot be accessed again"
      toggle={() => toggleConfirmDelete()}
      onConfirm={() => handleDeletePost(userId)}
      onCancel={() => toggleConfirmDelete()}
      isOpen={isOpenConfirmDelete}
      loading={loadingDeletePost}
    />
  );
};

export default DeletePostComponent;
