import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PanelLayout from "src/components/layouts/panel-layout";
import ArticleView from "src/components/organisms/articles/article-view";
import PublishArticleBtn from "src/components/organisms/articles/publish-article-btn";
import SaveArticleBtn from "src/components/organisms/articles/save-article-btn";

import useQueryParams from "src/hooks/useQueryParams";
import { useGetArticleMutation } from "src/services/articles";

const ViewArticle = () => {
  const { id } = useParams();

  const [getArticle, { data }] = useGetArticleMutation();
  const { getQueryParam } = useQueryParams();

  const action = getQueryParam("action");
  useEffect(() => {
    getArticle({ id });
  }, []);

  console.log(data, "data>");

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      {action === "preview" && (
        <div className="sticky top-0 z-20 flex flex-col items-start justify-between gap-4  rounded-tl-lg rounded-tr-lg bg-gray-400 p-16 px-0 md:flex-row  md:items-center md:gap-0 md:px-16 ">
          <h3 className="text-24 font-bold leading-8 text-white-heading">
            Preview an Article
          </h3>
          <div className="relative flex gap-2">
            {action === "preview" ? (
              <Link
                to={`/admin/library/article/${id}/edit`}
                className="rounded-xl border border-white-button px-24 py-7 text-14 font-medium leading-20 text-white-button transition duration-[0.4s] hover:bg-hover-secondary-button focus:outline-none"
              >
                Back to edit
              </Link>
            ) : (
              <SaveArticleBtn />
            )}
            <PublishArticleBtn />
          </div>
        </div>
      )}

      {/* <PostView res={data} isPreview={action === "preview"} isEditable={true} /> */}
      <ArticleView
        res={data}
        isPreview={action === "preview"}
        isEditable={true}
      />
    </PanelLayout>
  );
};

export default ViewArticle;
