import * as yup from "yup";

let focusedEmailValidationResult: any = undefined; // Variable to store the result obtained during focus

export const createArticleSchema = yup.object().shape({
  type: yup.string().trim().required("Please select a type"),
  title: yup
    .string()
    .trim()
    .required("Please enter title")
    .max(255, "Title too long"),
  // subtitle: yup
  //   .string()
  //   .trim()
  //   .required("Please enter subtitle")
  //   .max(255, "Subtitle too long"),
  // description: yup.string().trim().required("Please enter description"),
  sections: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .required("Title is required")
        .test("is-unique", "Title must be unique", function (value, context) {
          let newThis: any = this;
          if (this.from) {
            const titles: any = this.from[1].value?.sections.map(
              (bio: any) => bio?.title,
            );
            const current_index: number | undefined = newThis?.options?.index;

            const isValueUnique = titles.every(
              (title: any, index: any) =>
                index === current_index || title?.trim() !== value?.trim(),
            );

            return isValueUnique;
          }

          return;
        }),
      description: yup.string().required("Description is required"),
    }),
  ),
});
