import { IResponse } from "../types";
import {
  onQueryStarted,
} from "../api.config";
import store from "src/store";
import { IDeleteFeaturedMember, IPaginationPayload } from "./types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

const featuredMembersApi = rootApi.injectEndpoints({

  endpoints: (build) => ({

    getFeaturedMembersListing: build.mutation<
      IResponse,
      Partial<IPaginationPayload>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.FEATURED_MEMBERS.GET_FEATURED_MEMBERS}?per_page=${body?.perPage}&page=${body?.page}&search=${body?.query}`,
        method: "GET"
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
     }),

    deleteFeaturedMember: build.mutation<
      IResponse,
      Partial<IDeleteFeaturedMember>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.FEATURED_MEMBERS.DELETE_FEATURED_MEMBER}?id=${body?.id}`,
        method: "DELETE"
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
     }),

    getMembersListing: build.mutation<
      IResponse,
      Partial<any>
    >({
      query: () => ({
        url: `${ENDPOINTS.FEATURED_MEMBERS.GET_MEMBERS_LISTING}`,
        method: "GET"
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
     }),




    addFeaturedMember: build.mutation<
      IResponse,
      Partial<{id: string | number}>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.FEATURED_MEMBERS.ADD_FEATURED_MEMBER}?id=${body?.id}`,
        method: "POST"
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
     }),





    }),

  
});
export const {
  useGetFeaturedMembersListingMutation,
  useDeleteFeaturedMemberMutation,
  useGetMembersListingMutation,
  useAddFeaturedMemberMutation,
} = featuredMembersApi;
