"use client";

import React, { useEffect, useRef } from "react";
import { Icon } from "src/components/atoms/icons";
import useDropdownToggle from "src/hooks/useCustomDropdown";
import { usePostsContext } from "src/context/posts-context";
import { POST_TYPES } from "src/types";
import FilterOption from "./filter-option";
import { useParams } from "react-router-dom";

const PostFilterComponent = () => {
  const { isOpen, toggleDropdown, closeDropdown } = useDropdownToggle(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { id } = useParams();

  const { setFilter, setMyPosts, setPageMyPosts, fetchDataMyPosts } =
    usePostsContext();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <button
        className="relative flex items-center gap-3"
        onClick={toggleDropdown}
      >
        <span className="leading-5relative  border-button-border flex items-center gap-[10px] rounded-md border px-16 py-10 font-medium text-white-button">
          Filter
          <Icon.IcFilter />
        </span>

        <div
          className={`dropdown-wrapper absolute right-0 top-[100%] z-30 mt-10 min-w-[224px] ${
            isOpen ? "block" : "hidden"
          }`}
        >
          {/* #3A4047 */}
          <ul className="border border-border-color bg-gray-400">
            <FilterOption
              value={POST_TYPES.ALL}
              title="All"
              onClick={() => {
                setMyPosts([]);
                setFilter(POST_TYPES.ALL);
                setPageMyPosts(2);
                fetchDataMyPosts({
                  page: 1,
                  filter: POST_TYPES.ALL,
                  userId: id,
                });
              }}
            />
            <FilterOption
              value={POST_TYPES.PUBLISH}
              title="Published"
              onClick={() => {
                setMyPosts([]);
                setFilter(POST_TYPES.PUBLISH);
                setPageMyPosts(2);
                fetchDataMyPosts({
                  page: 1,
                  filter: POST_TYPES.PUBLISH,
                  userId: id,
                });
              }}
            />
            <FilterOption
              value={POST_TYPES.DRAFT}
              title="Unpublished"
              onClick={() => {
                setMyPosts([]);
                setFilter(POST_TYPES.DRAFT);
                setPageMyPosts(2);
                fetchDataMyPosts({
                  page: 1,
                  filter: POST_TYPES.DRAFT,
                  userId: id,
                });
              }}
            />
          </ul>
        </div>
      </button>
    </div>
  );
};

export default PostFilterComponent;
