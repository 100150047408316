import React from "react";
import { sideBarData } from "./sidebar.data";
import SiderBarHeader from "../../molecules/sidebar-header";
import { NavLink } from "react-router-dom";

const SideBarComponent = ({ isSidebarOpen, toggleSidebar }: any) => {
  return (
    <>
      <div className="flex justify-between">
        <SiderBarHeader />
        {isSidebarOpen && (
          <button className="flex md:hidden" onClick={toggleSidebar}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path
                d="M18 6L6 18M18 18L6 6.00001"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        )}
      </div>

      <ul className="mr-[-10px] flex h-[90%] flex-col gap-1 overflow-y-auto pr-[10px] transition-all duration-300 ease-in-out">
        {sideBarData?.map((val, i) => (
          <li key={val?.label}>
            <NavLink
              to={val.route}
              className={({ isActive }: { isActive: boolean }) => `
                            ${isActive ? "active" : ""}   
                            nav-link flex items-center  justify-start gap-3 rounded-lg px-16 py-12 transition duration-[0.4s] hover:bg-gray-300
                            ${val.featureMember ? "featured-members" : ""}
                          `}
            >
              {val.Icon}
              <span
                className={`text-14 font-medium leading-24 text-white-para  `}
              >
                {val.label}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SideBarComponent;
