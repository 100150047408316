import { TODO } from "src/types";

export interface ISectionsEditable {
  profile: TODO;
  className?: string;
  showHeader: boolean;
  formik: TODO;
  saveOnFocusOut?: boolean;
  saveOnDragEnd?: boolean;
  handleBlur: TODO;
  contentType: CONTENT_TYPE;
}

export enum CONTENT_TYPE {
  BIO = "BIO",
  POST = "POST",
  ARTICLE = "ARTICLE",
}
