import React from 'react'
import { Link } from 'react-router-dom'
import { IEditBioBtn } from './types'

const EditBioBtnComponent = ({to,title}: IEditBioBtn) => {
  return (
     <Link to={to} className="py-7 px-24 inline-block hover:bg-hover-secondary-button transition duration-[0.4s] border border-white-button text-white-button text-14 font-medium leading-20 rounded-xl focus:outline-none">{title}</Link>
  )
}

export default EditBioBtnComponent

