import React from 'react'
import AuthLayout from 'src/components/layouts/auth-layout'
import ForgotPasswordForm from '../elements/forgot-password-form'

const FogotPassword = () => {
    return (
        <AuthLayout>
            <ForgotPasswordForm />
        </AuthLayout>
    )
}

export default FogotPassword