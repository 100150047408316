
import React from 'react'
import SignInForm from './sign-in-form.component'
import { useFormik } from 'formik'
import useLoadingError from 'src/hooks/useLoadingError';
import { useAppDispatch } from "src/store/hook";
import { loginSchema } from 'src/validations/login-schema';
import { useGetCurrentUserMutation, useLoginMutation } from 'src/services/auth';
import { persistToken } from 'src/utils';
import { setAuthToken, setUser } from "src/store/features/auth";
import { useNavigate } from 'react-router-dom';


const SignInFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const { loading, error, setErrorMsg, reset, startLoading, stopLoading } = useLoadingError()
  const [login] =
    useLoginMutation<any>();
  const [getCurrentUser] = useGetCurrentUserMutation()
  

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit:  (values) => {

      startLoading()
        login(values).unwrap().then((data) => {

          console.log(data,"data")
          persistToken(data?.data?.access_token);
          dispatch(setUser(data?.data));
          dispatch(setAuthToken(data?.data?.access_token));
          getCurrentUser({})
          navigate("/admin/users");


        }).catch((err) => {
          setErrorMsg(err?.data?.message)
        }).finally(() => {
          stopLoading()
        })
    },
  });

 



  return (
    <SignInForm formik={formik} error={error} resetError={reset} loading={loading} />
  )
}

export default SignInFormContainer