import React from "react";

const ButtonLoaderComponent = ({ loading }: any) => {
  return (
    <div
      className={`absolute left-0 right-0 mx-auto h-[24px] w-[24px] animate-spin rounded-full border-4 border-solid border-current border-r-transparent text-center align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] ${!loading ? "opacity-0" : ""}`}
    >
      <span className="!-m-px !absolute !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
    </div>
  );
};

export default ButtonLoaderComponent;
