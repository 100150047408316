"use client";
import React from "react";
import ShareFbComponent from "./share-fb.component";
import { TODO } from "src/types";
import { SHARE_PLATFORM } from "../share-button/types";

const ShareTwitterContainer = ({ shareType, handleShareLink }: TODO) => {
  const handleClick = () => {
    handleShareLink(SHARE_PLATFORM.FB);
  };

  return <ShareFbComponent handleClick={handleClick} />;
};

export default ShareTwitterContainer;
