import React from "react";
import Label from "../label";
import ErrorLabel from "../error-label";
import { ISelectInput } from "./select-input.types";
import { Select } from "antd";
const { Option } = Select;
import "./select-input.style.css";
import Chip from "../chip";
import { VARIANTS } from "src/types";
import { Icon } from "../icons";

const SelectInput = ({
  id,
  name,
  label,
  value,
  mode,
  required = false,
  errorMessage = "",
  showError,
  containerClassName = "theme-form-select",
  onSelect,
  onChange,
  options,
  className = "",
  searchable = false,
  popupClassName = "",
  placeholder,
  disabled,
  onPopupScroll,
  filteredOptions,
  onSearch,
  notFoundContent,
  onBlur,
  searchValue,
}: ISelectInput) => {
  const defaultFilterOptions = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div
      className={`${containerClassName} ${
        showError && errorMessage ? "select-country-error" : ""
      }`}
    >
      {label && (
        <Label id={id ?? name} name={label} required={required} className="" />
      )}
      <Select
        style={{ width: "100%" }}
        value={value}
        className={className}
        onSelect={(e) => {
          onSelect?.(e);
        }}
        showSearch={searchable}
        filterOption={filteredOptions || defaultFilterOptions}
        popupClassName={popupClassName}
        mode={mode}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onPopupScroll={onPopupScroll}
        tagRender={(props) => {
          return (
            <Chip
              name={props?.label}
              variant={VARIANTS.SECONDARY}
              crossIcon={
                !disabled ? (
                  <button onClick={props?.onClose} className="relative z-30">
                    <Icon.IcChipCross />
                  </button>
                ) : (
                  <></>
                )
              }
            />
          );
        }}
        onSearch={onSearch}
        notFoundContent={notFoundContent}
        onBlur={onBlur}
        searchValue={searchValue}
      >
        {options?.map((item: any, i: number) => {
          return (
            <Option value={item?.value} key={item?.value} label={item?.label}>
              <div className="flex flex-row">
                <span>{item?.label || item?.value}</span>
              </div>
            </Option>
          );
        })}
      </Select>
      {showError && <ErrorLabel message={errorMessage} />}
    </div>
  );
};

export default SelectInput;
