import React from 'react'

const IcTwitterIcon = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.2131 0.219653C0.943268 0.353745 0.573283 0.672605 0.390906 0.928231L0.059339 1.39314L0.0183786 9.81252C-0.0224319 18.1785 -0.0206374 18.2353 0.297625 18.8016C0.473723 19.1149 0.874203 19.5154 1.18768 19.6917C1.7538 20.0098 1.8115 20.0116 10.1285 19.9708C18.3173 19.9307 18.5085 19.9231 18.9123 19.6215C19.1392 19.452 19.4636 19.1276 19.6331 18.9007C19.9347 18.4971 19.9423 18.305 19.9825 10.1822C20.0226 2.08094 20.016 1.86134 19.7186 1.26114C19.5511 0.9227 19.2298 0.511157 19.0048 0.346719C18.6088 0.0571585 18.3302 0.0465446 10.1499 0.0118631C2.59527 -0.0202771 1.652 0.00169831 1.2131 0.219653ZM3.12387 3.49167C3.12387 3.53592 4.30498 5.15773 5.74845 7.0957C8.34283 10.5786 8.36989 10.6229 8.10305 10.9392C7.81169 11.2848 4.49573 14.8904 3.51254 15.931L2.91219 16.5663L3.57861 16.5611L4.24503 16.5558L6.56211 14.0205C7.83651 12.6261 8.92569 11.5016 8.98249 11.5218C9.0393 11.542 9.9316 12.6851 10.9653 14.0623L12.845 16.5663H14.8609C15.9696 16.5663 16.8769 16.5246 16.8769 16.4736C16.8769 16.4226 15.6253 14.7023 14.0956 12.6506L11.3144 8.92022L11.6664 8.52093C11.86 8.30133 12.822 7.24684 13.8038 6.17754C14.7858 5.10824 15.757 4.04852 15.9621 3.82234L16.3348 3.41125H15.7171C15.1126 3.41125 15.0546 3.45968 12.9869 5.69095C11.825 6.94487 10.8278 7.988 10.7707 8.00908C10.7136 8.03016 9.89467 7.00676 8.9508 5.73475L7.23482 3.42216L5.17934 3.41678C4.0489 3.41364 3.12387 3.44742 3.12387 3.49167ZM5.01356 4.49504C5.07589 4.59789 6.96902 7.154 9.22048 10.1756L13.3139 15.6694H14.1985C14.6849 15.6694 15.083 15.6384 15.083 15.6004C15.083 15.5626 13.1996 13.0064 10.8977 9.91985L6.7125 4.30818H5.8063C5.12044 4.30818 4.92775 4.35362 5.01356 4.49504Z"
    fill="url(#paint0_linear_6816_3994)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_6816_3994"
      x1={10}
      y1={0}
      x2={10}
      y2="19.9891"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#D06705" />
      <stop offset="0.489583" stopColor="#EF9C20" />
      <stop offset="0.979167" stopColor="#ECDA36" />
    </linearGradient>
  </defs>
</svg>




  )
}

export default IcTwitterIcon