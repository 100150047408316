import React from "react";
import { Icon } from "src/components/atoms/icons";
import { userAnalyticsSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";

const VisitorHighlightsComponent = () => {
  const userAnalytics = useAppSelector(userAnalyticsSelector);

  const data: any = [
    {
      value: "1.200",
      label: "Visitors",
      shift: "23.3%",
    },
    {
      value: "900",
      label: "Search appearances",
      shift: "23.3%",
    },
    {
      value: "2000",
      label: "Biography impressions",
      shift: "8.2%",
    },
  ];

  return (
    <div className="">
      <div className="border-b border-solid border-border-color p-16 text-14 font-semibold leading-20 text-white">
        Visitor highlights
      </div>
      <div className="grid gap-4 px-24 py-16 sm:grid-cols-12">
        {(userAnalytics?.highlights || data)?.map((e: any) => {
          return (
            <div key={e?.label} className="sm:col-span-4">
              <h6 className="mb-2 text-18 font-bold leading-28 tracking-0.36 text-white">
                {e.value}
              </h6>
              <p className="mb-8 text-12 font-semibold leading-16 tracking-0.24 text-white-para">
                {e.label}
              </p>
              {e.shift !== "0.00" && (
                <>
                  {e.shift > 0 ? (
                    <p className="flex items-baseline gap-0.5 text-12 font-semibold leading-16 tracking-0.24 text-green">
                      <Icon.IcGreenUpArrow />
                      {e.shift + "%"}
                    </p>
                  ) : (
                    <p className="flex items-center gap-0.5 text-12 font-semibold leading-16 tracking-0.24 text-error-danger-500">
                      <Icon.IcRedDownArrow />
                      {e.shift + "%"}
                    </p>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VisitorHighlightsComponent;
