import React, { useEffect, useState } from "react";
import AddUserFromComponent from "./add-user-form.component";
import { useRegisterContext } from "src/context/register-context";
import { checkObjectKeys, showToast } from "src/utils";
import {
  useAddUserMutation,
  useDashboardStatsMutation,
} from "src/services/users";
import useLoadingError from "src/hooks/useLoadingError";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import { useNavigate } from "react-router-dom";

const AddUserFormContainer = (props: any) => {
  const { formik } = useRegisterContext();
  const [addUser] = useAddUserMutation();
  const { startLoading, stopLoading, loading } = useLoadingError();
  const navigate = useNavigate();
  const [getDashboardStats] = useDashboardStatsMutation();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    formik?.resetForm();
    formik?.setValues({
      email: "",
      password: "",
      sections: [
        {
          title: "Introduction",
          description: "",
          className: "add-section-min-height",
          disabled: true,
        },
        {
          title: "Early life and education",
          description: "",
          className: "add-section-min-height",
          disabled: true,
        },
        {
          title: "Career trajectory",
          description: "",
          className: "add-section-min-height",
          disabled: true,
        },
        {
          title: "Legacy and future",
          description: "",
          className: "add-section-min-height",
          disabled: true,
        },
        {
          title: "References",
          description: "",
          className: "add-section-min-height",
          disabled: true,
        },
      ],
      keywords: [],
      firstName: "",
      lastName: "",
      country: "",
      city: "",
      dob: "",
      profession: "",
      height: "",
      officeName: "",
      education: "",
      image: "",
      isPublished: "0",
    });
    setDisabled(false);
  }, [props?.isOpen]);

  const fieldsToValidate = {
    email: true,
    firstName: true,
    lastName: true,
    password: true,
  };
  const handleSaveUser = (type: "SAVE" | "NEXT") => {
    formik?.setTouched(fieldsToValidate);
    formik?.validateForm();
    const hasError = checkObjectKeys(formik?.errors, fieldsToValidate);

    const hasEmptyValues = Object.keys(fieldsToValidate).some(
      (val: string) => formik?.values[val] == "",
    );

    if (!hasError && !hasEmptyValues) {
      if (type === "SAVE") {
        startLoading();
        addUser({
          firstName: formik?.values?.firstName,
          lastName: formik?.values?.lastName,
          email: formik?.values?.email,
          password: formik?.values?.password,
          keywords: formik?.values?.keywords,
        })
          .unwrap()
          .then((res: any) => {
            props?.fetchData({ currentPage: 1, query: "" });
            showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
            props?.toggle();
            getDashboardStats({});
          })
          .catch((err: any) => {
            console.log(err, "errror");
            showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
          })
          .finally(() => {
            stopLoading();
          });
      } else if (type === "NEXT") {
        navigate("/admin/users/create");
      }
    }
  };

  return (
    <AddUserFromComponent
      {...props}
      handleSaveUser={handleSaveUser}
      loading={loading}
      setDisabled={setDisabled}
      disabled={disabled}
    />
  );
};

export default AddUserFormContainer;
