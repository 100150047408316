import React from 'react'


import ResetPasswordForm from '../elements/reset-password-form';
import AuthLayout from 'src/components/layouts/auth-layout'

const ResetPassword = () => {
  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  )
}

export default ResetPassword