import React, { useEffect, useState } from "react";
import { IRatingBar } from "./types";
import { Progress } from "antd";

const RatingBarComponent = (props: IRatingBar) => {

    const [progressValue, setProgressValue] = useState(0);

    useEffect(() => {
      // Set a timeout to simulate a changing value (replace with your actual value change logic)
      const timeout = setTimeout(() => {
        setProgressValue(props.value);
      }, 800); // Change the duration as needed
  
      return () => clearTimeout(timeout);
    }, [props.value]);

  return (
    <div className="flex items-center gap-7 mb-4">
      <span className="text-white text-12 font-normal leading-12">
        {props?.label}
      </span>
      <Progress
        percent={progressValue}
        strokeColor="white"
        showInfo={false}
        strokeWidth={6}
        className="py-0 m-0 !leading-[0px] bg-[#4A5C74] rounded-[25px]"
        status="active"
      />
    </div>
  );
};

export default RatingBarComponent;
