import { ImageResize, Plugin } from "ckeditor5";

export default class VideoResize extends Plugin {
    static get requires() {
        return [ ImageResize ];
    }

    init() {
        const editor = this.editor;

        editor.model.schema.extend('media', { allowAttributes: 'width' });

        editor.conversion.for('downcast').add(dispatcher =>
            dispatcher.on('attribute:width:media', (evt, data, conversionApi) => {
                const { writer } = conversionApi;
                const figure = conversionApi.mapper.toViewElement(data.item);

                if (figure && figure.is('element', 'figure')) {
                    writer.setStyle('width', data.attributeNewValue, figure);
                    writer.addClass(`media-width-${data.attributeNewValue.slice(0,-1)}`, figure)
                }
            })
        );

        // Upcast: View to Model
        editor.conversion.for('upcast').attributeToAttribute({
            view: {
                name: 'figure',
                styles: {
                    width: /.+/
                }
            },
            model: {
                key: 'width',
                value: (viewElement:any) => {
                    const widthStyle = viewElement.getStyle('width');
                    return widthStyle;
                }
            }
        });
    }
}