import React, { useEffect } from "react";
import { IAddAdminUser } from "./types";
import { useFormik } from "formik";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import AddAdminUserComponent from "./add-admin-user.component";
import { adminUserSchema } from "src/validations/admin-user-schema";
import {
  useAddAdminUserMutation,
  useEditAdminUserMutation,
} from "src/services/admin-members";

const AddAdminUserContainer = (props: IAddAdminUser) => {
  const { loading, startLoading, stopLoading } = useLoadingError();
  const [addAdminUser] = useAddAdminUserMutation();
  const [editAdminUser] = useEditAdminUserMutation();

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      email: "",
      role: "",
      password: "",
    },
    validationSchema: adminUserSchema(props?.itemId),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) => {
      const addEditUser = props?.itemId ? editAdminUser : addAdminUser;

      startLoading();
      addEditUser(values)
        .unwrap()
        .then((res) => {
          showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
          props?.toggle();
          formik.resetForm();
          props?.fetchData({ currentPage: 1, query: "" });
        })
        .catch((err: any) => {
          console.log(err, "erro");
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        })
        .finally(() => {
          stopLoading();
        });
    },
  });

  useEffect(() => {
    formik?.resetForm();
  }, [props?.isOpen]);

  useEffect(() => {
    if (props?.itemId) {
      const d = props?.data?.find((item: any) => item.id == props?.itemId);
      d && formik.setValues(d);
    } else {
      formik.setValues({
        id: "",
        name: "",
        email: "",
        role: "admin",
        password: "",
      });
    }
  }, [props?.itemId, props?.isOpen]);

  return <AddAdminUserComponent {...props} loading={loading} formik={formik} />;
};

export default AddAdminUserContainer;
