import React from 'react'
import ReactPaginate from 'react-paginate'
import { Icon } from 'src/components/atoms/icons'

const PaginationComponent = ({pagination,handlePageChange}:any) => {

    return (
        <div>
        <ReactPaginate
        breakLabel="..."
        nextLabel={<div className="flex items-center gap-2">Next<Icon.IcNext /></div>}
        onPageChange={(e) => {
 
            handlePageChange(e?.selected + 1)
            
        }}
        pageRangeDisplayed={2}
        pageCount={pagination?.totalPages}
        previousLabel={<div className="flex items-center gap-2"><Icon.IcPrevious />Previous</div>}
        renderOnZeroPageCount={null}
        className="border border-transparent lg:border-border-color flex items-center rounded-lg shadow-pagination-shadow flex-wrap lg:flex-nowrap"
        previousClassName="bg-transparent lg:bg-white-button lg:hover:bg-hover-white-button transition duration-[0.4s] rounded-lg lg:rounded-r-none lg:border-r border-transparent lg:border-[#EAECF0]"
        previousLinkClassName="text-white-para lg:text-gray-800 text-12 font-medium leading-20 flex py-10 lg:pl-16 pr-16"
        nextClassName="bg-transparent lg:bg-white-button lg:hover:bg-hover-white-button transition duration-[0.4s] rounded-lg lg:rounded-l-none"
        nextLinkClassName="text-white-para lg:text-gray-800 text-12 font-medium leading-20 flex py-10 pl-16 lg:pr-16"
        pageClassName="bg-transparent lg:bg-white-button hover:bg-hover-white-button transition duration-[0.4s] border-transparent lg:border-r lg:border-[#EAECF0] rounded-lg lg:rounded-none"
        pageLinkClassName="text-white-para lg:text-gray-800 text-12 font-medium leading-20 flex py-10 px-17"
        breakClassName="bg-transparent lg:bg-white-button hover:bg-hover-white-button transition duration-[0.4s] border-transparent lg:border-r lg:border-[#EAECF0]"
        breakLinkClassName="text-white-para lg:text-gray-800 text-12 font-medium leading-20 flex py-10 px-17"
        activeClassName="!bg-hover-white-button"
        activeLinkClassName="!text-gray-800"
        disabledClassName="opacity-75 cursor-not-allowed hover:bg-transparent lg:bg-white-button hover:opacity-75"
        disabledLinkClassName="cursor-not-allowed"
        forcePage={pagination?.currentPage -1}
    />
    </div>
    )
}

export default PaginationComponent