"use client";

import { createContext, useContext, useMemo } from "react";
import { IEditorContextProps } from "./types";

import { CONTENT_TYPE } from "src/components/organisms/sections/sections-editable/types";
import { useDragDropBioMutation } from "src/services/users";

const EditorContext = createContext<IEditorContextProps>({
  handleOnDragEnd: null,
  handleDelete: null,
  handleAddSection: null,
  handleOnDragStart: null,
});

export const EditorContextProvider = ({ children }: any) => {
  const [dragDropBio] = useDragDropBioMutation();

  const handleAddSection = (formik: any) => {
    formik?.setValues({
      ...formik.values,
      sections: [
        ...(formik?.values?.sections || []),
        { title: "", description: "", className: "add-section-min-height" },
      ],
    });

    const currentScrollPos =
      window.scrollY || document.documentElement.scrollTop;

    // Scroll to 158 pixels down from the current position
    // window.scrollTo(0, currentScrollPos + 18);

    setTimeout(() => {
      window.scrollTo({
        top: currentScrollPos + 178,
        behavior: "smooth",
      });
    }, 200);
  };

  const handleDelete = (
    id: number,
    formik: any,
    saveOnFocusOut: boolean,
    callback: any,
  ) => {
    const sections = formik?.values.sections.filter(
      (bio: any, i: number) => i !== id,
    );

    formik?.setValues({
      ...formik.values,
      sections: sections,
    });

    const updatedBioErrors =
      formik?.errors?.sections?.filter((bio: any, i: number) => i !== id) || [];

    formik?.setErrors({
      ...formik.errors,
      sections: [...updatedBioErrors],
    });

    formik.errors = {
      ...formik.errors,
      sections: [...updatedBioErrors],
    };

    if (formik?.touched?.sections && formik?.touched?.sections[id]) {
      formik.touched.sections[id].description = false;
      formik.touched.sections[id].title = false;
    }

    callback?.();
  };

  const handleOnDragStart = () => {
    const elements: any = document.getElementsByClassName("ck-content");

    for (const element of elements) {
      element.blur();
    }
  };

  const handleOnDragEnd = (
    result: any,
    formik: any,
    saveOnDragEnd: boolean,
    contentType: CONTENT_TYPE,
  ) => {
    if (!result.destination) return;

    const items = Array.from(formik?.values?.sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    formik?.setValues({
      ...formik.values,
      sections: items,
    });

    if (saveOnDragEnd) {
      const payload = items
        ?.filter((item: any) => item.id)
        .map((item: any, i: number) => ({
          id: item?.id,
          order: i + 1,
        }));

      dragDropBio({ sortOrder: payload })
        .then((res: any) => {
          console.log(res, "res");
          formik?.setValues({
            ...formik.values,
            sections: items,
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    formik?.setTouched({});
  };
  const contextValue = useMemo(
    () => ({
      handleOnDragEnd,
      handleDelete,
      handleAddSection,
      handleOnDragStart,
    }),
    [handleOnDragEnd, handleDelete, handleAddSection, handleOnDragStart],
  );

  return (
    <EditorContext.Provider value={contextValue}>
      {children}
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => useContext(EditorContext);
