import React, { useState } from "react";
import { columns } from "./columns";
import DataTableComponent from "./data-table.component";
import usePagination from "src/hooks/usePagination";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import { useNavigate } from "react-router-dom";
import {
  useDeleteFeedbackMutation,
  useGetFeedbackListingMutation,
  useGetUserFeedbackDashboardMutation,
} from "src/services/user-feedback";

const DataTableContainer = () => {
  const [getUserFeedbackListing] = useGetFeedbackListingMutation();
  const [getUserFeedbackDashboard] = useGetUserFeedbackDashboardMutation();

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "user-feedback-datatable",
    mutation: getUserFeedbackListing,
  });

  const navigate = useNavigate();

  // for delete popup
  const { isOpen, toggle } = useModal();

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteFeedback] = useDeleteFeedbackMutation();

  const { loading, startLoading, stopLoading } = useLoadingError();

  const showDeletePopup = (id: number) => {
    setDeleteId(id);
    toggle();
  };

  const confirmDelete = () => {
    startLoading();
    deleteFeedback({ id: deleteId })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        fetchData({ currentPage: 1, query: "" });
        toggle();
        getUserFeedbackDashboard({});
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onRowClicked = (row: any) => {
    navigate(`/admin/user-feedback/${row?.id}`);
  };

  let descriptionText;

  if (pagination?.total == 0) {
    descriptionText = "No feedback";
  } else {
    descriptionText = `Total ${pagination?.total} feedback`;
  }

  return (
    <>
      <DataTableComponent
        title={"Feedback"}
        description={descriptionText}
        className=""
        data={data}
        columns={columns(showDeletePopup)}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        onRowClicked={onRowClicked}
        clearSearch={clearSearch}
      />

      <ConfirmDelete
        title="Are you sure you want to delete the experience?"
        message="The data you delete cannot be accessed again"
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
      />
    </>
  );
};

export default DataTableContainer;
