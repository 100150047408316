"use client";

import { Icon } from "src/components/atoms/icons";
import React from "react";
import ShareButton from "../../../molecules/share-button";
import { SHARE_TYPE } from "../../../molecules/share-button/types";
import { IPostCard } from "./types";
import { POST_TYPES, PUBLISH_POST_BTN_TYPES } from "src/types";
import PublishPostBtn from "../publish-post-btn";
import { formatDateForPost } from "src/components/molecules/crop-modal-easy/utils";
import DeletePostBtn from "../delete-post-btn";
import { Link, useParams } from "react-router-dom";
import UserInfo from "../../user-info";
import { extractTextFromHTML, truncateString } from "src/utils";

const PostCardComponent = ({ post, isEditable, href, target }: IPostCard) => {
  const { id: userId } = useParams();

  return (
    <div className="relative flex flex-col justify-between space-y-2 bg-gray-400 p-16 md:min-h-[279px]">
      <div>
        <Link
          to={href ?? `/admin/users/${userId}/post/${post?.id}`}
          className="text-0 absolute left-0 top-0 z-10 h-full w-full text-[0]"
          target={target}
        >
          {post?.title}
        </Link>
        <div className="flex justify-between gap-x-3">
          {" "}
          <UserInfo post={post} truncate={true} />
          <div className="relative z-[10] hidden items-start gap-1 text-white md:flex">
            {isEditable && (
              <>
                {" "}
                <DeletePostBtn id={post?.id} />
                <Link
                  className="flex items-center"
                  to={`/admin/users/${userId}/post/${post?.id}/edit`}
                >
                  <Icon.IcEditPost />
                </Link>
              </>
            )}

            {post?.status === POST_TYPES.PUBLISH ? (
              <ShareButton
                shareType={SHARE_TYPE.POST}
                slug={post?.slug}
                image={post?.share_img}
                username={post?.author}
              />
            ) : (
              <PublishPostBtn
                buttonType={PUBLISH_POST_BTN_TYPES.CARD}
                postId={post?.id}
              />
            )}
          </div>
        </div>
        <div className="mt-8 flex justify-between gap-x-2">
          <div className="">
            <Link
              // to={href || `/posts/${post?.slug}`}
              to={href ?? `/admin/users/${userId}/post/${post?.id}`}
              className="author-name elipsis-2 relative z-10 mb-3 inline max-w-[437px] cursor-pointer text-18 font-semibold leading-6 text-white-heading"
            >
              {truncateString(post?.title, 80)}
            </Link>
            <div className="hidden md:block">
              <p className="ellipsis-3 text-16 font-normal leading-6 text-white-para ">
                {extractTextFromHTML(post?.description)}
              </p>
            </div>
          </div>
          {post?.image && (
            <div className="h-[97px] flex-shrink-0 text-right md:h-[156px]">
              <img
                src={post?.image}
                alt="Post"
                width={156}
                height={128}
                className="h-[97px] w-[97px]  object-cover md:h-[156px] md:w-[156px]"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                loading="eager"
                title={post?.title}
              ></img>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between text-right text-12 font-medium text-white-para md:justify-end">
        <div className="relative z-20 flex gap-1 text-white md:hidden">
          {isEditable && (
            <>
              {" "}
              <DeletePostBtn id={post?.id} />
              <Link
                className="flex items-center"
                to={`/admin/users/${userId}/post/${post?.id}/edit`}
              >
                <Icon.IcEditPost />
              </Link>
            </>
          )}

          {post?.status === POST_TYPES.PUBLISH ? (
            <ShareButton
              shareType={SHARE_TYPE.POST}
              slug={post?.slug}
              image={post?.share_img}
              username={post?.author}
            />
          ) : (
            <PublishPostBtn
              buttonType={PUBLISH_POST_BTN_TYPES.CARD}
              postId={post?.id}
            />
          )}
        </div>
        <div>
          {post?.read_time_minutes} min read
          <span className="px-4 font-normal leading-7">|</span>
          {formatDateForPost(post?.created_at)}
        </div>
      </div>
    </div>
  );
};

export default PostCardComponent;
