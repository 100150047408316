import React from "react";

const LeaderBadgeComponent = () => {
  return (
    <div className="inline-flex items-center gap-x-1 rounded-[4px] bg-[#FDF5E9] px-6 py-2">
      <img
        src="/assets/images/leader_badge.svg"
        width={16}
        height={16}
        alt="Brand Logo"
        title="Brand Logo"
        loading="eager"
      />
      <span className="text-12 font-medium leading-20 text-[#924703]">
        Content Leader
      </span>
    </div>
  );
};

export default LeaderBadgeComponent;
