import React from "react";
import CustomModal from "src/components/molecules/custom-modal";
import { IAddMember } from "./types";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import SelectInput from "src/components/atoms/select-input";

const AddMemberComponent = ({
  toggle,
  isOpen,
  members,
  formik,
  loading,
}: IAddMember) => {
  return (
    <CustomModal toggle={toggle} isOpen={isOpen} innerClass="xl:w-[580px]">
      <form
        className="modal-content flex w-full flex-col rounded-xl bg-gray-400"
        onSubmit={formik?.handleSubmit}
      >
        <div className="flex items-center justify-between gap-2 rounded-t-xl border-b border-border-color p-24">
          <span className="text-16 font-semibold leading-24 text-white-heading">
            Add featured member
          </span>
          <button onClick={toggle} type="button">
            <Icon.IcModalClose />
          </button>
        </div>

        <div className="modal-body p-24">
          <div className="flex flex-col gap-4">
            <SelectInput
              label="Name"
              name="id"
              onSelect={(value: any) => {
                formik?.setFieldValue("id", value);
              }}
              value={formik?.values?.id}
              options={members?.map((member: any) => {
                return {
                  value: member?.id,
                  label: member?.name,
                };
              })}
              showError={Boolean(formik?.touched.id)}
              errorMessage={formik?.errors?.id}
              searchable={true}
              containerClassName="theme-form-select"
              required={true}
            />
          </div>
        </div>

        <div className="flex flex-col justify-end gap-4 rounded-b-xl border-t border-border-color p-24 sm:flex-row">
          <CustomButton
            title="Add featured member"
            variant="primary"
            className=""
            type="submit"
            loading={loading}
          ></CustomButton>
          <CustomButton
            title="Cancel"
            variant="secondary"
            type="button"
            onClick={toggle}
          ></CustomButton>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddMemberComponent;
