import React from 'react'
import VerifyOtpFormContainer from './elements/verify-otp-form'
import AuthLayout from 'src/components/layouts/auth-layout'

const VerifyOtp = () => {
    return (
        <AuthLayout>
            <VerifyOtpFormContainer />
        </AuthLayout>
    )
}

export default VerifyOtp