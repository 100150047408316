import React, { useEffect } from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import DataTable from "./elements/data-table";
import CardLayout from "src/components/organisms/card-layout";
import { useGetAllTopicsMutation } from "src/services/topics";

const Users = () => {
  const [getAllTopics] = useGetAllTopicsMutation();
  useEffect(() => {
    getAllTopics();
  }, []);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <CardLayout />
      <DataTable />
    </PanelLayout>
  );
};

export default Users;
