import { Icon } from "src/components/atoms/icons";
import ShareButton from "src/components/molecules/share-button";
import { SHARE_TYPE } from "src/components/molecules/share-button/types";
import React from "react";
import UserInfo from "../../user-info";
import { POST_TYPES, TODO } from "src/types";
import DeletePostBtn from "../delete-post-btn";
import { formatDateForPost } from "src/components/molecules/crop-modal-easy/utils";
import { Link, useParams } from "react-router-dom";

const PostHeaderComponent = ({ isPreview, post, isEditable }: TODO) => {
  const { id: userId, postId } = useParams();
  return (
    <div className="mb-52 flex flex-col gap-4">
      <div>
        <h1 className="text-32 font-semibold leading-10 tracking-[-0.64px] text-white-heading">
          {post?.title}
        </h1>
        <h2 className="text-22 font-medium leading-10 tracking-[-0.44px] text-white-para">
          {post?.subtitle}
        </h2>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-4">
        {" "}
        <UserInfo post={post} truncate={false} />
        {!isPreview ? (
          <div className="flex items-start gap-1 text-white">
            <p className="text-right text-12 font-medium text-white-para">
              {post?.read_time_minutes} min read
              <span className="px-4 font-normal leading-7">|</span>
              {formatDateForPost(post?.created_at)}
              <span className="px-4 font-normal leading-7">|</span>
              {post?.view_count} views
            </p>

            {isEditable && (
              <>
                <span className="px-4 text-12 font-normal leading-7 text-white-para">
                  |
                </span>
                <DeletePostBtn id={post?.id} />
                <Link to={`/admin/users/${userId}/post/${postId}/edit`}>
                  <Icon.IcEditPost />
                </Link>
              </>
            )}
            {post?.status == POST_TYPES.PUBLISH && (
              <ShareButton
                shareType={SHARE_TYPE.POST}
                slug={post?.slug}
                image={post?.share_img}
                username={post?.author}
              />
            )}
          </div>
        ) : (
          <div className="flex gap-1 text-white">
            <p className="text-right text-12 font-medium text-white-para">
              {formatDateForPost(post?.created_at)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostHeaderComponent;
