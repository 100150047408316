import React from "react";
import { Icon } from "../icons";
import { ILogoutButton } from "./types";

const LogoutButtonComponent = ({ handleLogout }: ILogoutButton) => {
  return (
    <button
      className="white-link-with-icon flex items-center gap-2 py-12 pl-16 pr-16"
      onClick={handleLogout}
    >
      <Icon.IcLogoutAdmin />
      <span className="whitespace-nowrap text-16 font-normal leading-24 text-white-heading">
        Log out
      </span>
    </button>
  );
};

export default LogoutButtonComponent;
