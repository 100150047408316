import React from 'react'
import { IRatingBar } from './types'
import RatingBarComponent from './rating-bar.component'

const RatingBarContainer = (props: IRatingBar) => {
  return (
   <RatingBarComponent {...props}/>
  )
}

export default RatingBarContainer