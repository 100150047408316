"use client";

import { createContext, useContext, useMemo, useState } from "react";
import { IShareContext } from "./types";
import useModal from "src/hooks/useModal";
import ShareModal from "src/components/organisms/share-modal";

const ShareContext = createContext<IShareContext>({
  isOpen: false,
  toggle: null,
  shareData: {},
  setShareData: null,
});

export const ShareContextProvider = ({ children }: any) => {
  const { isOpen, toggle } = useModal();

  const [shareData, setShareData] = useState({
    shareType: "",
    platform: "",
    imageLink: "",
    url: "",
    platformLink: "",
    slug: "",
  });

  const values = useMemo(
    () => ({
      isOpen,
      toggle,
      shareData,
      setShareData,
    }),
    [isOpen, toggle, shareData, setShareData],
  );

  return (
    <ShareContext.Provider value={values}>
      {children}
      <ShareModal />
    </ShareContext.Provider>
  );
};

export const useShareContext = () => useContext(ShareContext);
