"use client";
import React, { useRef } from "react";
import HeadingEditable from "src/components/atoms/heading-editable";
import Editor from "src/components/molecules/editor";
import DeleteButton from "../delete-button";
import { ISingleSectionData } from "./types";

const SingleSectionComponent = ({
  bio,
  id,
  formik,
  saveOnFocusOut,
  toggle,
  handleBlur,
}: ISingleSectionData) => {
  const divRef: any = useRef();

  return (
    <div className="pb-30">
      <div
        className="bio-section editor-wrapper group relative rounded-xl border border-border-color hover:border-blue-link"
        onBlur={(e) => saveOnFocusOut && handleBlur(e, id, divRef)}
        ref={divRef}
      >
        {formik?.values?.sections &&
          formik?.values?.sections.length > 1 &&
          !formik?.values?.sections[id]?.disabled && (
            <DeleteButton
              id={id}
              formik={formik}
              saveOnFocusOut={saveOnFocusOut}
              toggle={toggle}
            />
          )}
        <HeadingEditable id={id} bio={bio} disabled={false} formik={formik} />
        <Editor id={id} bio={bio} disabled={false} formik={formik} />
      </div>
    </div>
  );
};

export default SingleSectionComponent;
