import { useState } from "react";

import { IUseLoadMore, MUTATION_TYPE } from "./types";
import { CONSTANTS } from "src/constants";
import { useGetAuthorPostsMutation } from "src/services/posts";
import {
  useGetAllMembersMutation,
  useGetAllPostsMutation,
} from "src/services/articles";
import { TODO } from "src/types";

const useLoadMore = ({
  initialData,
  mutationType,
  defaultLoading,
  itemsPerPage,
}: IUseLoadMore) => {
  const [getMyPosts] = useGetAuthorPostsMutation();
  const [getAllMembers] = useGetAllMembersMutation();
  const [getAllPosts] = useGetAllPostsMutation();

  const [data, setData] = useState(initialData || []);
  const [page, setPage] = useState(1); // Initial page value
  const [totalPages, setTotalPages] = useState(0); // Initial page value
  const [hasMore, setHasMore] = useState(true); // Initial page value
  const [perPage] = useState(itemsPerPage ?? CONSTANTS.POSTS_PER_PAGE); // Initial page value

  const [loading, setLoading] = useState(defaultLoading);

  const loadMore = () => {
    setPage((prev: any) => prev + 1);
  };

  let action: any = {
    [MUTATION_TYPE.MY_POSTS]: getMyPosts,
    [MUTATION_TYPE.ALL_MEMBERS]: getAllMembers,
    [MUTATION_TYPE.ALL_POSTS]: getAllPosts,
  };

  const fetchAction: any = action[mutationType];

  const fetchData = ({ page, filter, userId, search }: TODO) => {
    setLoading(true);

    fetchAction?.({
      page,
      search,
      status: filter,
      per_page: perPage,
      user_id: userId,
    })
      .unwrap()
      .then((res: any) => {
        setData((prev: any) => [...prev, ...res.data.data]);
        setTotalPages(res?.data?.meta?.pagination?.total_pages);
        setHasMore(res?.data?.meta?.pagination?.links?.next);
      })
      .catch((err: any) => {
        console.log(err, "error comes here");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    hasMore,
    data,
    setData,
    page,
    setPage,
    loadMore,
    totalPages,
    fetchData,
    setLoading,
  };
};

export default useLoadMore;
