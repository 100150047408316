import React, { useEffect, useState } from "react";
import SelectInput from "src/components/atoms/select-input";
import useLoadMore from "src/hooks/useLoadMore";
import { MUTATION_TYPE } from "src/hooks/types";
import useDebounce from "src/hooks/useDebounce";

const PostsInputComponent = ({ formik, initialData }: any) => {
  const {
    data,
    loading,
    fetchData,
    setData,
    setPage,
    page,
    totalPages,
    setLoading,
  } = useLoadMore({
    initialData: [],
    mutationType: MUTATION_TYPE.ALL_POSTS,
    startPage: 1,
    defaultLoading: false,
    itemsPerPage: 10,
  });

  const [options, setOptions] = useState(data);
  const [search, setSearch] = useState("");

  useDebounce(
    () => {
      if (search) {
        setData([]);
        setPage(1);
        fetchData({ page: 1, search });
      } else {
        setData(initialData);
        fetchData({
          page: 1,
          member: "all",
        });
      }
    },
    300,
    [search],
  );
  useEffect(() => {
    console.log(data, "data??");
    const uniqueData: any = [];
    const uniqueIds: any = new Set();

    data.forEach((item: any) => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        uniqueData.push(item);
      }
    });

    console.log(uniqueData);
    setOptions(uniqueData);
  }, [data]);

  return (
    <SelectInput
      name="related_posts"
      label="Related Posts"
      value={formik?.values?.related_posts}
      showError={Boolean(formik?.touched.related_posts)}
      errorMessage={formik?.errors.related_posts}
      options={options?.map((e: any) => ({
        label: e?.title,
        value: e?.id,
      }))}
      onChange={(val: any) => {
        formik?.setFieldValue("related_posts", val);
        setSearch("");
      }}
      containerClassName="tags-input"
      placeholder="Choose posts here"
      searchable={true}
      mode="multiple"
      onPopupScroll={(e: any) => {
        const { target } = e;
        if (
          target.scrollTop + target.offsetHeight + 100 >= target.scrollHeight &&
          page < totalPages &&
          !loading
        ) {
          setPage((prev) => prev + 1);
          fetchData({ page: page + 1 });
        }
      }}
      filterOption={false}
      onSearch={(input: string) => {
        setLoading(true);
        setSearch(input);
      }}
      onBlur={() => {
        setSearch("");
      }}
      notFoundContent={
        <div className="flex h-[100px] items-center justify-center text-white-heading">
          {loading ? "Loading..." : "No Data Found"}
        </div>
      }
      searchValue={search}
    />
  );
};

export default PostsInputComponent;
