
import React from 'react'
import LogoutButtonComponent from './logout-button.component'
import { useAppDispatch } from 'src/store/hook'
import { logout } from 'src/store/features/auth/actions'
import { setAuthToken } from 'src/store/features/auth'
import { clearToken, showToast } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TOASTR_TYPES } from 'src/types'

const LogoutButtonContainer = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const handleLogout = () => {
        dispatch(logout());
        dispatch(setAuthToken(""));
        clearToken();
        navigate("/login");
        showToast(toast,TOASTR_TYPES.SUCCESS, "You are logged out successfully")
    }

    return (
       <LogoutButtonComponent handleLogout={handleLogout}/>
    )
}

export default LogoutButtonContainer