import React from 'react'

const IcFeedback = () => {
  return (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M3.33268 1.66406H16.666C17.5827 1.66406 18.3327 2.41406 18.3327 3.33073V13.3307C18.3327 14.2474 17.5827 14.9974 16.666 14.9974H4.99935L1.66602 18.3307L1.67435 3.33073C1.67435 2.41406 2.41602 1.66406 3.33268 1.66406ZM4.30768 13.3307H16.666V3.33073H3.33268V14.3057L3.81602 13.8224L4.30768 13.3307ZM9.16602 9.9974H10.8327V11.6641H9.16602V9.9974ZM10.8327 4.9974H9.16602V8.33073H10.8327V4.9974Z"
    fill="#BAB8B8"
  />
</svg>


  )
}

export default IcFeedback