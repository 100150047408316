import React from "react";
import VisitorHighlights from "../visitor-highlights";
import VisitorCard from "../visitor-card";
import { useAppSelector } from "src/store/hook";
import { userAnalyticsSelector } from "src/store/features/user/selectors";

const UserAnalyticsComponent = () => {
  const userAnalytics = useAppSelector(userAnalyticsSelector);

  return (
    <div className="bg-gray-500">
      <div className="rounded-b-xl bg-gray-300">
        <VisitorHighlights />
      </div>
      <div className="mt-24 grid gap-[22px] xl:grid-cols-12">
        {userAnalytics?.cards?.map((d: any) => {
          return <VisitorCard key={d?.title} title={d?.title} data={d?.data} />;
        })}
      </div>
    </div>
  );
};

export default UserAnalyticsComponent;
