"use client";
import React from "react";
import CreateArticleFormComponent from "./create-article-form.component";
import { TODO } from "src/types";

const CreatePostFormContainer = (props: TODO) => {
  return <CreateArticleFormComponent {...props} />;
};

export default CreatePostFormContainer;
