import React from 'react'

const IcRedDownArrow = () => {
  return (
    <svg
    width={8}
    height={10}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.69559 0.272727V6.66903L7.11178 4.24432L7.99815 5.12216L4.06065 9.05114L0.131669 5.12216L1.00099 4.24432L3.4257 6.66903V0.272727H4.69559Z"
      fill="#F04438"
    />
  </svg>
  )
}

export default IcRedDownArrow