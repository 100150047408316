import React from "react";

const IcChipCross = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.70711 8.00242L12.8571 3.85867C12.9368 3.76152 12.9776 3.63819 12.9714 3.51267C12.9653 3.38714 12.9126 3.26839 12.8238 3.17953C12.7349 3.09066 12.6161 3.03802 12.4906 3.03186C12.3651 3.02569 12.2418 3.06644 12.1446 3.14617L8.00086 7.29617L3.85711 3.14617C3.75996 3.06644 3.63663 3.02569 3.5111 3.03186C3.38558 3.03802 3.26683 3.09066 3.17796 3.17953C3.0891 3.26839 3.03646 3.38714 3.0303 3.51267C3.02413 3.63819 3.06488 3.76152 3.14461 3.85867L7.29461 8.00242L3.14461 12.1462C3.05072 12.241 2.99805 12.369 2.99805 12.5024C2.99805 12.6358 3.05072 12.7639 3.14461 12.8587C3.24018 12.9511 3.36792 13.0027 3.50086 13.0027C3.6338 13.0027 3.76154 12.9511 3.85711 12.8587L8.00086 8.70867L12.1446 12.8587C12.2402 12.9511 12.3679 13.0027 12.5009 13.0027C12.6338 13.0027 12.7615 12.9511 12.8571 12.8587C12.951 12.7639 13.0037 12.6358 13.0037 12.5024C13.0037 12.369 12.951 12.241 12.8571 12.1462L8.70711 8.00242Z"
        fill="url(#paint0_linear_5316_17092)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5316_17092"
          x1="8.00086"
          y1="3.03125"
          x2="8.00086"
          y2="13.0027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D06705" />
          <stop
            offset="0.489583"
            stopColor="#EF9C20"
          />
          <stop
            offset="0.979167"
            stopColor="#ECDA36"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IcChipCross;
