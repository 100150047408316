import { useState, useEffect } from "react";
import { useVerifyRecaptchaMutation } from "src/services/auth";

interface IRecaptchaState {
  recaptcha: string;
  setRecaptcha: (arg0: string) => void;
  isVerified: boolean;
}

const useRecaptcha = (): IRecaptchaState => {
  const [recaptcha, setRecaptcha] = useState("");

  const [verifyCatptcha, { isSuccess: isVerified, isError }] =
    useVerifyRecaptchaMutation();

  useEffect(() => {
    if (recaptcha) {
      verifyCatptcha({ token: recaptcha });
    }
  }, [recaptcha]);

  return {
    recaptcha,
    setRecaptcha,
    isVerified: recaptcha ? isVerified : isError,
  };
};

export default useRecaptcha;
