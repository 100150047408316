import { Icon } from "src/components/atoms/icons";
import { ILinkedin } from "./types";

const ShareLinkedinComponent = ({ handleClick }: ILinkedin) => {
  return (
    <li>
      <button
        className="group flex w-full items-center gap-2 py-12 pl-16 pr-16"
        onClick={handleClick}
      >
        <Icon.IcLinkedin />
        <span className="whitespace-nowrap text-14 font-medium leading-20 text-white-para transition duration-[0.4s] group-hover:text-white">
          Share on LinkedIn
        </span>
      </button>
    </li>
  );
};

export default ShareLinkedinComponent;
