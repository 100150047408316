import React from "react";
import { Icon } from "src/components/atoms/icons";
import { ICard } from "./types";
import CountUp from "react-countup";

const CardComponent = ({ title, value = 0 }: ICard) => {
  return (
    <div className="bg-card w-full rounded-xl px-20 py-16">
      <div className="flex items-center justify-between">
        <div>
          <div className="mb-4 text-40 font-bold leading-60 tracking-0.8 text-white">
            <CountUp end={+value} />
          </div>
          <div className="text-14 font-normal leading-24 text-white">
            {title}
          </div>
        </div>
        <div className="rounded-lg bg-green p-10">
          <Icon.IcCardUsersIcon />
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
