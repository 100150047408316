import React from "react";
import MemberCard from "src/components/molecules/member-card";
import { TODO } from "src/types";

const ExpertsListComponent = ({ data = [] }: TODO) => {
  return (
    <div className="order-3 flex flex-col gap-6 xl:w-[55%]">
      <h1 className="text-20 font-bold leading-[30px] text-white-heading">
        Experts in the field
      </h1>
      {data?.map((item: any) => (
        <MemberCard
          {...item}
          type="featured"
          href={`${process.env.REACT_APP_WEBSITE_URL}/member/${item?.slug}`}
        />
      ))}
    </div>
  );
};

export default ExpertsListComponent;
