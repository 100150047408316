import React from "react";
import ShareLinkedinComponent from "./share-linkedin.component";
import { TODO } from "src/types";
import { SHARE_PLATFORM } from "../share-button/types";

const ShareLinkedinContainer = ({ handleShareLink }: TODO) => {
  const handleClick = () => {
    handleShareLink(SHARE_PLATFORM.LINKED_IN);
  };

  return <ShareLinkedinComponent handleClick={handleClick} />;
};

export default ShareLinkedinContainer;
