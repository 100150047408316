import React, { useEffect } from 'react'
import AddMemberComponent from './add-member.component'
import { IAddMember } from './types'
import { useAddFeaturedMemberMutation } from 'src/services/featured-members';
import { useFormik } from 'formik';
import useLoadingError from 'src/hooks/useLoadingError';
import { featuredMemberSchema } from 'src/validations/featured-member-schema';
import { showToast } from 'src/utils';
import { toast } from 'react-toastify';
import { TOASTR_TYPES } from 'src/types';

const AddMemberContainer = (props: IAddMember) => {

  const { loading,startLoading, stopLoading } = useLoadingError();
  const [addFeaturedMember] = useAddFeaturedMemberMutation();

  const formik = useFormik({
    initialValues: {
     id: ""
    },
    validationSchema: featuredMemberSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit:  (values) => {

      startLoading()
      addFeaturedMember(values).unwrap().then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        props?.toggle();
        formik.resetForm()
        props?.getMembersListing({})
        props?.fetchData({currentPage: 1,query: ""})
      }).catch((err:any) => {
          console.log(err,"erro")
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message)        
      }).finally(() => {
          stopLoading()
      })


    }
})


useEffect(() => {
  formik?.resetForm()
},[props?.isOpen])



  return (
     <AddMemberComponent {...props} loading={loading} formik={formik}/>
  )
}

export default AddMemberContainer