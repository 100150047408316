// import Link from 'next/link'
import React from "react";

const ContentsComponent = ({
  sections,
  activeSection,
  handleSidebarItemClick,
}: any) => {
  return (
    <div className="order-2 hidden xl:order-1  xl:block xl:w-1/5 xl:max-w-[176px]">
      <div className="sticky top-[135px]">
        <div className="mb-8 text-18 font-semibold leading-28 text-blue-link">
          Contents <span>({sections?.length})</span>
        </div>
        <ul className="biography-side-bar flex flex-col gap-2">
          {sections?.map((bio: any, key: number) => {
            return (
              <li key={bio?.id}>
                <button
                  id={"section-" + key}
                  onClick={() => handleSidebarItemClick(key)}
                  className={
                    activeSection == `${key}` ? "active text-left" : "text-left"
                  }
                >
                  <span className=" cursor-pointer text-16 font-semibold leading-24 text-white-heading">
                    {bio?.title}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ContentsComponent;
