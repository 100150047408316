import React from 'react'

const IcChevronBack = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M13.0871 6.175L11.9121 5L6.91211 10L11.9121 15L13.0871 13.825L9.27044 10L13.0871 6.175Z"
    fill="#E0DBDB"
  />
</svg>


  )
}

export default IcChevronBack