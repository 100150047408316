"use client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "src/components/atoms/button";
import ConfirmDelete from "src/components/organisms/confirm-delete";

import { useRegisterContext } from "src/context/register-context";
import useModal from "src/hooks/useModal";
import { TOASTR_TYPES } from "src/types";
import { showToast } from "src/utils";

const Footer = () => {
  const { formik } = useRegisterContext();
  const navigate = useNavigate();
  const { toggle, isOpen } = useModal();

  const handleOnCancel = () => {
    toggle();
    showToast(toast, TOASTR_TYPES.SUCCESS, "User discarded successfully");
    setTimeout(() => {
      formik?.resetForm();
      navigate("/admin/users");
    }, 1500);
  };

  return (
    <>
      <div className="bg-gray-400 text-white mt-auto border-t border-border-color sticky bottom-0 w-full z-20 p-24">
        <div className="main-container">
          <div className="flex flex-col xl:flex-row items-center justify-end gap-5">
            <CustomButton
              title="Discard"
              variant="secondary"
              className="w-full xl:w-auto order-2 xl:order-1"
              onClick={() => {
                // router.push("/register")
                toggle();
              }}
            />
            <CustomButton
              title="Save & send user email"
              variant="primary"
              className="w-full xl:w-auto order-1 xl:order-2"
              onClick={() => {

                formik?.handleSubmit();
              }}
              loading={formik?.isSubmitting && formik.isValid}
            />
          </div>
        </div>
      </div>
      <ConfirmDelete
        toggle={toggle}
        isOpen={isOpen}
        onCancel={handleOnCancel}
        onConfirm={toggle}
        title="Are you sure you want to discard?"
        message="This will not save the user, and your changes will be lost."
        deleteBtnTitle="Go back"
        cancelBtnTitle="Discard user"
      />
    </>
  );
};

export default Footer;
