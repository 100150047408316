import React from 'react'
import CustomButton from 'src/components/atoms/button'
import TextInput from 'src/components/atoms/text-input'
import { IChangeEmailComponent } from './types'

const ChangeEmailComponent = ({formik, loading}: IChangeEmailComponent) => {
  return (
    <div>
        <form onSubmit={formik?.handleSubmit}>
            <div className="flex flex-col gap-4">
              <TextInput
                name='email'
                type='email'
                label='Email address'

                value={formik?.values.email}
                showError={Boolean(formik?.touched.email)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.email}
              />
            </div>
            <div className="mt-24">
              <CustomButton title="Save" loading={loading} type='submit'></CustomButton>
            </div>
        </form>
    </div>
  )
}

export default ChangeEmailComponent