import React from 'react'

const IcDelete = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M17.5 4.98698C14.725 4.71198 11.9333 4.57031 9.15 4.57031C7.5 4.57031 5.85 4.65365 4.2 4.82031L2.5 4.98698"
    stroke="#E52B36"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M7.08398 4.13906L7.26732 3.0474C7.40065 2.25573 7.50065 1.66406 8.90898 1.66406H11.0923C12.5007 1.66406 12.609 2.28906 12.734 3.05573L12.9173 4.13906"
    stroke="#E52B36"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M15.7077 7.61719L15.166 16.0089C15.0743 17.3172 14.9993 18.3339 12.6743 18.3339H7.32435C4.99935 18.3339 4.92435 17.3172 4.83268 16.0089L4.29102 7.61719"
    stroke="#E52B36"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M8.60742 13.75H11.3824"
    stroke="#E52B36"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M7.91602 10.4141H12.0827"
    stroke="#E52B36"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  

  )
}

export default IcDelete