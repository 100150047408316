import React from "react";
import { IRatingStars } from "./types";
import { Rate } from "antd";
import "./rating-stars.style.css";

const RatingStarsComponent = (props: IRatingStars) => {
  return (
    <div className="table-rating">
      <Rate
        // allowHalf
        id="rating-stars"
        disabled
        value={parseFloat(props?.value)}
        className="inline-flex items-center rounded-[40px] bg-gray-200 px-8 py-4 !text-16 text-[#D0D5DD]"
      />
    </div>
  );
};

export default RatingStarsComponent;
