import { IResponse } from "../types";
import { onQueryStarted, transFormResponse } from "../api.config";
import store from "src/store";

import { ICreateArticle, IDeleteArticle, IGetArticle } from "./types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";
import { objectToQueryParams } from "src/components/molecules/crop-modal-easy/utils";

const articlessApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    createArticle: build.mutation<IResponse, Partial<ICreateArticle>>({
      query: (body: any) => {
        body.content = body.sections.map((e: any, i: any) => ({
          title: e?.title,
          description: e?.description,
          sort_order: i + 1,
        }));
        return {
          url: `${ENDPOINTS.ARTICLES.CREATE_ARTICLE}`,
          body,
          method: "POST",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    updateArticle: build.mutation<IResponse, Partial<ICreateArticle>>({
      query: (body: any) => {
        body.content = body.sections.map((e: any, i: any) => ({
          id: e?.id,
          title: e?.title,
          description: e?.description,
          sort_order: i + 1,
        }));
        return {
          url: `${ENDPOINTS.ARTICLES.UPDATE_ARTICLE}/${body?.id}`,
          body,
          method: "PUT",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getArticle: build.mutation<IResponse, Partial<IGetArticle>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.ARTICLES.GET_ARTICLE}/${id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: any) =>
        transFormResponse(res, store.dispatch, true),
    }),

    deleteArticle: build.mutation<IResponse, Partial<IDeleteArticle>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.ARTICLES.GET_ARTICLE}/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    publishArticle: build.mutation<IResponse, Partial<IGetArticle>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.ARTICLES.GET_ARTICLE}/${id}/publish`,
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    deleteArticleSection: build.mutation<IResponse, Partial<IGetArticle>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.ARTICLES.DELETE_ARTICLE_SECTION}/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getAllArticles: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.ARTICLES.GET_ALL_ARTICLES}?per_page=${body?.perPage}&page=${body?.page}&search=${body?.query}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: any) =>
        transFormResponse(res, store.dispatch, false),
    }),
    getAllMembers: build.mutation<IResponse, Partial<IGetArticle>>({
      query: (body) => ({
        url: `${ENDPOINTS.ARTICLES.GET_ALL_MEMBERS}?${objectToQueryParams(body)}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: any) =>
        transFormResponse(res, store.dispatch, false),
    }),
    getAllPosts: build.mutation<IResponse, Partial<IGetArticle>>({
      query: (body) => ({
        url: `${ENDPOINTS.ARTICLES.GET_ALL_RELATED_POSTS}?${objectToQueryParams(body)}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: any) =>
        transFormResponse(res, store.dispatch, false),
    }),
  }),
});
export const {
  useGetArticleMutation,
  useGetAllArticlesMutation,
  useCreateArticleMutation,
  useUpdateArticleMutation,
  useDeleteArticleMutation,
  usePublishArticleMutation,
  useDeleteArticleSectionMutation,
  useGetAllMembersMutation,
  useGetAllPostsMutation,
} = articlessApi;
