import React from "react";
import { ICopyButton } from "./types";
import { Icon } from "src/components/atoms/icons";

const CopyButtonComponent = ({ handleCopyClick, isCopied }: ICopyButton) => {
  return (
    <li>
      <button onClick={handleCopyClick}>
        <div className="group flex items-center gap-2 py-12 pl-16 pr-16">
          <Icon.IcCopy />
          <span className="whitespace-nowrap text-14 font-medium leading-20 text-white-para transition duration-[0.4s] group-hover:text-white">
            Copy Link
          </span>
        </div>
      </button>
    </li>
  );
};

export default CopyButtonComponent;
