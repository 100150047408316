import IcEye from "./ic-eye";
import IcPrs from "./ic-prs";
import IcEyeOff from "./ic-eye-off";
import IcSearch from "./ic-search";
import IcCross from "./ic-cross";
import IcUploadImage from "./ic-uploadimage";
import IcPlus from "./ic-plus";
import IcSearchWhite from "./ic-search-white";
import IcNotificationBell from "./ic-notification-bell";
import IcDownArrow from "./ic-down-arrow";
import IcMyProfile from "./ic-my-profile";
import IcSettings from "./ic-settings";
import IcLogout from "./ic-logout";
import IcBackArrow from "./ic-back-arrow";
import IcPencil from "./ic-pencil";
import IcDrag from "./ic-drag";
import IcBin from "./ic-bin";
import IcDarkBin from "./ic-dark-bin";
import IcDarkUpload from "./ic-dark-upload";
import IcModalClose from "./ic-modal-close";
import IcUsers from "./ic-users";
import IcActiveUsers from "./ic-active-users";
import IcFeaturedMembers from "./ic-featured-members";
import IcHome from "./ic-home";
import IcLogoutAdmin from "./ic-logout-admin";
import IcSettingsAdmin from "./ic-settings-admin";
import IcCardUsersIcon from "./ic-card-users-icon";
import IcView from "./ic-view";
import IcDelete from "./ic-delete";
import IcPrevious from "./ic-previous";
import IcNext from "./ic-next";
import IcChevronBack from "./ic-chevron-back";
import IcEdit from "./ic-edit";
import IcOffcanvasBackArrow from "./ic-offcanvas-back-arrow";
import IcCopy from "./ic-copy";
import IcTwitter from "./ic-twitter";
import IcFacebook from "./ic-facebook";
import IcLinkedin from "./ic-linkedin";
import IcSettingsBackArrow from "./ic-settingsbackarrow";
import IcShare from "./ic-share";
import IcLinkedinIcon from "./ic-linkedin-icon";
import IcTwitterIcon from "./ic-twitter-icon";
import IcFeedback from "./ic-feedback";
import IcStarFilled from "./ic-star-filled";
import IcRedDownArrow from "./ic-red-down-arrow";
import IcGreenUpArrow from "./ic-green-up-arrow";
import IcHamburgerMenu from "./ic-hamburger-menu";
import IcTopic from "./ic-topic";
import IcChipCross from "./ic-chip-cross";
import IcSharePost from "./ic-share-post";
import IcEditPost from "./ic-edit-post";
import IcDeletePost from "./ic-delete-post";
import IcCopyCard from "./ic-copy-card";
import IcDownload from "./ic-download";
import IcFilter from "./ic-filter";
import IcVerified from "./ic-verified";
import IcLibrary from "./ic-library";

export const Icon = {
  IcEye: IcEye, // Use the imported component name here
  IcEyeOff: IcEyeOff,
  IcPrs: IcPrs,
  IcSearch: IcSearch,
  IcCross: IcCross,
  IcUploadImage: IcUploadImage,
  IcPlus: IcPlus,
  IcSearchWhite: IcSearchWhite,
  IcNotificationBell: IcNotificationBell,
  IcDownArrow: IcDownArrow,
  IcMyProfile: IcMyProfile,
  IcSettings: IcSettings,
  IcLogout: IcLogout,
  IcBackArrow: IcBackArrow,
  IcPencil: IcPencil,
  IcDrag: IcDrag,
  IcBin: IcBin,
  IcDarkBin: IcDarkBin,
  IcDarkUpload: IcDarkUpload,
  IcModalClose: IcModalClose,
  IcUsers: IcUsers,
  IcActiveUsers: IcActiveUsers,
  IcFeaturedMembers: IcFeaturedMembers,
  IcHome: IcHome,
  IcLogoutAdmin: IcLogoutAdmin,
  IcSettingsAdmin: IcSettingsAdmin,
  IcCardUsersIcon: IcCardUsersIcon,
  IcView: IcView,
  IcDelete: IcDelete,
  IcPrevious: IcPrevious,
  IcNext: IcNext,
  IcChevronBack: IcChevronBack,
  IcEdit: IcEdit,
  IcOffcanvasBackArrow: IcOffcanvasBackArrow,
  IcCopy: IcCopy,
  IcTwitter: IcTwitter,
  IcFacebook: IcFacebook,
  IcLinkedin: IcLinkedin,
  IcSettingsBackArrow: IcSettingsBackArrow,
  IcShare: IcShare,
  IcLinkedinIcon: IcLinkedinIcon,
  IcTwitterIcon: IcTwitterIcon,

  IcFeedback: IcFeedback,
  IcStarFilled: IcStarFilled,
  IcRedDownArrow: IcRedDownArrow,
  IcGreenUpArrow: IcGreenUpArrow,
  IcHamburgerMenu: IcHamburgerMenu,
  IcTopic: IcTopic,
  IcChipCross: IcChipCross,
  IcSharePost: IcSharePost,
  IcEditPost: IcEditPost,
  IcDeletePost: IcDeletePost,
  IcCopyCard: IcCopyCard,
  IcDownload: IcDownload,
  IcFilter: IcFilter,
  IcVerified: IcVerified,
  IcLibrary: IcLibrary,
};
