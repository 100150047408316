"use client";

import React from "react";
import SectionsEditable from "src/components/organisms/sections/sections-editable";
import { CONTENT_TYPE } from "src/components/organisms/sections/sections-editable/types";
import { useRegisterContext } from "src/context/register-context";

const MyBiographyComponent = () => {
  const { formik } = useRegisterContext();

  return (
    <SectionsEditable
      formik={formik}
      profile={null}
      showHeader={true}
      handleBlur={() => {}}
      contentType={CONTENT_TYPE.BIO}
      saveOnDragEnd={false}
      saveOnFocusOut={false}
      className="default-section-template biography-section-height rounded-b-xl border border-t-0 border-border-color bg-gray-400 p-20"
    />
  );
};

export default MyBiographyComponent;
