import React from "react";
import { Icon } from "src/components/atoms/icons";

const DataTableSearchComponent = ({
  query,
  handleSearch,
  clearSearch,
}: any) => {
  return (
    <div className="relative order-3 w-full xl:order-2 xl:w-[214px]">
      <input
        className="w-full text-ellipsis rounded border border-border-color bg-input-background py-11 pl-16 pr-44 text-16 font-normal leading-24 text-input-text-color placeholder:text-placeholder-text-color focus:shadow-input-shadow focus:outline-none"
        placeholder="Search"
        value={query}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />

      {query ? (
        <div className="absolute right-[14px] top-[13px] flex items-center">
          <button
            onClick={() => {
              clearSearch?.();
            }}
          >
            <Icon.IcCross />
          </button>
        </div>
      ) : (
        <div className="absolute right-[16px] top-[13px]">
          <Icon.IcSearch />
        </div>
      )}
    </div>
  );
};

export default DataTableSearchComponent;
