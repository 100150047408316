import React from "react";

const IcCopyCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.9999 15.0003C14.9333 15.0003 15.4 15.0003 15.7566 14.8187C16.0702 14.6589 16.3251 14.4039 16.4849 14.0903C16.6666 13.7338 16.6666 13.2671 16.6666 12.3337V4.33366C16.6666 3.40024 16.6666 2.93353 16.4849 2.57701C16.3251 2.2634 16.0702 2.00844 15.7566 1.84865C15.4 1.66699 14.9333 1.66699 13.9999 1.66699H9.33325C8.39983 1.66699 7.93312 1.66699 7.5766 1.84865C7.263 2.00844 7.00803 2.2634 6.84824 2.57701C6.66659 2.93353 6.66659 3.40024 6.66659 4.33366M5.99992 18.3337H10.6666C11.6 18.3337 12.0667 18.3337 12.4232 18.152C12.7368 17.9922 12.9918 17.7372 13.1516 17.4236C13.3333 17.0671 13.3333 16.6004 13.3333 15.667V7.66699C13.3333 6.73357 13.3333 6.26686 13.1516 5.91034C12.9918 5.59674 12.7368 5.34177 12.4232 5.18198C12.0667 5.00033 11.6 5.00033 10.6666 5.00033H5.99992C5.0665 5.00033 4.59979 5.00033 4.24327 5.18198C3.92966 5.34177 3.6747 5.59674 3.51491 5.91034C3.33325 6.26686 3.33325 6.73357 3.33325 7.66699V15.667C3.33325 16.6004 3.33325 17.0671 3.51491 17.4236C3.6747 17.7372 3.92966 17.9922 4.24327 18.152C4.59979 18.3337 5.0665 18.3337 5.99992 18.3337Z"
        stroke="#007BFF"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default IcCopyCard;
