"use client";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "src/components/atoms/button";
import { useBioContext } from "src/context/bio-context";
import useLoadingError from "src/hooks/useLoadingError";
import { scrollToBioError } from "src/utils";

const PreviewBtnComponent = () => {
  const { formikBio } = useBioContext();
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, startLoading, stopLoading } = useLoadingError();

  const handleOnClick = () => {
    formikBio?.submitForm();

    if (!formikBio?.errors?.sections) {
      startLoading();

      setTimeout(() => {
        navigate(`/admin/users/${id}/preview-bio`);
        stopLoading();
      }, 1500);
    } else {
      scrollToBioError();
    }
  };

  return (
    <CustomButton
      loading={loading}
      onClick={handleOnClick}
      className="relative rounded-xl border border-white-button px-24 py-7 text-14 font-medium leading-20 text-white-button transition duration-[0.4s] hover:bg-hover-secondary-button focus:outline-none"
      variant="custom"
      title="Preview"
    />
  );
};

export default PreviewBtnComponent;
