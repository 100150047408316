import React from 'react'
import { IRatingStars } from './types'
import RatingStarsComponent from './rating-stars.component'

const RatingStarsContainer = (props: IRatingStars) => {
  return (
   <RatingStarsComponent {...props}/>
  )
}

export default RatingStarsContainer