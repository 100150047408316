import React, { useEffect } from 'react'
import AuthLayout from 'src/components/layouts/auth-layout'

import SignInForm from "../elements/sign-in-form"
import { useAppSelector } from 'src/store/hook'
import { userSelector } from 'src/store/features/auth/selectors'
import { useNavigate } from 'react-router-dom'

const Login = () => {

  const user = useAppSelector(userSelector);
  const navigate = useNavigate()

  useEffect(() => { 
    if(user){
      
      navigate("/admin/users")
    }

  },[user])

  return (
    <AuthLayout>
      <div className=''>
        <SignInForm />
      </div>
    </AuthLayout>
  )
}

export default Login