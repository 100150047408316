import { IResponse, IPaginationPayload } from "../types";
import { onQueryStarted, transformErrorResponse } from "../api.config";
import store from "src/store";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";
import { setLoading } from "src/store/features/alerts";
import { setUserFeedbackDashboard } from "src/store/features/user-feedback";

const userFeedbackApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getFeedbackListing: build.mutation<IResponse, Partial<IPaginationPayload>>({
      query: (body) => ({
        url: `${ENDPOINTS.USER_FEEDBACK.GET_LISTING}?per_page=${body?.perPage}&page=${body?.page}&search=${body?.query}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getFeedback: build.mutation<IResponse, Partial<{ id: string | number }>>({
      query: (body) => ({
        url: `${ENDPOINTS.USER_FEEDBACK.GET_USER_FEEDBACK}/${body?.id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) => {
        store.dispatch(setLoading(false));
        return res;
      },
      transformErrorResponse: (res: any) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteFeedback: build.mutation<IResponse, Partial<{ id: any }>>({
      query: (body) => ({
        url: `${ENDPOINTS.USER_FEEDBACK.DELETE}?id=${body?.id}`,
        body,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getUserFeedbackDashboard: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.USER_FEEDBACK.DASHBOARD}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        store.dispatch(setUserFeedbackDashboard(res?.data));
        return res;
      },
    }),
  }),
});
export const {
  useGetFeedbackListingMutation,
  useGetFeedbackMutation,
  useDeleteFeedbackMutation,
  useGetUserFeedbackDashboardMutation,
} = userFeedbackApi;
