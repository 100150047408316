import { IResponse } from "../types";
import { onQueryStarted, transFormResponse } from "../api.config";
import store from "src/store";

import { IAuthorPosts, ICreatePost, IGetPost } from "./types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";
import { objectToQueryParams } from "src/components/molecules/crop-modal-easy/utils";

const postsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthorPosts: build.mutation<IResponse, Partial<IAuthorPosts>>({
      query: ({ user_id, ...body }) => ({
        url: `${ENDPOINTS.POSTS.GET_AUTHOR_POSTS}/${user_id}/posts?${objectToQueryParams(body)}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    createPost: build.mutation<IResponse, Partial<ICreatePost>>({
      query: (body) => ({
        url: `${ENDPOINTS.POSTS.CREATE_POST}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    updatePost: build.mutation<IResponse, Partial<ICreatePost>>({
      query: ({ id, ...body }) => ({
        url: `${ENDPOINTS.POSTS.UPDATE_POST}/${id}`,
        body,
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getPost: build.mutation<IResponse, Partial<IGetPost>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.POSTS.GET_POST}/${id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: any) =>
        transFormResponse(res, store.dispatch, true),
    }),

    deletePost: build.mutation<IResponse, Partial<IGetPost>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.POSTS.GET_POST}/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    publishPost: build.mutation<IResponse, Partial<IGetPost>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.POSTS.GET_POST}/${id}/publish`,
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    deletePostSection: build.mutation<IResponse, Partial<IGetPost>>({
      query: ({ id }) => ({
        url: `${ENDPOINTS.POSTS.DELETE_POST_SECTION}/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
  }),
});
export const {
  useGetAuthorPostsMutation,
  useGetPostMutation,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
  usePublishPostMutation,
  useDeletePostSectionMutation,
} = postsApi;
