import React from "react";
import { Icon } from "src/components/atoms/icons";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/store/hook";
import { alertsSelector } from "src/store/features/alerts/selectors";

const BreadCrumbsComponent = ({ breadcrumbs, moduleName }: any) => {
  const navigate = useNavigate();
  const alerts = useAppSelector(alertsSelector);

  return (
    <div className="order-2 flex w-full gap-4 md:order-1 md:w-auto">
      {breadcrumbs && breadcrumbs.length > 2 && (
        <button className="cursor-pointer" onClick={() => navigate(-1)}>
          <Icon.IcChevronBack />
        </button>
      )}
      <div className="">
        <div className="mb-4">
          <div className="flex flex-wrap items-center gap-2">
            <Icon.IcHome />
            {breadcrumbs.slice(0, 3).map((breadcrumb: any, index: number) => (
              <React.Fragment key={breadcrumb?.label}>
                {breadcrumb.label !== "Home" && (
                  <span className="text-12 font-light leading-18 text-white-para">
                    {index > 0 && <span className="mr-8">/</span>}
                    {breadcrumb.label}
                  </span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div
          className={`first-letter-capital text-16 font-medium leading-24 text-white-heading ${alerts?.loading ? "invisible" : "visible"}`}
        >
          {moduleName}
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbsComponent;
