import { useEffect } from "react";

const useScrollToSection = (sectionId: string, offset: number = 170) => {
  useEffect(() => {
    const scrollToSection = () => {
      const element = document.getElementById(sectionId);
      const headerElement = document.getElementById("top");

      if (element) {
        const headerHeight = headerElement ? headerElement.offsetHeight : 0;
        const topPosition =
          element.getBoundingClientRect().top +
          window.scrollY -
          (headerHeight + offset);

        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
      }
    };

    const interval = setInterval(() => {
      const elementExists = document.getElementById(sectionId);
      if (elementExists) {
        scrollToSection();
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [sectionId, offset]);
};

export default useScrollToSection;
