import React, { useEffect } from "react";
import { ICustomModal } from "./custom-modal.types";
import "./custom-modal.css";
const CustomModalComponent = ({
  children,
  isOpen,
  toggle,
  innerClass = "",
}: ICustomModal) => {
  function handleInnerDivClick(event: any) {
    event.stopPropagation();
  }

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  return (
    <div
      className={`w-100 h-100 fixed inset-0 left-0 top-0 bg-black bg-opacity-50 transition-opacity duration-500 ${
        isOpen
          ? " z-[100] overflow-y-auto overflow-x-hidden opacity-100"
          : "z-[-100] overflow-hidden opacity-0"
      }`}
      // onClick={toggle}
    >
      <div
        className={`modal-dialog modal-dialog-centered mx-auto flex w-[90%] justify-center ${innerClass}`}
        onClick={handleInnerDivClick}
      >
        {children}
      </div>
    </div>
  );
};

export default CustomModalComponent;
