import React from "react";

const IcTopic = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66602 4.16406H11.666V5.83073H1.66602V4.16406ZM1.66602 7.4974H11.666V9.16406H1.66602V7.4974ZM14.9993 10.8307V7.4974H13.3327V10.8307H9.99935V12.4974H13.3327V15.8307H14.9993V12.4974H18.3327V10.8307H14.9993ZM8.33268 12.4974H1.66602V10.8307H8.33268V12.4974Z"
        fill="#BAB8B8"
      />
    </svg>
  );
};

export default IcTopic;
