import React from 'react'

const IcGreenUpArrow = () => {
  return (
    <svg
    width={9}
    height={9}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.09233 9V2.60369L1.67614 5.02841L0.789773 4.15057L4.72727 0.221591L8.65625 4.15057L7.78693 5.02841L5.36222 2.60369V9H4.09233Z"
      fill="#0FBC0C"
    />
  </svg>
  )
}

export default IcGreenUpArrow