import React from "react";
import { ICustomDrawer } from "./types";
import { Drawer, Space } from "antd";
import "./custom-drawer.css";
import { Icon } from "src/components/atoms/icons";

const CustomDrawerComponent = (props: ICustomDrawer) => {
  return (
    <div>
      <Space></Space>
      <Drawer
        title={props?.title}
        placement="right"
        size={"default"}
        onClose={props.closeDrawer}
        open={props.isOpen}
        closeIcon={<Icon.IcOffcanvasBackArrow />}
        extra={<Space></Space>}
      >
        {props.children}
      </Drawer>
    </div>
  );
};

export default CustomDrawerComponent;
