"use client";
import React, { useEffect, useRef, useState } from "react";
import Contents from "../../profile/contents";

import { ARTICLE_TYPES, TODO } from "src/types";

import DescriptionView from "../../sections/description-view";
import RelatedProfiles from "../related-profiles";
import ExpertsList from "../experts-list";
import RelatedPosts from "../related-posts";
import ArticleHeader from "../article-header";
import {
  initialArticleValues,
  useArticlesContext,
} from "src/context/articles-context";

const ArticleViewComponent = ({ res, isPreview, isEditable }: TODO) => {
  const { formik } = useArticlesContext();

  const lastSectionRef = useRef(null);
  const [sections, setSections] = useState([]);
  const [bio, setBio] = useState<any>([]);

  useEffect(() => {
    console.log(res, "response>>>");
    formik.setValues(
      isPreview
        ? {
            id: res?.data?.id,
            status: res?.data?.status,
            topic_id: res?.data?.topic_id,
            type: res?.data?.type,
            title: res?.data?.title,
            subtitle: res?.data?.subtitle,
            description: res?.data?.description,
            image: res?.data?.image,
            image_description: res?.data?.image_description,
            sections: res?.data?.content?.map(
              ({ id, title, description }: any, i: any) => ({
                id,
                title,
                description,
                sort_order: i + 1,
              }),
            ),
            experts: res?.data?.experts?.map((e: any) => e?.id),
            related_posts: res?.data?.related_posts?.map((e: any) => e?.id),
          }
        : initialArticleValues,
    );
  }, [isPreview, res]);

  useEffect(() => {
    setBio(res?.data?.content);
  }, [res?.data]);

  useEffect(() => {
    setSections(bio?.map((e: any, i: any) => i));
  }, [bio]);

  const [activeSection, setActiveSection] = useState(0);
  const handleScroll = () => {
    const headerOffset = 100;
    const navbarHeight = 50;
    for (let i = sections?.length - 1; i >= 0; i--) {
      const targetSection = document.getElementById(sections[i]);
      if (targetSection) {
        const sectionRect = targetSection.getBoundingClientRect();
        if (sectionRect.top - headerOffset <= navbarHeight) {
          setActiveSection(i);
          break;
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sections]);

  const handleSidebarItemClick = (index: number) => {
    const targetSection = document.getElementById(sections[index]);
    if (targetSection) {
      let headerOffset = 100;
      let elementPosition: any = targetSection?.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  return (
    <>
      <ArticleHeader data={res?.data} isEditable={true} />
      <div className="flex flex-col gap-7 md:p-30 xl:flex-row xl:gap-8">
        <div className="order-2 flex w-full flex-col gap-6 xl:flex-row">
          <Contents
            sections={bio}
            activeSection={activeSection}
            handleSidebarItemClick={handleSidebarItemClick}
          />

          <div className="order-3 w-full xl:order-2 xl:w-full">
            <div className="" id={`post-content-container`}>
              {bio?.map((section: any, i: number) => {
                return (
                  <div
                    className="mb-30 border border-transparent"
                    id={sections && sections[i]}
                    key={sections[i]}
                    ref={i === sections?.length - 1 ? lastSectionRef : null}
                  >
                    <div className="relative flex items-center gap-1">
                      <h2 className="text-18 font-semibold leading-28 text-white-heading">
                        {section?.title}
                      </h2>
                    </div>
                    {i == 0 &&
                      res?.data?.type == ARTICLE_TYPES.PEOPLE &&
                      res?.data?.image && (
                        <div className="float-right mb-16 ml-32 w-[240px] rounded-[10px] bg-gray-400 p-12">
                          <img src={res?.data?.image} alt="article"></img>
                          <h4 className="break-words text-20 font-semibold leading-32 text-white-heading">
                            {res?.data?.title}
                          </h4>
                          {res?.data?.image_description && (
                            <p className="break-words text-14 font-medium leading-20 text-white-para">
                              {res?.data?.image_description}
                            </p>
                          )}
                        </div>
                      )}
                    <DescriptionView
                      description={section?.description}
                      className="!overflow-visible !p-0"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {res?.data?.type == ARTICLE_TYPES.TOPIC &&
            res?.data?.experts?.length > 0 && (
              <ExpertsList data={res?.data?.experts} />
            )}
        </div>
      </div>
      {res?.data?.type == ARTICLE_TYPES.PEOPLE &&
        res?.data?.experts?.length > 0 && (
          <div className="pb-64 pt-24 lg:pt-32">
            <RelatedProfiles data={res?.data?.experts} />
          </div>
        )}
      {res?.data?.type == ARTICLE_TYPES.TOPIC &&
        res?.data?.related_posts.length > 0 && (
          <div className="pb-64 pt-24 lg:pt-32">
            <RelatedPosts data={res?.data} />
          </div>
        )}
    </>
  );
};

export default ArticleViewComponent;
