import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PanelLayout from "src/components/layouts/panel-layout";
import BiographyViewComponent from "src/components/organisms/biography/biography-view/biography-view.component";
import { useGetUserMutation } from "src/services/users";
import { useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";
import PublishProfile from "../edit-bio/elements/publish-profile";

const ViewUser = () => {
  const otherUser = useAppSelector(otherUserSelector);

  const { id } = useParams();
  const [getUser] = useGetUserMutation<any>();

  useEffect(() => {
    getUser({ id });
  }, []);

  return (
    <PanelLayout wrapperClass={`px-24 pb-20 `}>
      <div className="flex sm:items-center py-20 flex-col sm:flex-row gap-4 sticky top-0 bg-[#0f1014] z-20">
        <h3 className="text-white-heading text-24 font-bold leading-32 tracking-0.48 break-all">
          Preview
        </h3>

        <div className="sm:ml-auto flex items-center gap-3 flex-wrap">
          <Link
            to={`/admin/users/${id}/edit-bio`}
            className="py-7 px-24 hover:bg-hover-secondary-button transition duration-[0.4s] border border-white-button text-white-button text-14 font-medium leading-20 rounded-xl focus:outline-none"
          >
            Back to edit
          </Link>
          <PublishProfile />
        </div>
      </div>

      <BiographyViewComponent
        user={otherUser}
        searchParams={{ type: "" }}
        contentsClassName="top-[97px]"
      />
    </PanelLayout>
  );
};

export default ViewUser;
