import moment from "moment";

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue: number): number {
  return (degreeValue * Math.PI) / 180;
}

interface Size {
  width: number;
  height: number;
}

export function rotateSize(
  width: number,
  height: number,
  rotation: number,
): Size {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<string | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise<string | null>((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file) {
        resolve(URL.createObjectURL(file));
      } else {
        reject(new Error("Failed to create blob."));
      }
    }, "image/jpeg");
  });
}

export const formatWebsiteUrl = (url: any) => {
  // Check if the URL starts with "https://" or "http://"
  // Trim the URL and check if it is empty or whitespace-only
  const trimmedUrl = url?.trim();
  if (!trimmedUrl) {
    // If empty or whitespace-only, return an empty string
    return "";
  }

  if (!trimmedUrl.startsWith("https://") && !url.startsWith("http://")) {
    // If not, prepend "https://"
    return `https://${url}`;
  }

  // If the URL already starts with "https://" or "http://", return as is
  return url;
};

export const truncateString = (input: string, maxLength: number = 10) => {
  if (input.length > maxLength) {
    return input.substring(0, maxLength) + "...";
  } else {
    return input;
  }
};

export const filterEmptyTags = (val: string[]) => {
  return val.filter((value: string) => value.trim() !== "");
};

export const formatDateForPost = (date: string) => {
  return moment(date).format("MMM D, YYYY");
};

export const objectToQueryParams = (obj: Record<string, any>) => {
  const queryParams = Object.keys(obj)
    .filter(
      (key) => obj[key] !== undefined && obj[key] !== null && obj[key] !== "",
    )
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
  return queryParams;
};

export const scrollToError = () => {
  setTimeout(() => {
    const errorElement = document.querySelector(".input-field-error");

    if (errorElement) {
      errorElement.scrollIntoView({
        behavior: "smooth", // You can use 'auto' or 'smooth' for the scrolling behavior
        block: "center", // You can use 'start', 'center', 'end', or 'nearest'
      });
    }
  }, 100);
};

export const splitKeywords = (keywords: string) => {
  const result = keywords ? keywords.split(",") : [];
  return result;
};
