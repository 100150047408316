import React from "react";
import CustomDrawer from "src/components/molecules/custom-drawer";
import ConfirmPublishPost from "src/components/organisms/posts/confirm-publish-post";
import DeletePost from "src/components/organisms/posts/delete-post";
import ChangeEmail from "src/pages/settings/elements/change-email";
import ChangePassword from "src/pages/settings/elements/change-password";
import { setDrawerType } from "src/store/features/common";
import { drawerTypeSelector } from "src/store/features/common/selectors";
import { useAppDispatch, useAppSelector } from "src/store/hook";

const PannelLayoutComponent = ({ children }: any) => {
  const drawerType = useAppSelector(drawerTypeSelector);
  const dispatch = useAppDispatch();

  return (
    <>
      {children}
      <CustomDrawer
        isOpen={drawerType !== null}
        closeDrawer={() => dispatch(setDrawerType(null))}
        title={`${drawerType == 1 ? "Edit email" : "Change password"}`}
      >
        <div>
          {drawerType == 1 && <ChangeEmail />}
          {drawerType == 2 && <ChangePassword />}
        </div>
      </CustomDrawer>
      <ConfirmPublishPost />
      <DeletePost />
    </>
  );
};

export default PannelLayoutComponent;
