import { useLayoutEffect, useState } from "react";

interface PaginationState {
  currentPage: number;
  itemsPerPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  query: string;
  total: number;
  totalPages: number;
  loading: boolean;
}

interface UsePaginationProps {
  key: string;
  mutation: any; // Replace YourMutationType with the actual type of your mutation
}

const usePagination = ({ key, mutation }: UsePaginationProps) => {
  const defaultPagination: PaginationState = {
    currentPage: 1,
    itemsPerPage: 10,
    hasNextPage: false,
    hasPrevPage: false,
    query: "",
    total: 0,
    totalPages: 0,
    loading: true,
  };

  const [pagination, setPagination] = useState<PaginationState>(() => {
    const storedData = sessionStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultPagination;
  });

  useLayoutEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(pagination));
  }, [
    key,
    pagination?.currentPage,
    pagination?.itemsPerPage,
    pagination?.query,
  ]);

  const [data, setData] = useState();

  const handlePageChange = (page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (pageSize: number) => {
    setPagination((prev) => ({
      ...prev,
      itemsPerPage: pageSize,
      currentPage: 1,
    }));
  };

  const handleSearch = (value: string) => {
    setPagination((prev) => ({ ...prev, currentPage: 1, query: value }));
  };

  const clearSearch = () => {
    setPagination((prev) => ({ ...prev, query: "" }));
  };

  const fetchData = ({ currentPage, query }: any) => {
    setPagination((prev) => ({ ...prev, currentPage, query, loading: true }));
    mutation({
      page: currentPage,
      perPage: pagination?.itemsPerPage,
      query: pagination?.query,
    })
      .unwrap()
      .then((res: any) => {
        setData(res?.data?.data);
        setPagination((prev) => ({
          ...prev,
          total: res?.data?.meta?.pagination?.total || 0,
          totalPages: res?.data?.meta?.pagination?.total_pages || 0,
        }));
        if (res?.data?.data?.length == 0 && pagination?.currentPage > 1) {
          setPagination((prev) => ({
            ...prev,
            currentPage: prev?.currentPage - 1,
          }));
        }
      })
      .catch((err: any) => {
        console.log(err, "errro");
      })
      .finally(() => {
        setPagination((prev) => ({ ...prev, loading: false }));
      });
  };

  useLayoutEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData({
        currentPage: pagination?.currentPage,
        query: pagination?.query,
      });
    }, 200);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pagination?.currentPage, pagination?.itemsPerPage, pagination?.query]);

  return {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  };
};

export default usePagination;
