import React from "react";
import { IAddAdminUser } from "./types";
import CustomButton from "src/components/atoms/button";
import SelectInput from "src/components/atoms/select-input";
import CustomDrawer from "src/components/molecules/custom-drawer";
import TextInput from "src/components/atoms/text-input";

const AddAdminUserComponent = ({
  toggle,
  isOpen,
  formik,
  loading,
  itemId
}: IAddAdminUser) => {

  return (

    <CustomDrawer isOpen={isOpen} closeDrawer={toggle} title={itemId ? "Edit admin user":"Add admin user" }>
      <div>
        <form onSubmit={formik?.handleSubmit}>
          <div className="flex flex-col gap-4">
            <TextInput
              name='name'
              type='text'
              label='Name'
              value={formik?.values.name}
              showError={Boolean(formik?.touched.name)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.name}
              required={true}
            />
            <TextInput
              name='email'
              type='text'
              label='Email address'
              value={formik?.values.email}
              showError={Boolean(formik?.touched.email)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.email}
              required={true}
            />
            <SelectInput
              name="role"
              label="Role"
              value={formik?.values?.role}
              showError={Boolean(formik?.touched.role)}
              errorMessage={formik?.errors.role}
              options={[{ value: "admin", label: "Admin" }]}
              onSelect={(value: string) => formik?.setFieldValue("role", value)}
              containerClassName="theme-form-select "
              required={true}

            />

            {!formik?.values?.id && <TextInput
              name='password'
              type='password'
              label='Password'
              value={formik?.values?.password}
              showError={Boolean(formik?.touched?.password)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors?.password}
              required={true}
            />}
          </div>
          <div className="mt-24">
            <CustomButton title="Save" loading={loading} type='submit'></CustomButton>
          </div>
        </form>
      </div>
    </CustomDrawer>
  );
};

export default AddAdminUserComponent;
