"use client";
import React from "react";
import CustomButton from "src/components/atoms/button";
import { ARTICLE_ACTIONS } from "src/types";
import { IPublishPostBtn } from "./types";
import useArticle from "src/hooks/useArticle";
import { useArticlesContext } from "src/context/articles-context";

const PublishArticleBtnComponent = ({ postId }: IPublishPostBtn) => {
  const { loading, handleArticleAction } = useArticle();
  const { loading: loadingArticle } = useArticlesContext();

  return (
    <CustomButton
      title="Publish"
      variant="gradient"
      className="rounded-xl bg-white-button px-24 py-9 text-14 font-medium leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button focus:outline-none"
      loading={loading}
      onClick={() => handleArticleAction(ARTICLE_ACTIONS.PUBLISH)}
      disabled={loadingArticle}
    />
  );
};

export default PublishArticleBtnComponent;
