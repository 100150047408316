import React from "react";
import { TODO } from "src/types";
import PostCard from "../../posts/post-card";

const RelatedPostsComponent = ({ data }: TODO) => {
  return (
    <div className="main-container">
      <div className="mb-24 flex flex-wrap items-center justify-between gap-2">
        <h2 className="text-24 font-semibold leading-32 text-white-heading">
          PIO-E Posts related to {data?.title}
        </h2>
      </div>

      <div className="flex w-full flex-col gap-y-8 xl:w-[70%]">
        {data?.related_posts?.map((post: any, id: any) => (
          <PostCard
            key={post?.id}
            post={post}
            isEditable={false}
            href={`${process.env.REACT_APP_WEBSITE_URL}/posts/${post?.slug}`}
            target="_blank"
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedPostsComponent;
