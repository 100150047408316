import { useFormik } from "formik";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { IContextProps } from "./types";
import { registerSchema } from "src/validations/register-schema";
import { toast } from "react-toastify";
import { initialBioValues } from "src/constants";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/types";
import useLoadingError from "src/hooks/useLoadingError";
import { useAddUserMutation } from "src/services/users";
import { useNavigate } from "react-router-dom";

const RegisterContext = createContext<IContextProps>({
  currentStep: 1,
  setCurrentStep: (): number => 1,
  formik: null,
  isProfileEdit: false,
  setIsProfileEdit: (): boolean => false,
  deleteId: undefined,
  setDeleteId: (): number => 0,
});

export const RegisterContextProvider = ({ children }: any) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const { startLoading, stopLoading } = useLoadingError();
  const [addUser] = useAddUserMutation();
  const navigate = useNavigate();

  const [initialValues] = useState({
    email: "",
    password: "",
    sections: initialBioValues,
    keywords: [],
    firstName: "",
    lastName: "",
    country: "",
    city: "",
    dob: "",
    profession: "",
    officeName: "",
    education: "",
    image: "",
    isPublished: "0",
    height: "",
    website: "",
    twitterLink: "",
    linkedinLink: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting, resetForm, setValues }) => {
      try {
        startLoading();
        addUser({
          ...values,
          biography: values?.sections?.map((bio: any, i: number) => ({
            ...bio,
            sort_order: i + 1,
          })),
        })
          .unwrap()
          .then((res: any) => {
            setTimeout(() => {
              setValues({
                email: "",
                password: "",
                keywords: [],
                sections: [
                  {
                    title: "Introduction",
                    description: "",
                    className: "add-section-min-height",
                  },
                  {
                    title: "Early life and education",
                    description: "",
                    className: "add-section-min-height",
                  },
                  {
                    title: "Career trajectory",
                    description: "",
                    className: "add-section-min-height",
                  },
                  {
                    title: "Legacy and future",
                    description: "",
                    className: "add-section-min-height",
                  },
                  {
                    title: "References",
                    description: "",
                    className: "add-section-min-height",
                  },
                ],
                firstName: "",
                lastName: "",
                country: "",
                city: "",
                dob: "",
                profession: "",
                officeName: "",
                education: "",
                image: "",
                isPublished: "0",
                height: "",
                website: "",
                twitterLink: "",
                linkedinLink: "",
              });
              sessionStorage.setItem(
                "register-form",
                JSON.stringify(initialValues),
              );
            }, 100);
            showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);

            navigate("/admin/users");
          })
          .catch((err: any) => {
            console.log(err, "errror");
            showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
            setSubmitting(false);
          })
          .finally(() => {
            stopLoading();
          });
      } catch (err: any) {
        showToast(toast, TOASTR_TYPES.ERROR, err?.message);

        console.log(err, "error");
      }
    },
  });

  // Load form data from session storage when the component mounts
  useEffect(() => {
    try {
      const storedData = JSON.parse(
        sessionStorage.getItem("register-formdata") ?? "{}",
      );

      formik.setValues({ ...initialValues, ...storedData });
    } catch (err) {
      formik.setValues({ ...initialValues });
      console.log(err, "err");
    }
  }, []);

  // Save form data to session storage whenever the form values change
  useEffect(() => {
    console.log("values", formik?.values);
    sessionStorage.setItem("register-formdata", JSON.stringify(formik.values));
  }, [formik.values]);

  const values = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      formik,
      isProfileEdit,
      setIsProfileEdit,
      deleteId,
      setDeleteId,
    }),
    [
      currentStep,
      setCurrentStep,
      formik,
      isProfileEdit,
      setIsProfileEdit,
      deleteId,
      setDeleteId,
    ],
  );

  return (
    <RegisterContext.Provider value={values}>
      {children}
    </RegisterContext.Provider>
  );
};

export const useRegisterContext = () => useContext(RegisterContext);
