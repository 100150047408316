import { ICommonState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  deleteItem: null,
  drawerType: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDeleteItem(state, action) {
      state.deleteItem = action.payload;
    },
    setDrawerType(state, action) {
      state.drawerType = action.payload;
    },
  },
});

export default commonSlice.reducer;
export const { setDeleteItem, setDrawerType } = commonSlice.actions;
