import { IResponse, IPaginationPayload } from "../types";
import {
  onQueryStarted,
  transFormResponse,
  transformErrorResponse,
} from "../api.config";
import store from "src/store";
import { IAddTopic } from "./types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";

import { setTopics } from "src/store/features/user";

const topicsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTopicsListing: build.mutation<IResponse, Partial<IPaginationPayload>>({
      query: (body) => ({
        url: `${ENDPOINTS.TOPICS.GET_LISTING}?per_page=${body?.perPage}&page=${body?.page}&search=${body?.query}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getAllTopics: build.mutation<IResponse, void>({
      query: (body) => ({
        url: `${ENDPOINTS.TOPICS.GET_ALL_TOPICS}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        store.dispatch(setTopics(res?.data));
        return res;
      },
    }),

    addTopic: build.mutation<IResponse, Partial<IAddTopic>>({
      query: (body) => {
        return {
          url: `${ENDPOINTS.TOPICS.ADD_TOPIC}`,
          body: {
            ...body,
            keywords: body.keywords?.join(","),
          },
          method: "POST",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    editTopic: build.mutation<IResponse, Partial<IAddTopic>>({
      query: (body) => {
        return {
          url: `${ENDPOINTS.TOPICS.EDIT_TOPIC}/${body?.id}`,
          body: {
            ...body,
            keywords: body.keywords?.join(","),
          },
          method: "PUT",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getTopic: build.mutation<IResponse, Partial<{ id: string | number }>>({
      query: (body) => ({
        url: `${ENDPOINTS.TOPICS.GET_TOPIC}/${body?.id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: any) =>
        transFormResponse(res, store.dispatch, true),
      transformErrorResponse: (res: any) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    deleteTopic: build.mutation<IResponse, Partial<{ id: any }>>({
      query: (body) => ({
        url: `${ENDPOINTS.TOPICS.DELETE}/${body?.id}`,
        body,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
  }),
});
export const {
  useGetTopicsListingMutation,
  useGetTopicMutation,
  useAddTopicMutation,
  useEditTopicMutation,
  useDeleteTopicMutation,
  useGetAllTopicsMutation,
} = topicsApi;
