import React from "react";
import { INoData } from "./types";

const NoDataComponent = ({ message }: INoData) => {
  return (
    <p className="mt-[48px] text-center text-16 font-normal leading-24 text-white-heading">
      {message}
    </p>
  );
};

export default NoDataComponent;
