import { ISidebarItemProps } from "./sidebar.types";
import { Icon } from "src/components/atoms/icons";
import React from "react";

export const sideBarData: ISidebarItemProps[] = [
  {
    route: "/admin/users",
    Icon: <Icon.IcUsers />,
    label: "Users",
    isDisabled: true,
    featureMember: false,
  },
  {
    route: "/admin/admin-users",
    Icon: <Icon.IcActiveUsers />,
    label: "Admin users",
    isDisabled: true,
    featureMember: false,
  },
  {
    route: "/admin/featured-members",
    Icon: <Icon.IcFeaturedMembers />,
    label: "Featured members",
    isDisabled: true,
    featureMember: true,
  },
  {
    route: "/admin/user-feedback",
    Icon: <Icon.IcFeedback />,
    label: "User feedback",
    isDisabled: true,
    featureMember: true,
  },
  {
    route: "/admin/topics",
    Icon: <Icon.IcTopic />,
    label: "Add topic",
    isDisabled: false,
    featureMember: true,
  },
  {
    route: "/admin/library",
    Icon: <Icon.IcLibrary />,
    label: "Library",
    isDisabled: false,
    featureMember: true,
  },
];
