import React from 'react'
import { IChangePasswordComponent } from './types'

     
import CustomButton from 'src/components/atoms/button'
import TextInput from 'src/components/atoms/text-input'

const ChangePasswordComponent = ({formik, loading}:IChangePasswordComponent) => {
  return (
    <div>
        <form onSubmit={formik?.handleSubmit}>
            <div className="flex flex-col gap-4">
              <TextInput
                name='currentPassword'
                type='password'
                label='Current password'
                value={formik?.values.currentPassword}
                showError={Boolean(formik?.touched.currentPassword)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.currentPassword}
                required={true}
              />
              <TextInput
                name='newPassword'
                type='password'
                label='New Password'
                value={formik?.values.newPassword}
                showError={Boolean(formik?.touched.newPassword)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.newPassword}
                required={true}
              />
              <TextInput
                name='repeatPassword'
                type='password'
                label='Repeat Password'
                value={formik?.values.repeatPassword}
                showError={Boolean(formik?.touched.repeatPassword)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.repeatPassword}
                required={true}
              />
            </div>
            <div className="mt-24">
              <CustomButton title="Save" loading={loading} type='submit'></CustomButton>
            </div>
        </form>
    </div>
  )
}

export default ChangePasswordComponent