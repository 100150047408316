import React, { useEffect } from 'react'
import CardLayoutComponent from './card-layout.component'
import { useDashboardStatsMutation } from 'src/services/users';
import { useAppSelector } from 'src/store/hook';
import { dashboardSelector } from 'src/store/features/user/selectors';

const CardLayoutContainer = () => {


    const [getDashboardStats] = useDashboardStatsMutation();
    const dashboard =  useAppSelector(dashboardSelector)

    useEffect(() => {
      getDashboardStats({})
    },[])
  
    return (
        <CardLayoutComponent data={dashboard}/>
    )
}

export default CardLayoutContainer