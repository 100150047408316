import React from "react";

const IcDeletePost = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M6 19.5C6 20.6 6.9 21.5 8 21.5H16C17.1 21.5 18 20.6 18 19.5V7.5H6V19.5ZM19 4.5H15.5L14.5 3.5H9.5L8.5 4.5H5V6.5H19V4.5Z"
        fill="#D92D20"
      />
    </svg>
  );
};

export default IcDeletePost;
