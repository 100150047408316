import React from 'react'
import Card from 'src/components/molecules/cards'
import { ICardLayout } from './types'

const CardLayoutComponent = ({data}: ICardLayout) => {
    return (

        <div className="flex items-center gap-[23px] pb-12 flex-wrap xl:flex-nowrap mb-20">
            <Card title='Registered Users' value={data?.register_user}/>
            <Card title='Published Users' value={data?.published_user}/>
            <Card title='Daily New User Registrations' value={data?.daily_user}/>
        </div>
    )
}

export default CardLayoutComponent