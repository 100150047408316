import React from 'react'

const IcShare = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M13.7499 12.5C13.3331 12.4993 12.9204 12.5827 12.5365 12.7451C12.1526 12.9075 11.8054 13.1457 11.5156 13.4453L7.91401 11.1328C8.19515 10.4038 8.19515 9.59623 7.91401 8.86719L11.5156 6.55469C12.0222 7.07286 12.6932 7.39882 13.4137 7.47679C14.1342 7.55475 14.8594 7.37988 15.4652 6.98209C16.071 6.5843 16.5197 5.98834 16.7345 5.2962C16.9493 4.60406 16.9169 3.85877 16.6428 3.18791C16.3687 2.51704 15.8699 1.9623 15.2318 1.61864C14.5938 1.27497 13.8562 1.16375 13.1451 1.30401C12.4341 1.44426 11.794 1.82728 11.3342 2.38749C10.8745 2.94771 10.6238 3.6503 10.6249 4.375C10.6262 4.76225 10.6977 5.14607 10.8359 5.50782L7.23432 7.82032C6.80072 7.37485 6.2441 7.06882 5.63564 6.94138C5.02719 6.81393 4.39455 6.87086 3.81862 7.10488C3.24269 7.33889 2.74964 7.73937 2.40252 8.25509C2.05541 8.77081 1.87 9.37834 1.87 10C1.87 10.6217 2.05541 11.2292 2.40252 11.7449C2.74964 12.2606 3.24269 12.6611 3.81862 12.8951C4.39455 13.1291 5.02719 13.1861 5.63564 13.0586C6.2441 12.9312 6.80072 12.6252 7.23432 12.1797L10.8359 14.4922C10.6977 14.8539 10.6262 15.2378 10.6249 15.625C10.6249 16.2431 10.8082 16.8473 11.1516 17.3612C11.495 17.8751 11.983 18.2756 12.5541 18.5121C13.1251 18.7486 13.7534 18.8105 14.3596 18.69C14.9658 18.5694 15.5226 18.2718 15.9597 17.8347C16.3967 17.3977 16.6943 16.8409 16.8149 16.2347C16.9355 15.6285 16.8736 15.0001 16.6371 14.4291C16.4005 13.8581 16 13.37 15.4861 13.0267C14.9722 12.6833 14.368 12.5 13.7499 12.5Z"
    fill="#0F1014"
  />
</svg>
  )
}

export default IcShare