import React from 'react'
import { IEditBioBtn } from './types'
import EditBioBtnComponent from './edit-bio-btn.component'

const EditBioBtnContainer = (props: IEditBioBtn) => {
  return (
     <EditBioBtnComponent {...props}/>
  )
}

export default EditBioBtnContainer