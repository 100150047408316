import React from "react";
import DatatableFooterComponent from "./datatable-footer.component";
import { IDataTable } from "src/types";

const DatatableFooterContainer = ({
  pagination,
  handlePageChange,
  handleItemsPerPageChange,
}: Partial<IDataTable>) => {
  return (
    <DatatableFooterComponent
      pagination={pagination}
      handlePageChange={handlePageChange}
      handleItemsPerPageChange={handleItemsPerPageChange}
    />
  );
};

export default DatatableFooterContainer;
