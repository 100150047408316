import React from "react";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import TextArea from "src/components/atoms/textarea";
import SelectInput from "src/components/atoms/select-input";

import "./style.css";
import { IAddTopicForm } from "./types";
import { ACTION_TYPE } from "src/types";
import { filterEmptyTags } from "src/components/molecules/crop-modal-easy/utils";

const AddTopicFormComponent = ({
  isOpen,
  toggle,
  formik,
  actionType,
}: IAddTopicForm) => {
  return (
    <CustomModal
      isOpen={isOpen}
      openModal={toggle}
      toggle={toggle}
      innerClass="xl:w-[580px]"
    >
      <form
        className="modal-content flex w-full flex-col rounded-xl bg-gray-400"
        onSubmit={formik?.handleSubmit}
      >
        <div className="flex items-center justify-between gap-2 rounded-t-xl border-b border-border-color p-24">
          <span className="text-16 font-semibold leading-24 text-white-heading">
            {actionType === ACTION_TYPE.ADD ? "Add" : "Edit"} topic
          </span>
          <button
            onClick={() => {
              toggle();
              formik?.resetForm();
            }}
            type="button"
          >
            <Icon.IcModalClose />
          </button>
        </div>

        <div className="modal-body p-24">
          <div className="flex flex-col gap-4">
            <TextInput
              name="title"
              type="text"
              label="Title"
              value={formik?.values?.title}
              showError={Boolean(formik?.touched?.title)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.title}
              required={true}
              placeholder="Enter title"
            />
            <TextArea
              label="Description"
              name="description"
              value={formik?.values?.description}
              showError={Boolean(formik?.touched?.description)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.description}
              required={true}
              placeholder="Add description"
            />
            <SelectInput
              label="Keywords"
              name="keywords"
              options={[]}
              containerClassName="tags-input"
              mode="tags"
              showError={Boolean(formik?.touched?.keywords)}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.keywords}
              required={true}
              placeholder="Enter keyword here"
              onChange={(val: any) => {
                formik?.setFieldValue("keywords", filterEmptyTags(val));
              }}
              value={formik?.values?.keywords}
            />
          </div>
        </div>

        <div className="flex flex-col gap-3 rounded-b-xl border-t border-border-color p-24 md:flex-row">
          <CustomButton
            type="button"
            title="Cancel"
            variant="secondary"
            className="order-2 md:order-2 md:ml-auto"
            onClick={() => {
              toggle();
              formik?.resetForm();
            }}
          ></CustomButton>
          <CustomButton
            type="submit"
            title={actionType === ACTION_TYPE.ADD ? "Add" : "Update"}
            variant="primary"
            className="order-1 md:order-3"
            loading={formik?.isSubmitting}
            disabled={formik?.isSubmitting}
          ></CustomButton>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddTopicFormComponent;
