import React from "react";
import { useFormik } from "formik";
import ForgotPasswordForm from "./forgot-password-form.component";
import { forgotPasswordSchema } from "src/validations/forgot-password-schema";
import moment from "moment";
import useLoadingError from "src/hooks/useLoadingError";
import { useForgotPasswordMutation } from "src/services/auth";
import { useNavigate } from "react-router-dom";

const ForgotPasswordFormContainer = () => {
  const navigate = useNavigate();
  const { loading, error, setErrorMsg, reset, startLoading, stopLoading } =
    useLoadingError();
  const [forgotPassword] = useForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      startLoading();
      forgotPassword(values)
        .unwrap()
        .then((res: any) => {
          console.log("am sucess", res);
          const { email } = formik.values;
          navigate(
            `/verify-otp?token=${res?.token}&email=${encodeURIComponent(email)}`,
          );
          sessionStorage.setItem("otpRequestTimestamp", moment().format());
        })
        .catch((err) => {
          console.log("==err", err);

          setErrorMsg(err?.data?.message);
        })
        .finally(() => {
          stopLoading();
        });
    },
  });

  return (
    <ForgotPasswordForm
      formik={formik}
      error={error}
      resetError={reset}
      loading={loading}
    />
  );
};

export default ForgotPasswordFormContainer;
