import React from "react";
import { IConfirmDeleteModal } from "./confirm-delete.component.types";
import { Icon } from "src/components/atoms/icons";
import CustomButton from "src/components/atoms/button";
import CustomModal from "src/components/molecules/custom-modal";

const ConfirmDeleteComponent = ({
  title,
  message,
  deleteBtnTitle = "Delete",
  cancelBtnTitle = "Cancel",
  isOpen,
  onConfirm,
  openModal,
  toggle,
  loading,
  onCancel,
}: IConfirmDeleteModal) => {
  return (
    <CustomModal
      isOpen={isOpen}
      openModal={openModal}
      toggle={toggle}
      innerClass="sm:w-[320px]"
    >
      <div className="relative flex w-full flex-col rounded-[30px] border border-border-color bg-gray-400">
        <button className="absolute right-[16px] top-[16px]" onClick={toggle}>
          <Icon.IcModalClose />
        </button>
        <div className="px-16 pb-16 pt-32">
          <img
            className="mx-auto mb-20"
            src="/assets/images/delete-pop-up-img.png"
            width={158}
            height={173}
            alt="Brand Logo"
          />
          <h3 className="mb-8 text-center text-18 font-medium leading-28 text-white-heading">
            {title}
          </h3>
          <p className="text-center text-14 font-normal leading-20 text-white-para">
            {message}
          </p>
        </div>
        <div className="px-16 pb-20 pt-16">
          <div className="flex flex-col gap-3">
            <CustomButton
              title={deleteBtnTitle}
              variant="primary"
              onClick={onConfirm}
              loading={loading}
            />
            <CustomButton
              title={cancelBtnTitle}
              variant="secondary"
              onClick={onCancel || toggle}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDeleteComponent;
