import React from 'react'
import ChangePasswordComponent from './change-password.component'
import useLoadingError from 'src/hooks/useLoadingError'
import { useFormik } from 'formik'
import { changePasswordSchema } from 'src/validations/change-password-schema'
import { toast } from 'react-toastify'
import { showToast } from 'src/utils'
import { TOASTR_TYPES } from 'src/types'
import { useChangePasswordMutation } from 'src/services/auth'
import { useAppDispatch } from 'src/store/hook'
import { setDrawerType } from 'src/store/features/common'

const ChangePasswordContainer = () => {

  const { loading, startLoading, stopLoading } = useLoadingError();
  const [changePassword] = useChangePasswordMutation();
  const dispatch = useAppDispatch();





  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: ""
    },
    validationSchema: changePasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit:  (values) => {


          startLoading()
          changePassword(values).unwrap().then((res) => {
               console.log(res,"res>>>>>")
               dispatch(setDrawerType(null))
               showToast(toast, TOASTR_TYPES.SUCCESS, res?.message)
          }).catch(err => {
              console.log(err,"error response>>>>")
              showToast(toast,TOASTR_TYPES.ERROR,err?.message)
              return false
          }).finally(() => {
               stopLoading()
          })


    },
  });

  
  return (
    <ChangePasswordComponent formik={formik} loading={loading}/>
  )
}

export default ChangePasswordContainer