"use client";
import React from "react";

import { IDeleteButton } from "./delete-button.types";
import { useEditorContext } from "src/context/editor-context";
import { Icon } from "src/components/atoms/icons";
import { useRegisterContext } from "src/context/register-context";

const DeleteButtonComponent = ({
  id,
  formik,
  saveOnFocusOut,
  toggle,
}: IDeleteButton) => {
  const { setDeleteId } = useRegisterContext();

  const { handleDelete } = useEditorContext();

  return (
    <button
      className="delete-section absolute right-[-11px] top-[-11px] hidden group-hover:block "
      onClick={() => {
        const { id: idx } = formik?.values?.sections[id] ?? {};
        if (idx) {
          setDeleteId(id);
          toggle();
        } else {
          handleDelete(id, formik, Boolean(saveOnFocusOut), () => {});
        }
      }}
    >
      <Icon.IcBin />
    </button>
  );
};

export default DeleteButtonComponent;
