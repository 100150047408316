"use client";
import React from "react";
import CustomButton from "src/components/atoms/button";
import { POST_ACTIONS } from "src/types";

import usePost from "src/hooks/usePost";

const SavePostBtnComponent = () => {
  const { loading, handlePostAction } = usePost();
  const handleSavePost = () => {
    handlePostAction(POST_ACTIONS.SAVE);
  };

  return (
    <CustomButton
      title="Save"
      variant="custom"
      className="relative rounded-xl bg-white-button px-24 py-9 text-14 font-medium leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button focus:outline-none"
      onClick={handleSavePost}
      loading={loading}
    />
  );
};

export default SavePostBtnComponent;
