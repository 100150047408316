import React from 'react'
import FormHeader from '../form-header/form-header.component'
import CustomButton from 'src/components/atoms/button'
import TextInput from 'src/components/atoms/text-input'
import { ISignInForm } from './sign-in-form.types'
import ErrorLabel from 'src/components/atoms/error-label'
import { Link} from "react-router-dom";


const SignInForm = ({
  formik,
  error,
  resetError,
  loading
}: ISignInForm) => {
  return (
    <div className="">
      <FormHeader title='Welcome back!' description='Enter your account login information to access your Put It On account' />
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-32">
          <TextInput name='email' type='email' label='Email' containerClassName='mb-16' value={formik.values.email} showError={Boolean(formik.touched.email)}
            onChange={(e) => {
              resetError()
              formik.handleChange(e)
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError()
            }}
            errorMessage={formik.errors.email}
            autoFocus={false}
            required={true}
          />
          <TextInput name='password' type='password' label='Password' containerClassName='mb-16' value={formik.values.password} showError={Boolean(formik.touched.password)}
            onChange={(e) => {
              resetError()
              formik.handleChange(e)
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              resetError()
            }}
            errorMessage={formik.errors.password}
            required={true}
          />
          {Boolean(error) && <ErrorLabel message={error} />}
          <Link to="/forgot-password" className="text-blue-link transition duration-[0.4s] hover:text-hover-blue-link text-14 leading-20 font-semibold">Forgot password?</Link>
        </div>
        <CustomButton title='Login' type="submit" className='w-full' loading={loading} variant='primary' />
      </form>

    </div>
  )
}

export default SignInForm 