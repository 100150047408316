import React from 'react'
import VisitorCardComponent from './visitor-card.component'
import { IVisitorCard } from './types'


const VisitorCardContainer = (props: IVisitorCard) => {
  return (
    <VisitorCardComponent {...props}/>
  )
}

export default VisitorCardContainer