import React from "react";

const IcActiveUsers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.57122 10.3025C10.873 11.6043 10.873 13.7148 9.57122 15.0166C8.26947 16.3183 6.15892 16.3183 4.85717 15.0166C3.55542 13.7148 3.55542 11.6043 4.85717 10.3025C6.15892 9.00079 8.26947 9.00079 9.57122 10.3025ZM9.57122 10.3025L14.5799 5.29391C14.7427 5.1312 15.0065 5.1312 15.1692 5.29391L16.6423 6.76705M12.5175 7.35642L14.2853 9.12419"
        stroke="#BAB8B8"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IcActiveUsers;
