import React from "react";
import SelectInput from "src/components/atoms/select-input";
import Pagination from "src/components/molecules/pagination";
import { IDataTable } from "src/types";

const DatatableFooterComponent = ({
  pagination,
  handlePageChange,
  handleItemsPerPageChange,
}: Partial<IDataTable>) => {
  const startDocument =
    (pagination?.currentPage - 1) * pagination?.itemsPerPage + 1;
  const endDocument = Math.min(
    pagination?.currentPage * pagination?.itemsPerPage,
    pagination?.total
  );

  return (
    <div className="flex items-center justify-between bg-gray-400 px-16 py-12 rounded-b-xl flex-wrap gap-4">
      <div className="flex items-center gap-4">
        <span className="text-white-para text-12 font-normal leading-20">
          Show{" "}
        </span>

        <SelectInput
          name="select"
          containerClassName="data-limit-select"
          options={[
            { value: 10 },
            { value: 20 },
            { value: 50 },
            { value: 100 },
          ]}
          onSelect={(value: number) => {
            handleItemsPerPageChange(value);
          }}
          value={pagination?.itemsPerPage}
        />

        <span className="text-white-para text-12 font-normal leading-20">
          {" "}
          <p>
            {`${pagination?.total == 0 ? 0 : startDocument}-${endDocument} of ${
              pagination?.total
            }`}
          </p>
        </span>
      </div>

      <Pagination
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default DatatableFooterComponent;
