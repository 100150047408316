import React, { useState } from "react";
import { columns } from "./columns";
import DataTableComponent from "./data-table.component";
import usePagination from "src/hooks/usePagination";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import CustomButton from "src/components/atoms/button";
import AddAdminUser from "../add-admin-user";
import {
  useDeleteAdminUserMutation,
  useGetAdminMembersListingMutation,
} from "src/services/admin-members";
import { useAppSelector } from "src/store/hook";
import { userSelector } from "src/store/features/auth/selectors";

const DataTableContainer = () => {
  const [getAdminMembersListing] = useGetAdminMembersListingMutation();

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "admin-users-datatable",
    mutation: getAdminMembersListing,
  });

  // for delete popup
  const { isOpen, toggle } = useModal();

  const user = useAppSelector(userSelector);

  // for add admin user
  const { isOpen: isDrawerOpen, toggle: toggleDrawer } = useModal();

  const [itemId, setItemId] = useState<number | null>(null);
  const [deleteAdminUser] = useDeleteAdminUserMutation();

  const { loading, startLoading, stopLoading } = useLoadingError();

  const showDeletePopup = (id: number) => {
    setItemId(id);
    toggle();
  };

  const confirmDelete = () => {
    startLoading();
    deleteAdminUser({ id: itemId })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        fetchData({ currentPage: 1, query: "" });
        toggle();
        console.log(res, "response comes herer");
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        console.log(err, "error comes here");
      })
      .finally(() => {
        stopLoading();
      });
  };

  let descriptionText;

  if (pagination?.total === 0) {
    descriptionText = "No users";
  } else if (pagination?.total > 1) {
    descriptionText = `Total ${pagination?.total} users`;
  } else {
    descriptionText = `Total ${pagination?.total} user`;
  }

  return (
    <>
      <DataTableComponent
        title={"Admin users"}
        description={descriptionText}
        className="date-picker"
        data={data}
        columns={columns(showDeletePopup, setItemId, toggleDrawer, user)}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        clearSearch={clearSearch}
        button={
          <CustomButton
            title="Add admin user"
            variant="primary"
            onClick={() => {
              toggleDrawer();
              setItemId(null);
            }}
          />
        }
      />

      <ConfirmDelete
        title="Are you sure you want to delete the admin user?"
        message="The data you delete cannot be accessed again"
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
      />

      <AddAdminUser
        isOpen={isDrawerOpen}
        toggle={toggleDrawer}
        fetchData={fetchData}
        itemId={itemId}
        data={data}
      />
    </>
  );
};

export default DataTableContainer;
