import React from "react";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import { Icon } from "src/components/atoms/icons";
import CustomModal from "src/components/molecules/custom-modal";
import { useRegisterContext } from "src/context/register-context";
import { generateRandomPassword } from "src/utils";
import SelectInput from "src/components/atoms/select-input";
import { filterEmptyTags } from "src/components/molecules/crop-modal-easy/utils";

const AddUserFornComponent = ({
  isOpen,
  openModal,
  toggle,
  handleSaveUser,
  loading,
  setDisabled,
  disabled,
}: any) => {
  const { formik } = useRegisterContext();

  const generatePassword = () => {
    formik.setFieldValue("password", generateRandomPassword(10));
    setDisabled(true);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      openModal={openModal}
      toggle={toggle}
      innerClass="xl:w-[580px]"
    >
      <form
        className="modal-content flex w-full flex-col rounded-xl bg-gray-400"
        onSubmit={(e) => {
          e.preventDefault();
          handleSaveUser("SAVE");
        }}
      >
        <div className="flex items-center justify-between gap-2 rounded-t-xl border-b border-border-color p-24">
          <span className="text-16 font-semibold leading-24 text-white-heading">
            Add user
          </span>
          <button onClick={toggle} type="button">
            <Icon.IcModalClose />
          </button>
        </div>

        <div className="modal-body p-24">
          <div className="flex flex-col gap-4">
            <div className="grid gap-4 md:grid-cols-2">
              <TextInput
                name="firstName"
                type="text"
                label="First name"
                value={formik?.values.firstName}
                showError={Boolean(formik?.touched.firstName)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.firstName}
                required={true}
              />
              <TextInput
                name="lastName"
                type="text"
                label="Last name"
                value={formik?.values.lastName}
                showError={Boolean(formik?.touched.lastName)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.lastName}
                required={true}
              />
            </div>
            <TextInput
              name="email"
              type="email"
              label="Email address"
              value={formik?.values.email}
              showError={Boolean(formik?.touched.email)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.email}
              required={true}
            />
            <SelectInput
              label="Keywords"
              name="keywords"
              options={[]}
              containerClassName="tags-input"
              mode="tags"
              showError={Boolean(formik?.touched?.keywords)}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.keywords}
              placeholder="Enter keyword here"
              onChange={(val: any) => {
                formik?.setFieldValue("keywords", filterEmptyTags(val));
              }}
              value={formik?.values?.keywords}
            />

            <div className="relative">
              <TextInput
                name="password"
                type="text"
                label="Password"
                value={formik?.values.password}
                showError={Boolean(formik?.touched.password)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.password}
                required={true}
                className="pr-[193px]"
                disabled={true}
              />

              <div className="absolute right-[6px] top-[32px]">
                <button
                  onClick={generatePassword}
                  className="rounded-xl bg-white-button px-24 py-8 text-14 font-medium leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button focus:outline-none disabled:cursor-not-allowed disabled:opacity-70"
                  type="button" // Change the type to "submit" if it's inside a form
                  disabled={disabled} // Adjust the disabled attribute based on your form state
                >
                  <div className="absolute left-0 right-0 mx-auto h-[24px] w-[24px] animate-spin rounded-full border-4 border-solid border-current border-r-transparent text-center align-[-0.125em] opacity-0 motion-reduce:animate-[spin_1.5s_linear_infinite]">
                    <span className="!-m-px !absolute !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
                  </div>
                  <span>Generate password</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 rounded-b-xl border-t border-border-color p-24 md:flex-row">
          <CustomButton
            title="Cancel"
            type="button"
            variant="secondary"
            className="order-3 md:order-1"
            onClick={() => {
              toggle();
              formik?.resetForm();
            }}
            disabled={loading}
          ></CustomButton>

          <CustomButton
            type="submit"
            title="Save & send user email"
            variant="secondary"
            className="order-2 md:order-2 md:ml-auto"
            // onClick={() => handleSaveUser("SAVE")}
            loading={loading}
          ></CustomButton>
          <CustomButton
            type="button"
            title="Next"
            variant="primary"
            className="order-1 md:order-3"
            onClick={() => handleSaveUser("NEXT")}
            disabled={loading}
          ></CustomButton>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddUserFornComponent;
