import Chip from "src/components/atoms/chip";
import { TODO, VARIANTS } from "src/types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import LeaderBadge from "src/components/atoms/leader-badge";

const UserInfoComponent = ({ post }: TODO) => {
  const { id: userId } = useParams();

  return (
    <div className="flex w-full justify-between md:w-auto">
      <div className="relative flex">
        <Link
          to={`/admin/users/${userId}?activeTab=1`}
          onClick={() => {
            document.getElementById("tab-0")?.click();
          }}
          className="absolute bottom-0 left-0 right-0 top-0 z-10 h-full w-full"
        ></Link>
        <div className="flex-shrink-0">
          <img
            src={post?.author_image || "/assets/images/Profile.svg"}
            alt="post"
            width={39}
            height={39}
            className="mr-8"
          ></img>
        </div>
        <div className="relative top-[-3px] md:top-0">
          <div className="flex flex-col md:flex-row md:items-center">
            <Link
              to={`/admin/users/${userId}?activeTab=0`}
              className="author-name relative z-[10] text-white-heading"
              onClick={() => {
                document.getElementById("tab-1")?.click();
              }}
            >
              <span className="ellipsis-1">{post?.author}</span>
            </Link>
            <span className="hidden px-4 text-16 font-normal leading-7 text-white-para md:inline-flex">
              |
            </span>
            <span className="border-gradient mr-8 text-14 font-medium leading-5 text-white-para">
              <span className="ellipsis-1">
                {post?.author_profession || post?.profession}&nbsp;
                {post?.office_name ? `@ ${post?.office_name}` : ""}
              </span>
            </span>
          </div>
          {Boolean(post?.content_leader) && (
            <div className="">
              <LeaderBadge />
            </div>
          )}
        </div>
      </div>
      <div className="flex h-max text-16 font-normal leading-7 text-white-heading">
        <Chip
          name={post?.topic?.title || post?.topic}
          slug=""
          variant={VARIANTS.SECONDARY}
        />
      </div>
    </div>
  );
};

export default UserInfoComponent;
