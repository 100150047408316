"use client";
import React from "react";
import SectionsEditableComponent from "./sections-editable.component";
import { ISectionsEditable } from "./types";

const BiographyEditableContainer = (props: ISectionsEditable) => {
  return <SectionsEditableComponent {...props} />;
};

export default BiographyEditableContainer;
