import React from "react";

const IcFacebook = ({ height = 20, width = 20 }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.125 10C18.1209 11.9851 17.3924 13.9004 16.0762 15.3864C14.76 16.8723 12.9466 17.8267 10.9765 18.0703C10.9326 18.0746 10.8882 18.0698 10.8462 18.0561C10.8042 18.0425 10.7654 18.0203 10.7324 17.9909C10.6994 17.9616 10.6728 17.9257 10.6543 17.8856C10.6358 17.8455 10.6258 17.802 10.625 17.7578V11.875H12.5C12.5854 11.8752 12.67 11.8578 12.7485 11.824C12.827 11.7902 12.8977 11.7406 12.9563 11.6784C13.0149 11.6161 13.0601 11.5425 13.0891 11.4621C13.1181 11.3817 13.1303 11.2962 13.125 11.2109C13.1111 11.0493 13.0365 10.899 12.9162 10.7902C12.7958 10.6815 12.6387 10.6224 12.4765 10.625H10.625V8.75C10.625 8.41848 10.7567 8.10053 10.9911 7.86611C11.2255 7.63169 11.5434 7.5 11.875 7.5H13.125C13.2104 7.50016 13.295 7.4828 13.3735 7.44898C13.452 7.41515 13.5227 7.36559 13.5813 7.30335C13.6399 7.24111 13.6851 7.16752 13.7141 7.08713C13.7431 7.00673 13.7553 6.92124 13.75 6.83594C13.7361 6.67432 13.6615 6.52397 13.5412 6.41523C13.4208 6.30648 13.2637 6.24743 13.1015 6.25H11.875C11.2119 6.25 10.576 6.51339 10.1072 6.98223C9.63835 7.45107 9.37496 8.08696 9.37496 8.75V10.625H7.49996C7.41449 10.6248 7.32989 10.6422 7.2514 10.676C7.17291 10.7098 7.10219 10.7594 7.04361 10.8216C6.98503 10.8839 6.93984 10.9575 6.91083 11.0379C6.88182 11.1183 6.86961 11.2038 6.87496 11.2891C6.88878 11.4507 6.9634 11.601 7.08375 11.7098C7.20409 11.8185 7.36121 11.8776 7.52339 11.875H9.37496V17.7578C9.37517 17.8016 9.36618 17.845 9.34856 17.8851C9.33094 17.9252 9.30508 17.9611 9.27267 17.9906C9.24026 18.0201 9.20201 18.0424 9.16041 18.0561C9.11881 18.0699 9.07479 18.0747 9.03121 18.0703C4.90621 17.5703 1.71871 13.9844 1.88277 9.69531C1.97486 7.57717 2.89125 5.57893 4.43631 4.12711C5.98137 2.67529 8.03272 1.88491 10.1525 1.92468C12.2723 1.96444 14.2925 2.8312 15.782 4.33995C17.2716 5.84869 18.1124 7.87989 18.125 10Z"
        fill="#007BFF"
      />
    </svg>
  );
};

export default IcFacebook;
