import React from "react";
import { Progress } from "antd";
import { IVisitorCard } from "./types";

const VisitorCardComponent = ({ title, data }: IVisitorCard) => {
  return (
    <div className="flex flex-col rounded-xl bg-gray-300 xl:col-span-4">
      <h3 className="px-24 py-16 text-16 font-semibold leading-24 text-white">
        {title}
      </h3>
      <div
        className={`flex flex-col gap-6 px-24 pb-32 pt-16 ${
          data?.length === 0 ? "h-full items-center justify-center" : ""
        } modal-scroll max-h-[264px] overflow-y-auto`}
      >
        {data?.length === 0 ? (
          <p className="text-16 font-normal leading-24 text-white-heading">
            No results found{" "}
          </p>
        ) : (
          data.map((d: any, index: number) => (
            <div className="leading-[0]" key={d?.title}>
              <div className="mb-8 flex justify-between">
                <span className="text-14 font-bold leading-20 tracking-0.28 text-white-heading">
                  {d?.title || d?.name}
                </span>
                <span className="text-14 font-normal leading-20 tracking-0.28 text-white-para">
                  {d?.label}
                </span>
              </div>
              <Progress
                percent={d?.value}
                showInfo={false}
                strokeWidth={8}
                strokeColor="#095EBA"
                className="m-0 rounded-lg bg-primary-100 py-0 !leading-[0px]"
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default VisitorCardComponent;
