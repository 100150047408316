import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PanelLayout from "src/components/layouts/panel-layout";
import { useDraftBioMutation, useGetUserMutation } from "src/services/users";
import { useAppDispatch, useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";
import PersonalDetail from "src/components/organisms/profile/personal-detail";
import PreviewBtn from "./elements/preview-btn";
import PublishProfile from "./elements/publish-profile";
import { setOtherUser } from "src/store/features/user";
import SectionsEditable from "src/components/organisms/sections/sections-editable";
import { useBioContext } from "src/context/bio-context";
import { CONTENT_TYPE } from "src/components/organisms/sections/sections-editable/types";
import { initialBioValues } from "src/constants";
import useLoadingError from "src/hooks/useLoadingError";

const EditBio = () => {
  const otherUser = useAppSelector(otherUserSelector);
  const dispatch = useAppDispatch();
  const { id: userId } = useParams();
  const [getUser] = useGetUserMutation<any>();
  const [draftBio] = useDraftBioMutation();

  useEffect(() => {
    getUser({ id: userId });

    return () => {
      dispatch(setOtherUser({}));
    };
  }, []);

  const { formikBio } = useBioContext();

  useEffect(() => {
    let bioSections: any = [];
    const initialBioTitles = initialBioValues?.map((e: any) => e.title);

    if (otherUser?.biography?.length == 0) {
      bioSections = initialBioValues?.map((val: any) => ({
        title: val?.title,
        description: val?.description,
        id: val?.id,
        className: "add-section-min-height",
        disabled: true,
      }));
    } else {
      bioSections = otherUser?.biography?.map((val: any) => ({
        ...val,
        title: val?.title,
        description: val?.description,
        id: val?.id,
        className: "add-section-min-height",
      }));

      const bioTitles = bioSections?.map((e: any) => e?.title);

      initialBioTitles.forEach((title: string) => {
        if (!bioTitles.includes(title)) {
          bioSections?.push({
            title,
            description: "",
            className: "add-section-min-height",
            disabled: true,
          });
        }
      });
    }

    formikBio?.setValues({ sections: bioSections });
  }, [otherUser]);

  const {
    loading: loadingSaveBio,
    startLoading: startLoadingSaveBio,
    stopLoading: stopLoadingSaveBio,
  } = useLoadingError();

  const handleBlur = (e: React.FocusEvent, id: number, divRef: any) => {
    if (!divRef?.current?.contains(e.relatedTarget) && !loadingSaveBio) {
      console.log(id, "id comes here");
      formikBio?.validateForm();
      const data: any = formikBio?.values?.sections
        ? formikBio?.values?.sections[id]
        : [];

      console.log(data, "data>>>>");

      if (data?.title && data?.description && !loadingSaveBio) {
        startLoadingSaveBio();

        draftBio({
          userId: userId,
          id: data?.id,
          title: data?.title,
          description: data?.description,
          sortOrder: id + 1,
        })
          .unwrap()
          .then((res) => {
            if (formikBio?.values.sections) {
              formikBio.setFieldValue(`sections[${id}]`, {
                ...(res?.data || {}),
                ...formikBio.values.sections[id],
                id: res?.data?.id,
              });
            }
          })
          .catch((err) => {
            console.log(err, "error comes here");
          })
          .finally(() => {
            stopLoadingSaveBio();
          });
      }
    }
  };

  return (
    <PanelLayout wrapperClass={`px-24 pb-20`}>
      <div className="sticky top-0 z-30 flex flex-col gap-4 bg-[#0f1014] py-20 sm:flex-row sm:items-center">
        <h3 className="break-all text-24 font-bold leading-32 tracking-0.48 text-white-heading">
          Edit Biography
        </h3>
        <div className="flex flex-wrap items-center gap-3 sm:ml-auto">
          <PreviewBtn />
          <PublishProfile />
        </div>
      </div>
      <div className="flex flex-col gap-6 rounded-xl bg-gray-400 p-24 xl:flex-row">
        <div className="order-3 mb-30 rounded-xl border border-border-color px-24 py-32 xl:order-2 xl:w-[70%]">
          <SectionsEditable
            formik={formikBio}
            profile={otherUser}
            showHeader={false}
            handleBlur={handleBlur}
            contentType={CONTENT_TYPE.BIO}
            saveOnFocusOut={true}
            saveOnDragEnd={true}
          />
        </div>
        <PersonalDetail profile={otherUser} isEditable={false} />
      </div>
    </PanelLayout>
  );
};

export default EditBio;
