import React from "react";
import { IKeywords } from "./types";
import { VARIANTS } from "src/types";
import Chip from "src/components/atoms/chip";
import { truncateString } from "src/utils";

const KeywordsComponent = ({ data }: IKeywords) => {
  if (!data) return null; // Handle case where data might not be available

  // Split once and reuse, improving performance
  const keywords = data.split(",");
  const displayedKeywords = keywords.slice(0, 2);

  return (
    <>
      {displayedKeywords.map((keyword: string, index: number) => (
        <Chip
          key={keyword} // Consider using a more unique key if possible
          name={truncateString(keyword, 4)}
          variant={VARIANTS.SECONDARY}
        />
      ))}
      {keywords.length > 2 && (
        <div className="flex items-center">
          <Chip variant={VARIANTS.INFO} name={`+${keywords.length - 2}`} />
        </div>
      )}
    </>
  );
};

export default KeywordsComponent;
