import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import DataTableComponent from "./data-table.component";
import {
  useDeleteFeaturedMemberMutation,
  useGetFeaturedMembersListingMutation,
  useGetMembersListingMutation,
} from "src/services/featured-members";
import usePagination from "src/hooks/usePagination";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import CustomButton from "src/components/atoms/button";
import AddMember from "../add-member";
import { useNavigate } from "react-router-dom";

const DataTableContainer = () => {
  const [getFeaturedMembersListing] = useGetFeaturedMembersListingMutation();

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({
    key: "featured-members-datatable",
    mutation: getFeaturedMembersListing,
  });

  // for delete popup
  const { isOpen, toggle } = useModal();

  const navigate = useNavigate();

  // for add featured member
  const { isOpen: isOpenMember, toggle: toggleMember } = useModal();

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteFeaturedMember] = useDeleteFeaturedMemberMutation();
  const [getMembersListing, { data: usersData }] =
    useGetMembersListingMutation();

  useEffect(() => {
    getMembersListing({});
  }, []);

  const { loading, startLoading, stopLoading } = useLoadingError();

  const showDeletePopup = (id: number) => {
    setDeleteId(id);
    toggle();
  };

  const confirmDelete = () => {
    startLoading();
    deleteFeaturedMember({ id: deleteId })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        fetchData({ currentPage: 1, query: "" });
        toggle();
        getMembersListing({});
        console.log(res, "response comes herer");
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        console.log(err, "error comes here");
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onRowClicked = (row: any) => {
    navigate(`/admin/users/${row?.id}`);
  };

  let descriptionText;

  if (pagination?.total === 0) {
    descriptionText = "No members";
  } else if (pagination?.total > 1) {
    descriptionText = `Total ${pagination?.total} members`;
  } else {
    descriptionText = `Total ${pagination?.total} member`;
  }

  return (
    <>
      <DataTableComponent
        title={"Featured members"}
        description={descriptionText}
        className="date-picker"
        data={data}
        columns={columns(showDeletePopup)}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        clearSearch={clearSearch}
        button={
          <CustomButton
            title="Add new"
            variant="primary"
            onClick={toggleMember}
          />
        }
        onRowClicked={onRowClicked}
      />

      <ConfirmDelete
        title="Are you sure you want to remove this user from Featured members?"
        message="You can still find this user in the Users tab."
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
        deleteBtnTitle={"Remove now"}
      />

      <AddMember
        isOpen={isOpenMember}
        toggle={toggleMember}
        members={usersData?.data || []}
        getMembersListing={getMembersListing}
        fetchData={fetchData}
      />
    </>
  );
};

export default DataTableContainer;
