import React from "react";

import { IErrorLabel } from "./error-label.types";
const ErrorLabel = ({ message, className = "" }: IErrorLabel) => {
  return (
    <>
      {message ? (
        <div
          className={`input-field-error mt-6 text-12 font-normal leading-18 text-error-danger-500 ${className}`}
        >
          {message}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ErrorLabel;
