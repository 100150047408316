import { ICommonState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  userFeedbackDashboard: {
    "yourPlatformRate": {
        "average_rating": "0.00",
        "percentages": [
            {
                "label": "5",
                "value": 0
            },
            {
                "label": "4",
                "value": 0
            },
            {
                "label": "3",
                "value": 0
            },
            {
                "label": "2",
                "value": 0
            },
            {
                "label": "1",
                "value": 0
            }
        ]
    },
    "totalFeedback": 0
},
};

const userSlice = createSlice({
  name: "user-feedback",
  initialState,
  reducers: {
    setUserFeedbackDashboard(state, action) {
      state.userFeedbackDashboard = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { setUserFeedbackDashboard} = userSlice.actions;
