"use client";
import React from "react";
import CustomButton from "src/components/atoms/button";
import { PUBLISH_POST_BTN_TYPES } from "src/types";
import usePost from "src/hooks/usePost";
import { IPublishPostBtn } from "./types";
import useLoadingError from "src/hooks/useLoadingError";
import { usePostsContext } from "src/context/posts-context";

import { scrollToError } from "src/components/molecules/crop-modal-easy/utils";

const PublishPostBtnComponent = ({
  postId,
  buttonType = PUBLISH_POST_BTN_TYPES.DEFAULT,
}: IPublishPostBtn) => {
  const { loading: loadingPostAction } = usePost();

  const { formik, setPublishPostType, setPublishPostId, togglePublishPost } =
    usePostsContext();

  const { loading } = useLoadingError();

  if (buttonType === PUBLISH_POST_BTN_TYPES.DEFAULT) {
    return (
      <CustomButton
        title="Publish"
        variant="gradient"
        className="rounded-xl bg-white-button px-24 py-9 text-14 font-medium leading-20 text-gray-500 transition duration-[0.4s] hover:bg-hover-white-button focus:outline-none"
        onClick={() => {
          formik.submitForm();

          if (!formik?.isValid) {
            scrollToError();
            return;
          }

          setPublishPostType(PUBLISH_POST_BTN_TYPES.DEFAULT);
          togglePublishPost();
        }}
        loading={loadingPostAction}
      />
    );
  } else {
    return (
      <CustomButton
        title="Publish"
        variant="gradient"
        onClick={async () => {
          setPublishPostId(postId);
          setPublishPostType(PUBLISH_POST_BTN_TYPES.CARD);
          togglePublishPost();
        }}
        loading={loading}
      />
    );
  }
};

export default PublishPostBtnComponent;
