import React from 'react'
import RatingCardComponent from './rating-card.component'
import { IRatingCard } from './types'

const RatingCardContainer = (props: IRatingCard) => {
  return (
   <RatingCardComponent {...props}/>
  )
}

export default RatingCardContainer