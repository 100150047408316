import { ICommonState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ICommonState = {
  otherUser: {},
  dashboard: {},
  analytics: {},
  topics: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOtherUser(state, action) {
      state.otherUser = action.payload;
    },
    setDashboardData(state, action) {
      state.dashboard = action.payload;
    },
    setUserAnalytics(state, action) {
      state.analytics = action.payload;
    },
    setTopics(state, action) {
      state.topics = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { setOtherUser, setDashboardData, setUserAnalytics, setTopics } =
  userSlice.actions;
