"use client";
import React from "react";
import { IResendOtpButton } from "./resend-otp-button.types";
import { convertToMinutesAndSeconds } from "src/utils";

const ResendOtpButtonComponent = ({
  countdownTime,
  resendOtp,
  type,
}: IResendOtpButton) => {
  if (type == 1) {
    return (
      <>
        {Number(countdownTime) <= 0 ? (
          <button
            className="mt-5 flex cursor-pointer justify-center"
            onClick={resendOtp}
          >
            <div className="inline-block rounded-full px-20 py-12 text-12 font-medium leading-6 text-white">
              Resend OTP
            </div>
          </button>
        ) : (
          <div className="mt-20 flex justify-center">
            <div className="inline-block rounded-full bg-[#556477] px-20 py-12 text-12 font-medium leading-6 text-white">
              {convertToMinutesAndSeconds(countdownTime ?? 0)}
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {Number(countdownTime) <= 0 ? (
          <></>
        ) : (
          <div className="flex items-center justify-center">
            <div className="font-mormal rounded-[32px] bg-[#EBEEF1] px-8 py-4 text-12 leading-20">
              {convertToMinutesAndSeconds(countdownTime ?? 0)}
            </div>
          </div>
        )}
      </>
    );
  }
};

export default ResendOtpButtonComponent;
