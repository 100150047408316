import { IResponse, IPaginationPayload } from "../types";
import { onQueryStarted, transformErrorResponse } from "../api.config";
import store from "src/store";
import {
  IAddUser,
  IDeleteBioSection,
  IDraftBio,
  IDragDropBio,
  IEditUser,
  IPublishBio,
  IPublishUnpublishBio,
} from "./types";
import { rootApi } from "src/services/api";
import { ENDPOINTS } from "src/store/endpoints";
import {
  setDashboardData,
  setOtherUser,
  setUserAnalytics,
} from "src/store/features/user";
import { setLoading } from "src/store/features/alerts";
import { capitalizeFirstLetter } from "src/utils";
import { formatWebsiteUrl } from "src/components/molecules/crop-modal-easy/utils";
import { initialBioTitles } from "src/constants";

const featuredMembersApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getUsersListing: build.mutation<IResponse, Partial<IPaginationPayload>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.GET_USER_LISTING}?per_page=${body?.perPage}&page=${body?.page}&search=${body?.query}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    addUser: build.mutation<IResponse, Partial<IAddUser>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.ADD_USER}`,
        body: {
          first_name: capitalizeFirstLetter(body?.firstName),
          last_name: capitalizeFirstLetter(body?.lastName),
          keywords: body?.keywords?.join(","),
          email: body?.email,
          password: body?.password,
          country: body?.country,
          city: body?.city,
          dob: body?.dob,
          profession: body?.profession,
          office_name: body?.officeName,
          education: body?.education,
          image: body?.image,
          is_publish: body?.isPublished,
          biography: body?.biography,
          height: body?.height,
          website: formatWebsiteUrl(body?.website),
          twitter_link: formatWebsiteUrl(body.twitterLink),
          linkedin_link: formatWebsiteUrl(body?.linkedinLink),
        },
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    editUser: build.mutation<IResponse, Partial<IEditUser>>({
      query: (body) => {
        return {
          url: `${ENDPOINTS.USERS.EDIT_USER}`,
          body: {
            first_name: capitalizeFirstLetter(body?.firstName),
            last_name: capitalizeFirstLetter(body?.lastName),
            email: body?.email,
            password: body?.password,
            country: body?.country,
            city: body?.city,
            dob: body?.dob,
            profession: body?.profession,
            office_name: body?.officeName,
            education: body?.education,
            image: body?.image,
            //  is_publish: body?.isPublish,
            biography: body?.biography,
            user_id: body?.userId,
            height: body?.height,
            website: formatWebsiteUrl(body?.website),
            twitter_link: formatWebsiteUrl(body.twitterLink),
            linkedin_link: formatWebsiteUrl(body?.linkedinLink),
          },
          method: "PUT",
        };
      },
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getUser: build.mutation<IResponse, Partial<{ id: string | number }>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.GET_USER}?id=${body?.id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) => {
        let firstOccurrenceMap = new Map();

        if (res?.data?.biography) {
          res?.data?.biography?.forEach((item: any, index: any) => {
            // Check if the title is in the titles array and has not been encountered before
            if (
              initialBioTitles?.includes(item.title) &&
              !firstOccurrenceMap?.has(item?.title)
            ) {
              // Add the disabled property to the object
              item.disabled = true;
              // Add the title and its first occurrence index to the map
              firstOccurrenceMap?.set(item.title, index);
            } else {
              item.disabled = false;
            }
          });
        }

        store.dispatch(setOtherUser(res?.data));
        store.dispatch(setLoading(false));
        return res;
      },
      transformErrorResponse: (res: any) =>
        transformErrorResponse(res, store.dispatch, true),
    }),

    editUserEmail: build.mutation<
      IResponse,
      Partial<{ id: string | number; email: string }>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.EDIT_USER_EMAIL}?id=${body?.id}`,
        body,
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),
    editUserKeywords: build.mutation<
      IResponse,
      Partial<{ id: string | number; keywords: string[] }>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.EDIT_USER_KEYWORDS}?id=${body?.id}`,
        body: {
          keywords: body?.keywords?.join(","),
        },
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    deleteUser: build.mutation<IResponse, Partial<{ id: any }>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.DELETE_USER}?id=${body?.id}`,
        body,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    draftBio: build.mutation<IResponse, Partial<IDraftBio>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.DRAFT_BIO}?id=${body?.id ?? ""}&user_id=${
          body?.userId
        }`,
        body: {
          title: body?.title,
          description: body?.description,
          sort_order: body?.sortOrder,
        },
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    dragDropBio: build.mutation<IResponse, Partial<IDragDropBio>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.DRAG_DROP_BIO}?user_id=${"body.userId"}`,
        body: {
          sort_order: body?.sortOrder,
        },
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    publishBio: build.mutation<IResponse, Partial<IPublishBio>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.PUBLISH_BIO}?id=${body.id}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    deleteBioSection: build.mutation<IResponse, Partial<IDeleteBioSection>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.DELETE_BIO_SECTION}?id=${body.id}&user_id=${body?.userId}`,
        method: "DELETE",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    dashboardStats: build.mutation<IResponse, Partial<any>>({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.DASHBOARD_STATS}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        store.dispatch(setDashboardData(res?.data));
        return res;
      },
    }),

    updateUserBio: build.mutation<
      IResponse,
      Partial<{
        userId: any;
        bio: Array<IDraftBio>;
      }>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.UPDATE_UESR_BIO}?user_id=${body?.userId}`,
        body: {
          userId: body?.userId,
          bio: body?.bio,
        },
        method: "PUT",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    publishUnpublishBio: build.mutation<
      IResponse,
      Partial<IPublishUnpublishBio>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.PUBLISH_UNPUBLISH_BIO}`,
        body,
        method: "POST",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      transformResponse: (res: IResponse) => {
        return res;
      },
    }),

    getUserAnalytics: build.mutation<
      IResponse,
      Partial<{ id: string | number }>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.GET_USER_ANALYTICS}?id=${body?.id}`,
        method: "GET",
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, true),
      transformResponse: (res: IResponse) => {
        store.dispatch(setUserAnalytics(res?.data));
        store.dispatch(setLoading(false));
        return res;
      },
      transformErrorResponse: (res: any) => {
        store.dispatch(setLoading(false));
        return res.data;
      },
    }),

    toggleContentLeader: build.mutation<
      IResponse,
      Partial<{ is_content_leader: any; user_id: any }>
    >({
      query: (body) => ({
        url: `${ENDPOINTS.USERS.TOGGLE_CONTENT_LEADER}`,
        method: "PUT",
        body,
      }),
      onQueryStarted: () => onQueryStarted(store.dispatch, false),
      // transformResponse: (res: IResponse) => {
      //   store.dispatch(setUserAnalytics(res?.data));
      //   store.dispatch(setLoading(false));
      //   return res;
      // },
      // transformErrorResponse: (res: any) => {
      //   store.dispatch(setLoading(false));
      //   return res.data;
      // },
    }),
  }),
});
export const {
  useAddUserMutation,
  useGetUserMutation,
  useEditUserMutation,
  useEditUserEmailMutation,
  useEditUserKeywordsMutation,
  useGetUsersListingMutation,
  useDeleteUserMutation,
  useDraftBioMutation,
  useDragDropBioMutation,
  usePublishBioMutation,
  useDeleteBioSectionMutation,
  useDashboardStatsMutation,
  useUpdateUserBioMutation,
  usePublishUnpublishBioMutation,
  useGetUserAnalyticsMutation,
  useToggleContentLeaderMutation,
} = featuredMembersApi;
