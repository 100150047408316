import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PanelLayout from "src/components/layouts/panel-layout";
import CreatePostForm from "src/components/organisms/posts/create-post-form";
import PreviewPostBtn from "src/components/organisms/posts/preview-post-btn";
import PublishPostBtn from "src/components/organisms/posts/publish-post-btn";
import SavePostBtn from "src/components/organisms/posts/save-post-btn";
import { useGetPostMutation } from "src/services/posts";
import { topicsSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";
import { PUBLISH_POST_BTN_TYPES } from "src/types";

const EditPost = () => {
  const [getPost, { data }] = useGetPostMutation();
  const topics = useAppSelector(topicsSelector);
  const { postId } = useParams();
  useEffect(() => {
    getPost({ id: postId });
  }, []);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="sticky top-0 z-20 flex flex-col items-start justify-between gap-4  rounded-tl-lg rounded-tr-lg bg-gray-400 p-16  md:flex-row md:items-center md:gap-0 ">
        <h3 className="text-24 font-bold leading-8 text-white-heading">
          Edit Post
        </h3>
        <div className="relative flex gap-2">
          <PreviewPostBtn />
          <SavePostBtn />
          <PublishPostBtn buttonType={PUBLISH_POST_BTN_TYPES.DEFAULT} />
        </div>
      </div>
      <CreatePostForm topics={topics} initialData={data?.data} isEdit={true} />
    </PanelLayout>
  );
};

export default EditPost;
