import React from "react";
import { Icon } from "src/components/atoms/icons";
import { IShareCard } from "./types";
import { SHARE_TYPE } from "src/components/molecules/share-button/types";
import { useShareContext } from "src/context/share-context";
import { Link } from "react-router-dom";

const ShareCardComponent = ({ handleCopyClick, isCopied }: IShareCard) => {
  const { shareData } = useShareContext();

  const getDescription = (shareType: SHARE_TYPE) => {
    if (shareType === SHARE_TYPE.POST) {
      return `Discover captivating content from ${shareData?.username} on Putiton-E! Dive into their latest post and join me in engaging with their insightful perspectives. Let's ignite meaningful conversations together! 🚀🌟 #PutitonE `;
    } else if (shareType === SHARE_TYPE.ARTICLE) {
      return `Share the Knowledge! Spread the word about ${shareData?.username} on Putiton-L. 
Together, let's make learning contagious! 
📚#PutitonL #KnowledgeSharing`;
    } else {
      return `Check out ${shareData?.username}'s amazing profile on Putiton-E! Join me in exploring their fascinating journey and connecting with like-minded individuals. Let's embark on an unforgettable adventure together 🚀🌟 #PutitonE `;
    }
  };

  return (
    <div className="custom-dashed-border flex border-spacing-5 flex-col justify-start">
      <div className=" px-20 py-12 text-12 leading-5 tracking-[-0.24px]">
        <p className="share-profile-content">
          {getDescription(shareData?.shareType)}
        </p>
        <p>
          {" "}
          <Link
            to={shareData?.url || ""}
            className="break-all text-12 font-normal leading-5 tracking-[-0.24px] text-blue-link"
            target="_blank"
          >
            {shareData?.url}
          </Link>
        </p>
        <div className="relative flex justify-end">
          <button
            className="flex items-center text-14 font-normal leading-6 text-white-heading"
            onClick={handleCopyClick}
          >
            <Icon.IcCopyCard />
          </button>
          {isCopied && (
            <div className="absolute right-0 top-[100%] z-10 mt-10 whitespace-nowrap rounded-md bg-gray-900 px-20 py-10 text-white-para">
              Copied to the clipboard.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareCardComponent;
