"use client";
import React, { useEffect, useRef, useState } from "react";
import ResendOtpButtonComponent from "./resend-otp-button.component";
import moment from "moment";
import { useForgotPasswordMutation } from "src/services/auth";
import { useLocation, useNavigate } from "react-router-dom";

const ResendOtpButtonContainer = ({
  resetError,
  type,
  keyName,
  setCanResendOtp,
}: any) => {
  const [forgotPassword] = useForgotPasswordMutation();

  const [countdownTime] = useState(300);
  const [difference, setDifference] = useState(0);
  const intervalRef = useRef<any>(null);

  const navigate = useNavigate();
  const location = useLocation();

  // Get current query parameters
  const searchParams = new URLSearchParams(location.search);

  const email = searchParams.get("email") ?? "";
  const params = new URLSearchParams(searchParams);

  const otpRequestTimestamp = sessionStorage.getItem(keyName);

  useEffect(() => {
    const handleInterval = () => {
      const currentTime = moment();
      const differenceInSeconds = currentTime.diff(
        otpRequestTimestamp,
        "seconds",
      );

      setDifference(differenceInSeconds);

      if (differenceInSeconds >= countdownTime) {
        setCanResendOtp?.(false);
        clearInterval(intervalRef.current);
      }
    };

    if (otpRequestTimestamp) {
      const currentTime = moment();
      const differenceInSeconds = currentTime.diff(
        otpRequestTimestamp,
        "seconds",
      );

      if (differenceInSeconds <= countdownTime) {
        setCanResendOtp?.(true);
        handleInterval();
        intervalRef.current = setInterval(handleInterval, 1000);
      } else {
        setCanResendOtp?.(false);
      }
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [otpRequestTimestamp, countdownTime]);

  const handleClickResendOtp = () => {
    resetError();
    setDifference(0);
    setCanResendOtp?.(true);
    forgotPassword({ email })
      .unwrap()
      .then((res: any) => {
        console.log(res?.data?.token);
        params.set("token", res?.data?.token);
        searchParams.set("token", res?.token);

        const newSearchString = `?${searchParams.toString()}`;

        // Replace the current location with the updated query parameters
        navigate({
          pathname: location.pathname,
          search: newSearchString,
        });
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
    sessionStorage.setItem(keyName, moment().format());
  };

  return (
    <ResendOtpButtonComponent
      countdownTime={countdownTime - difference}
      resendOtp={handleClickResendOtp}
      keyName={keyName}
      type={type}
    />
  );
};

export default ResendOtpButtonContainer;
