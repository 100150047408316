"use client";
import React from "react";
import SingleSection from "../single-section";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IAllSections } from "./all-sections.types";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/types";
import { toast } from "react-toastify";
import { useEditorContext } from "src/context/editor-context";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import { useRegisterContext } from "src/context/register-context";
import { useDeleteBioSectionMutation } from "src/services/users";
import { useParams } from "react-router-dom";
import { CONTENT_TYPE } from "../types";
import { useDeletePostSectionMutation } from "src/services/posts";
import { useDeleteArticleSectionMutation } from "src/services/articles";

const AllSectionsComponent = ({
  formik,
  saveOnFocusOut,
  saveOnDragEnd,
  handleBlur,
  contentType,
}: IAllSections) => {
  const { handleOnDragEnd, handleOnDragStart, handleDelete } =
    useEditorContext();

  const { deleteId } = useRegisterContext();
  const { toggle, isOpen } = useModal();
  const { loading, startLoading, stopLoading } = useLoadingError();

  const { id: userId } = useParams();

  const [deleteBioSection] = useDeleteBioSectionMutation();
  const [deletePostSection] = useDeletePostSectionMutation();
  const [deleteArticleSection] = useDeleteArticleSectionMutation();

  const onConfirm = (id: any, contentType: CONTENT_TYPE) => {
    const action: any = {
      [CONTENT_TYPE.POST]: deletePostSection,
      [CONTENT_TYPE.BIO]: deleteBioSection,
      [CONTENT_TYPE.ARTICLE]: deleteArticleSection,
    };

    handleDelete(id, formik, Boolean(saveOnFocusOut), () => {
      if (
        saveOnFocusOut &&
        formik?.values?.sections &&
        formik?.values?.sections[id].id
      ) {
        startLoading();

        action[contentType]({
          id: formik?.values?.sections[id].id,
          userId: userId,
        })
          .unwrap()
          .then((res: any) => {
            showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
          })
          .catch((err: any) => {
            console.log(err, "error comes here");
          })
          .finally(() => {
            stopLoading();
            toggle();
          });
      } else {
        toggle();
        document.body.style.overflow = "auto";
      }
    });
  };

  return (
    <div className="">
      <DragDropContext
        onDragEnd={(result) =>
          handleOnDragEnd(result, formik, saveOnDragEnd, contentType)
        }
        onDragStart={handleOnDragStart}
      >
        <Droppable
          droppableId="droppable"
          mode="standard"
          isDropDisabled={false}
        >
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formik?.values?.sections &&
                formik?.values?.sections.map((bio: any, index: number) => {
                  return (
                    <div
                      id={`section-${index}`}
                      key={"item-key-" + index.toString()}
                    >
                      <Draggable
                        key={"item-" + index.toString()}
                        draggableId={"item-" + index.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            id={`section_editor-${index}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="test234"
                          >
                            <SingleSection
                              bio={bio}
                              id={index}
                              formik={formik}
                              saveOnFocusOut={saveOnFocusOut}
                              saveOnDragEnd={saveOnDragEnd}
                              toggle={toggle}
                              handleBlur={handleBlur}
                            />
                          </div>
                        )}
                      </Draggable>
                    </div>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ConfirmDelete
        title={"Are you sure you want to delete this section?"}
        message={"The data you delete cannot be accessed again"}
        toggle={toggle}
        onConfirm={() => onConfirm(deleteId, contentType)}
        isOpen={isOpen}
        onCancel={() => toggle()}
        loading={loading}
      />
    </div>
  );
};

export default AllSectionsComponent;
