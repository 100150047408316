import React from "react";
import DataTableSearch from "../datatable-search";
import { IDataTable } from "src/types";

const DatetableHeaderComponent = ({
  title,
  description,
  button,
  handleSearch,
  pagination,
  clearSearch,
}: Partial<IDataTable>) => {
  return (
    <div className="flex flex-wrap items-center justify-between gap-4 rounded-t-xl border-b border-border-color bg-gray-400 px-12 py-16 lg:gap-8">
      <div className="flex flex-col gap-1">
        <h2 className="text-24 font-bold leading-32 text-white-heading">
          {title}
        </h2>
        <div className="text-14 font-normal leading-24 text-white-para">
          {pagination?.loading ? "Loading..." : description}
        </div>
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-3 lg:w-auto lg:justify-normal">
        <div className="order-2 flex w-full sm:order-1 sm:w-auto">
          <DataTableSearch
            query={pagination?.query}
            handleSearch={handleSearch}
            clearSearch={clearSearch}
          />
        </div>
        <div className="order-1 flex w-full sm:order-1 sm:w-auto">{button}</div>
      </div>
    </div>
  );
};

export default DatetableHeaderComponent;
