export interface IShareButton {
  description?: string;
  shareType: SHARE_TYPE;
  slug?: string;
  image?: string;
  className?: string;
  username: any;
}

export enum SHARE_TYPE {
  "POST" = "POST",
  "PROFILE" = "PROFILE",
  "ARTICLE" = "ARTICLE",
}
export enum SHARE_PLATFORM {
  "FB" = "Facebook",
  "LINKED_IN" = "LinkedIn",
  "TWITTER" = "X",
}
