import React, { useEffect } from "react";
import AddTopicFormComponent from "./add-topic-form.component";
import { useFormik } from "formik";
import { addTopicSchema } from "src/validations/add-topic-schema";
import { IAddTopicForm } from "./types";
import { IAddTopic } from "src/services/topics/types";
import { useAddTopicMutation, useEditTopicMutation } from "src/services/topics";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";

const AddTopicFormContainer = (props: IAddTopicForm) => {
  const [addTopic] = useAddTopicMutation();
  const [editTopic] = useEditTopicMutation();

  useEffect(() => {
    formik.setValues(props?.initialValues);
  }, [props.initialValues]);

  const formik = useFormik<IAddTopic>({
    initialValues: props?.initialValues,
    validationSchema: addTopicSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, { resetForm, setValues }) => {
      const action = values?.id ? editTopic : addTopic;

      action(values)
        .unwrap()
        .then((res) => {
          props?.fetchData();
          props?.toggle();
          props?.fetchData();
          resetForm();
          showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        })
        .catch((err) => {
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return <AddTopicFormComponent formik={formik} {...props} />;
};

export default AddTopicFormContainer;
