export const countries:any = {
  "AC": {
    "name": "Ascension Island",
    "emoji": "🇦🇨",
    "unicode": "U+1F1E6 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg"
  },
  "AD": {
    "name": "Andorra",
    "emoji": "🇦🇩",
    "unicode": "U+1F1E6 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg"
  },
  "AE": {
    "name": "United Arab Emirates",
    "emoji": "🇦🇪",
    "unicode": "U+1F1E6 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg"
  },
  "AF": {
    "name": "Afghanistan",
    "emoji": "🇦🇫",
    "unicode": "U+1F1E6 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
  },
  "AG": {
    "name": "Antigua & Barbuda",
    "emoji": "🇦🇬",
    "unicode": "U+1F1E6 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg"
  },
  "AI": {
    "name": "Anguilla",
    "emoji": "🇦🇮",
    "unicode": "U+1F1E6 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg"
  },
  "AL": {
    "name": "Albania",
    "emoji": "🇦🇱",
    "unicode": "U+1F1E6 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg"
  },
  "AM": {
    "name": "Armenia",
    "emoji": "🇦🇲",
    "unicode": "U+1F1E6 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg"
  },
  "AO": {
    "name": "Angola",
    "emoji": "🇦🇴",
    "unicode": "U+1F1E6 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg"
  },
  "AQ": {
    "name": "Antarctica",
    "emoji": "🇦🇶",
    "unicode": "U+1F1E6 U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg"
  },
  "AR": {
    "name": "Argentina",
    "emoji": "🇦🇷",
    "unicode": "U+1F1E6 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg"
  },
  "AS": {
    "name": "American Samoa",
    "emoji": "🇦🇸",
    "unicode": "U+1F1E6 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg"
  },
  "AT": {
    "name": "Austria",
    "emoji": "🇦🇹",
    "unicode": "U+1F1E6 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg"
  },
  "AU": {
    "name": "Australia",
    "emoji": "🇦🇺",
    "unicode": "U+1F1E6 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg"
  },
  "AW": {
    "name": "Aruba",
    "emoji": "🇦🇼",
    "unicode": "U+1F1E6 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg"
  },
  "AX": {
    "name": "Åland Islands",
    "emoji": "🇦🇽",
    "unicode": "U+1F1E6 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg"
  },
  "AZ": {
    "name": "Azerbaijan",
    "emoji": "🇦🇿",
    "unicode": "U+1F1E6 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg"
  },
  "BA": {
    "name": "Bosnia & Herzegovina",
    "emoji": "🇧🇦",
    "unicode": "U+1F1E7 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg"
  },
  "BB": {
    "name": "Barbados",
    "emoji": "🇧🇧",
    "unicode": "U+1F1E7 U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg"
  },
  "BD": {
    "name": "Bangladesh",
    "emoji": "🇧🇩",
    "unicode": "U+1F1E7 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg"
  },
  "BE": {
    "name": "Belgium",
    "emoji": "🇧🇪",
    "unicode": "U+1F1E7 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg"
  },
  "BF": {
    "name": "Burkina Faso",
    "emoji": "🇧🇫",
    "unicode": "U+1F1E7 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg"
  },
  "BG": {
    "name": "Bulgaria",
    "emoji": "🇧🇬",
    "unicode": "U+1F1E7 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg"
  },
  "BH": {
    "name": "Bahrain",
    "emoji": "🇧🇭",
    "unicode": "U+1F1E7 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
  },
  "BI": {
    "name": "Burundi",
    "emoji": "🇧🇮",
    "unicode": "U+1F1E7 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg"
  },
  "BJ": {
    "name": "Benin",
    "emoji": "🇧🇯",
    "unicode": "U+1F1E7 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg"
  },
  "BL": {
    "name": "St. Barthélemy",
    "emoji": "🇧🇱",
    "unicode": "U+1F1E7 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg"
  },
  "BM": {
    "name": "Bermuda",
    "emoji": "🇧🇲",
    "unicode": "U+1F1E7 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg"
  },
  "BN": {
    "name": "Brunei",
    "emoji": "🇧🇳",
    "unicode": "U+1F1E7 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg"
  },
  "BO": {
    "name": "Bolivia",
    "emoji": "🇧🇴",
    "unicode": "U+1F1E7 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg"
  },
  "BQ": {
    "name": "Caribbean Netherlands",
    "emoji": "🇧🇶",
    "unicode": "U+1F1E7 U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg"
  },
  "BR": {
    "name": "Brazil",
    "emoji": "🇧🇷",
    "unicode": "U+1F1E7 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg"
  },
  "BS": {
    "name": "Bahamas",
    "emoji": "🇧🇸",
    "unicode": "U+1F1E7 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg"
  },
  "BT": {
    "name": "Bhutan",
    "emoji": "🇧🇹",
    "unicode": "U+1F1E7 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg"
  },
  "BV": {
    "name": "Bouvet Island",
    "emoji": "🇧🇻",
    "unicode": "U+1F1E7 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg"
  },
  "BW": {
    "name": "Botswana",
    "emoji": "🇧🇼",
    "unicode": "U+1F1E7 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg"
  },
  "BY": {
    "name": "Belarus",
    "emoji": "🇧🇾",
    "unicode": "U+1F1E7 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg"
  },
  "BZ": {
    "name": "Belize",
    "emoji": "🇧🇿",
    "unicode": "U+1F1E7 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg"
  },
  "CA": {
    "name": "Canada",
    "emoji": "🇨🇦",
    "unicode": "U+1F1E8 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg"
  },
  "CC": {
    "name": "Cocos (Keeling) Islands",
    "emoji": "🇨🇨",
    "unicode": "U+1F1E8 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg"
  },
  "CD": {
    "name": "Congo - Kinshasa",
    "emoji": "🇨🇩",
    "unicode": "U+1F1E8 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg"
  },
  "CF": {
    "name": "Central African Republic",
    "emoji": "🇨🇫",
    "unicode": "U+1F1E8 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg"
  },
  "CG": {
    "name": "Congo - Brazzaville",
    "emoji": "🇨🇬",
    "unicode": "U+1F1E8 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg"
  },
  "CH": {
    "name": "Switzerland",
    "emoji": "🇨🇭",
    "unicode": "U+1F1E8 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg"
  },
  "CI": {
    "name": "Côte d’Ivoire",
    "emoji": "🇨🇮",
    "unicode": "U+1F1E8 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg"
  },
  "CK": {
    "name": "Cook Islands",
    "emoji": "🇨🇰",
    "unicode": "U+1F1E8 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg"
  },
  "CL": {
    "name": "Chile",
    "emoji": "🇨🇱",
    "unicode": "U+1F1E8 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg"
  },
  "CM": {
    "name": "Cameroon",
    "emoji": "🇨🇲",
    "unicode": "U+1F1E8 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg"
  },
  "CN": {
    "name": "China",
    "emoji": "🇨🇳",
    "unicode": "U+1F1E8 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg"
  },
  "CO": {
    "name": "Colombia",
    "emoji": "🇨🇴",
    "unicode": "U+1F1E8 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg"
  },
  "CP": {
    "name": "Clipperton Island",
    "emoji": "🇨🇵",
    "unicode": "U+1F1E8 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CP.svg"
  },
  "CR": {
    "name": "Costa Rica",
    "emoji": "🇨🇷",
    "unicode": "U+1F1E8 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg"
  },
  "CU": {
    "name": "Cuba",
    "emoji": "🇨🇺",
    "unicode": "U+1F1E8 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg"
  },
  "CV": {
    "name": "Cape Verde",
    "emoji": "🇨🇻",
    "unicode": "U+1F1E8 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg"
  },
  "CW": {
    "name": "Curaçao",
    "emoji": "🇨🇼",
    "unicode": "U+1F1E8 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg"
  },
  "CX": {
    "name": "Christmas Island",
    "emoji": "🇨🇽",
    "unicode": "U+1F1E8 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg"
  },
  "CY": {
    "name": "Cyprus",
    "emoji": "🇨🇾",
    "unicode": "U+1F1E8 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg"
  },
  "CZ": {
    "name": "Czechia",
    "emoji": "🇨🇿",
    "unicode": "U+1F1E8 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg"
  },
  "DE": {
    "name": "Germany",
    "emoji": "🇩🇪",
    "unicode": "U+1F1E9 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg"
  },
  "DG": {
    "name": "Diego Garcia",
    "emoji": "🇩🇬",
    "unicode": "U+1F1E9 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg"
  },
  "DJ": {
    "name": "Djibouti",
    "emoji": "🇩🇯",
    "unicode": "U+1F1E9 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg"
  },
  "DK": {
    "name": "Denmark",
    "emoji": "🇩🇰",
    "unicode": "U+1F1E9 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg"
  },
  "DM": {
    "name": "Dominica",
    "emoji": "🇩🇲",
    "unicode": "U+1F1E9 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg"
  },
  "DO": {
    "name": "Dominican Republic",
    "emoji": "🇩🇴",
    "unicode": "U+1F1E9 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg"
  },
  "DZ": {
    "name": "Algeria",
    "emoji": "🇩🇿",
    "unicode": "U+1F1E9 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg"
  },
  "EA": {
    "name": "Ceuta & Melilla",
    "emoji": "🇪🇦",
    "unicode": "U+1F1EA U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg"
  },
  "EC": {
    "name": "Ecuador",
    "emoji": "🇪🇨",
    "unicode": "U+1F1EA U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg"
  },
  "EE": {
    "name": "Estonia",
    "emoji": "🇪🇪",
    "unicode": "U+1F1EA U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg"
  },
  "EG": {
    "name": "Egypt",
    "emoji": "🇪🇬",
    "unicode": "U+1F1EA U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg"
  },
  "EH": {
    "name": "Western Sahara",
    "emoji": "🇪🇭",
    "unicode": "U+1F1EA U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg"
  },
  "ER": {
    "name": "Eritrea",
    "emoji": "🇪🇷",
    "unicode": "U+1F1EA U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg"
  },
  "ES": {
    "name": "Spain",
    "emoji": "🇪🇸",
    "unicode": "U+1F1EA U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg"
  },
  "ET": {
    "name": "Ethiopia",
    "emoji": "🇪🇹",
    "unicode": "U+1F1EA U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg"
  },
  "EU": {
    "name": "European Union",
    "emoji": "🇪🇺",
    "unicode": "U+1F1EA U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg"
  },
  "FI": {
    "name": "Finland",
    "emoji": "🇫🇮",
    "unicode": "U+1F1EB U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg"
  },
  "FJ": {
    "name": "Fiji",
    "emoji": "🇫🇯",
    "unicode": "U+1F1EB U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg"
  },
  "FK": {
    "name": "Falkland Islands",
    "emoji": "🇫🇰",
    "unicode": "U+1F1EB U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg"
  },
  "FM": {
    "name": "Micronesia",
    "emoji": "🇫🇲",
    "unicode": "U+1F1EB U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg"
  },
  "FO": {
    "name": "Faroe Islands",
    "emoji": "🇫🇴",
    "unicode": "U+1F1EB U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg"
  },
  "FR": {
    "name": "France",
    "emoji": "🇫🇷",
    "unicode": "U+1F1EB U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg"
  },
  "GA": {
    "name": "Gabon",
    "emoji": "🇬🇦",
    "unicode": "U+1F1EC U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg"
  },
  "GB": {
    "name": "United Kingdom",
    "emoji": "🇬🇧",
    "unicode": "U+1F1EC U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg"
  },
  "GD": {
    "name": "Grenada",
    "emoji": "🇬🇩",
    "unicode": "U+1F1EC U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg"
  },
  "GE": {
    "name": "Georgia",
    "emoji": "🇬🇪",
    "unicode": "U+1F1EC U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg"
  },
  "GF": {
    "name": "French Guiana",
    "emoji": "🇬🇫",
    "unicode": "U+1F1EC U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg"
  },
  "GG": {
    "name": "Guernsey",
    "emoji": "🇬🇬",
    "unicode": "U+1F1EC U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg"
  },
  "GH": {
    "name": "Ghana",
    "emoji": "🇬🇭",
    "unicode": "U+1F1EC U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg"
  },
  "GI": {
    "name": "Gibraltar",
    "emoji": "🇬🇮",
    "unicode": "U+1F1EC U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg"
  },
  "GL": {
    "name": "Greenland",
    "emoji": "🇬🇱",
    "unicode": "U+1F1EC U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg"
  },
  "GM": {
    "name": "Gambia",
    "emoji": "🇬🇲",
    "unicode": "U+1F1EC U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg"
  },
  "GN": {
    "name": "Guinea",
    "emoji": "🇬🇳",
    "unicode": "U+1F1EC U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg"
  },
  "GP": {
    "name": "Guadeloupe",
    "emoji": "🇬🇵",
    "unicode": "U+1F1EC U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg"
  },
  "GQ": {
    "name": "Equatorial Guinea",
    "emoji": "🇬🇶",
    "unicode": "U+1F1EC U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg"
  },
  "GR": {
    "name": "Greece",
    "emoji": "🇬🇷",
    "unicode": "U+1F1EC U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg"
  },
  "GS": {
    "name": "South Georgia & South Sandwich Islands",
    "emoji": "🇬🇸",
    "unicode": "U+1F1EC U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg"
  },
  "GT": {
    "name": "Guatemala",
    "emoji": "🇬🇹",
    "unicode": "U+1F1EC U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg"
  },
  "GU": {
    "name": "Guam",
    "emoji": "🇬🇺",
    "unicode": "U+1F1EC U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg"
  },
  "GW": {
    "name": "Guinea-Bissau",
    "emoji": "🇬🇼",
    "unicode": "U+1F1EC U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg"
  },
  "GY": {
    "name": "Guyana",
    "emoji": "🇬🇾",
    "unicode": "U+1F1EC U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg"
  },
  "HK": {
    "name": "Hong Kong SAR China",
    "emoji": "🇭🇰",
    "unicode": "U+1F1ED U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg"
  },
  "HM": {
    "name": "Heard & McDonald Islands",
    "emoji": "🇭🇲",
    "unicode": "U+1F1ED U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg"
  },
  "HN": {
    "name": "Honduras",
    "emoji": "🇭🇳",
    "unicode": "U+1F1ED U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg"
  },
  "HR": {
    "name": "Croatia",
    "emoji": "🇭🇷",
    "unicode": "U+1F1ED U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg"
  },
  "HT": {
    "name": "Haiti",
    "emoji": "🇭🇹",
    "unicode": "U+1F1ED U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg"
  },
  "HU": {
    "name": "Hungary",
    "emoji": "🇭🇺",
    "unicode": "U+1F1ED U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg"
  },
  "IC": {
    "name": "Canary Islands",
    "emoji": "🇮🇨",
    "unicode": "U+1F1EE U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg"
  },
  "ID": {
    "name": "Indonesia",
    "emoji": "🇮🇩",
    "unicode": "U+1F1EE U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg"
  },
  "IE": {
    "name": "Ireland",
    "emoji": "🇮🇪",
    "unicode": "U+1F1EE U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg"
  },
  "IL": {
    "name": "Israel",
    "emoji": "🇮🇱",
    "unicode": "U+1F1EE U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg"
  },
  "IM": {
    "name": "Isle of Man",
    "emoji": "🇮🇲",
    "unicode": "U+1F1EE U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg"
  },
  "IN": {
    "name": "India",
    "emoji": "🇮🇳",
    "unicode": "U+1F1EE U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
  },
  "IO": {
    "name": "British Indian Ocean Territory",
    "emoji": "🇮🇴",
    "unicode": "U+1F1EE U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg"
  },
  "IQ": {
    "name": "Iraq",
    "emoji": "🇮🇶",
    "unicode": "U+1F1EE U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg"
  },
  "IR": {
    "name": "Iran",
    "emoji": "🇮🇷",
    "unicode": "U+1F1EE U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg"
  },
  "IS": {
    "name": "Iceland",
    "emoji": "🇮🇸",
    "unicode": "U+1F1EE U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg"
  },
  "IT": {
    "name": "Italy",
    "emoji": "🇮🇹",
    "unicode": "U+1F1EE U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg"
  },
  "JE": {
    "name": "Jersey",
    "emoji": "🇯🇪",
    "unicode": "U+1F1EF U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg"
  },
  "JM": {
    "name": "Jamaica",
    "emoji": "🇯🇲",
    "unicode": "U+1F1EF U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg"
  },
  "JO": {
    "name": "Jordan",
    "emoji": "🇯🇴",
    "unicode": "U+1F1EF U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg"
  },
  "JP": {
    "name": "Japan",
    "emoji": "🇯🇵",
    "unicode": "U+1F1EF U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg"
  },
  "KE": {
    "name": "Kenya",
    "emoji": "🇰🇪",
    "unicode": "U+1F1F0 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg"
  },
  "KG": {
    "name": "Kyrgyzstan",
    "emoji": "🇰🇬",
    "unicode": "U+1F1F0 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg"
  },
  "KH": {
    "name": "Cambodia",
    "emoji": "🇰🇭",
    "unicode": "U+1F1F0 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg"
  },
  "KI": {
    "name": "Kiribati",
    "emoji": "🇰🇮",
    "unicode": "U+1F1F0 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg"
  },
  "KM": {
    "name": "Comoros",
    "emoji": "🇰🇲",
    "unicode": "U+1F1F0 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg"
  },
  "KN": {
    "name": "St. Kitts & Nevis",
    "emoji": "🇰🇳",
    "unicode": "U+1F1F0 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg"
  },
  "KP": {
    "name": "North Korea",
    "emoji": "🇰🇵",
    "unicode": "U+1F1F0 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg"
  },
  "KR": {
    "name": "South Korea",
    "emoji": "🇰🇷",
    "unicode": "U+1F1F0 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg"
  },
  "KW": {
    "name": "Kuwait",
    "emoji": "🇰🇼",
    "unicode": "U+1F1F0 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg"
  },
  "KY": {
    "name": "Cayman Islands",
    "emoji": "🇰🇾",
    "unicode": "U+1F1F0 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg"
  },
  "KZ": {
    "name": "Kazakhstan",
    "emoji": "🇰🇿",
    "unicode": "U+1F1F0 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg"
  },
  "LA": {
    "name": "Laos",
    "emoji": "🇱🇦",
    "unicode": "U+1F1F1 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg"
  },
  "LB": {
    "name": "Lebanon",
    "emoji": "🇱🇧",
    "unicode": "U+1F1F1 U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg"
  },
  "LC": {
    "name": "St. Lucia",
    "emoji": "🇱🇨",
    "unicode": "U+1F1F1 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg"
  },
  "LI": {
    "name": "Liechtenstein",
    "emoji": "🇱🇮",
    "unicode": "U+1F1F1 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg"
  },
  "LK": {
    "name": "Sri Lanka",
    "emoji": "🇱🇰",
    "unicode": "U+1F1F1 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg"
  },
  "LR": {
    "name": "Liberia",
    "emoji": "🇱🇷",
    "unicode": "U+1F1F1 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg"
  },
  "LS": {
    "name": "Lesotho",
    "emoji": "🇱🇸",
    "unicode": "U+1F1F1 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg"
  },
  "LT": {
    "name": "Lithuania",
    "emoji": "🇱🇹",
    "unicode": "U+1F1F1 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg"
  },
  "LU": {
    "name": "Luxembourg",
    "emoji": "🇱🇺",
    "unicode": "U+1F1F1 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg"
  },
  "LV": {
    "name": "Latvia",
    "emoji": "🇱🇻",
    "unicode": "U+1F1F1 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg"
  },
  "LY": {
    "name": "Libya",
    "emoji": "🇱🇾",
    "unicode": "U+1F1F1 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg"
  },
  "MA": {
    "name": "Morocco",
    "emoji": "🇲🇦",
    "unicode": "U+1F1F2 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg"
  },
  "MC": {
    "name": "Monaco",
    "emoji": "🇲🇨",
    "unicode": "U+1F1F2 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg"
  },
  "MD": {
    "name": "Moldova",
    "emoji": "🇲🇩",
    "unicode": "U+1F1F2 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg"
  },
  "ME": {
    "name": "Montenegro",
    "emoji": "🇲🇪",
    "unicode": "U+1F1F2 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg"
  },
  "MF": {
    "name": "St. Martin",
    "emoji": "🇲🇫",
    "unicode": "U+1F1F2 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg"
  },
  "MG": {
    "name": "Madagascar",
    "emoji": "🇲🇬",
    "unicode": "U+1F1F2 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg"
  },
  "MH": {
    "name": "Marshall Islands",
    "emoji": "🇲🇭",
    "unicode": "U+1F1F2 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg"
  },
  "MK": {
    "name": "North Macedonia",
    "emoji": "🇲🇰",
    "unicode": "U+1F1F2 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg"
  },
  "ML": {
    "name": "Mali",
    "emoji": "🇲🇱",
    "unicode": "U+1F1F2 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg"
  },
  "MM": {
    "name": "Myanmar (Burma)",
    "emoji": "🇲🇲",
    "unicode": "U+1F1F2 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg"
  },
  "MN": {
    "name": "Mongolia",
    "emoji": "🇲🇳",
    "unicode": "U+1F1F2 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg"
  },
  "MO": {
    "name": "Macao SAR China",
    "emoji": "🇲🇴",
    "unicode": "U+1F1F2 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg"
  },
  "MP": {
    "name": "Northern Mariana Islands",
    "emoji": "🇲🇵",
    "unicode": "U+1F1F2 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg"
  },
  "MQ": {
    "name": "Martinique",
    "emoji": "🇲🇶",
    "unicode": "U+1F1F2 U+1F1F6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg"
  },
  "MR": {
    "name": "Mauritania",
    "emoji": "🇲🇷",
    "unicode": "U+1F1F2 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg"
  },
  "MS": {
    "name": "Montserrat",
    "emoji": "🇲🇸",
    "unicode": "U+1F1F2 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg"
  },
  "MT": {
    "name": "Malta",
    "emoji": "🇲🇹",
    "unicode": "U+1F1F2 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg"
  },
  "MU": {
    "name": "Mauritius",
    "emoji": "🇲🇺",
    "unicode": "U+1F1F2 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg"
  },
  "MV": {
    "name": "Maldives",
    "emoji": "🇲🇻",
    "unicode": "U+1F1F2 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg"
  },
  "MW": {
    "name": "Malawi",
    "emoji": "🇲🇼",
    "unicode": "U+1F1F2 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg"
  },
  "MX": {
    "name": "Mexico",
    "emoji": "🇲🇽",
    "unicode": "U+1F1F2 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg"
  },
  "MY": {
    "name": "Malaysia",
    "emoji": "🇲🇾",
    "unicode": "U+1F1F2 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg"
  },
  "MZ": {
    "name": "Mozambique",
    "emoji": "🇲🇿",
    "unicode": "U+1F1F2 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg"
  },
  "NA": {
    "name": "Namibia",
    "emoji": "🇳🇦",
    "unicode": "U+1F1F3 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg"
  },
  "NC": {
    "name": "New Caledonia",
    "emoji": "🇳🇨",
    "unicode": "U+1F1F3 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg"
  },
  "NE": {
    "name": "Niger",
    "emoji": "🇳🇪",
    "unicode": "U+1F1F3 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg"
  },
  "NF": {
    "name": "Norfolk Island",
    "emoji": "🇳🇫",
    "unicode": "U+1F1F3 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg"
  },
  "NG": {
    "name": "Nigeria",
    "emoji": "🇳🇬",
    "unicode": "U+1F1F3 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg"
  },
  "NI": {
    "name": "Nicaragua",
    "emoji": "🇳🇮",
    "unicode": "U+1F1F3 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg"
  },
  "NL": {
    "name": "Netherlands",
    "emoji": "🇳🇱",
    "unicode": "U+1F1F3 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg"
  },
  "NO": {
    "name": "Norway",
    "emoji": "🇳🇴",
    "unicode": "U+1F1F3 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg"
  },
  "NP": {
    "name": "Nepal",
    "emoji": "🇳🇵",
    "unicode": "U+1F1F3 U+1F1F5",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg"
  },
  "NR": {
    "name": "Nauru",
    "emoji": "🇳🇷",
    "unicode": "U+1F1F3 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg"
  },
  "NU": {
    "name": "Niue",
    "emoji": "🇳🇺",
    "unicode": "U+1F1F3 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg"
  },
  "NZ": {
    "name": "New Zealand",
    "emoji": "🇳🇿",
    "unicode": "U+1F1F3 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg"
  },
  "OM": {
    "name": "Oman",
    "emoji": "🇴🇲",
    "unicode": "U+1F1F4 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg"
  },
  "PA": {
    "name": "Panama",
    "emoji": "🇵🇦",
    "unicode": "U+1F1F5 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg"
  },
  "PE": {
    "name": "Peru",
    "emoji": "🇵🇪",
    "unicode": "U+1F1F5 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg"
  },
  "PF": {
    "name": "French Polynesia",
    "emoji": "🇵🇫",
    "unicode": "U+1F1F5 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg"
  },
  "PG": {
    "name": "Papua New Guinea",
    "emoji": "🇵🇬",
    "unicode": "U+1F1F5 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg"
  },
  "PH": {
    "name": "Philippines",
    "emoji": "🇵🇭",
    "unicode": "U+1F1F5 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg"
  },
  "PK": {
    "name": "Pakistan",
    "emoji": "🇵🇰",
    "unicode": "U+1F1F5 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg"
  },
  "PL": {
    "name": "Poland",
    "emoji": "🇵🇱",
    "unicode": "U+1F1F5 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg"
  },
  "PM": {
    "name": "St. Pierre & Miquelon",
    "emoji": "🇵🇲",
    "unicode": "U+1F1F5 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg"
  },
  "PN": {
    "name": "Pitcairn Islands",
    "emoji": "🇵🇳",
    "unicode": "U+1F1F5 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg"
  },
  "PR": {
    "name": "Puerto Rico",
    "emoji": "🇵🇷",
    "unicode": "U+1F1F5 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg"
  },
  "PS": {
    "name": "Palestinian Territories",
    "emoji": "🇵🇸",
    "unicode": "U+1F1F5 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg"
  },
  "PT": {
    "name": "Portugal",
    "emoji": "🇵🇹",
    "unicode": "U+1F1F5 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg"
  },
  "PW": {
    "name": "Palau",
    "emoji": "🇵🇼",
    "unicode": "U+1F1F5 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg"
  },
  "PY": {
    "name": "Paraguay",
    "emoji": "🇵🇾",
    "unicode": "U+1F1F5 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg"
  },
  "QA": {
    "name": "Qatar",
    "emoji": "🇶🇦",
    "unicode": "U+1F1F6 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg"
  },
  "RE": {
    "name": "Réunion",
    "emoji": "🇷🇪",
    "unicode": "U+1F1F7 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg"
  },
  "RO": {
    "name": "Romania",
    "emoji": "🇷🇴",
    "unicode": "U+1F1F7 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg"
  },
  "RS": {
    "name": "Serbia",
    "emoji": "🇷🇸",
    "unicode": "U+1F1F7 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg"
  },
  "RU": {
    "name": "Russia",
    "emoji": "🇷🇺",
    "unicode": "U+1F1F7 U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg"
  },
  "RW": {
    "name": "Rwanda",
    "emoji": "🇷🇼",
    "unicode": "U+1F1F7 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg"
  },
  "SA": {
    "name": "Saudi Arabia",
    "emoji": "🇸🇦",
    "unicode": "U+1F1F8 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg"
  },
  "SB": {
    "name": "Solomon Islands",
    "emoji": "🇸🇧",
    "unicode": "U+1F1F8 U+1F1E7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg"
  },
  "SC": {
    "name": "Seychelles",
    "emoji": "🇸🇨",
    "unicode": "U+1F1F8 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg"
  },
  "SD": {
    "name": "Sudan",
    "emoji": "🇸🇩",
    "unicode": "U+1F1F8 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg"
  },
  "SE": {
    "name": "Sweden",
    "emoji": "🇸🇪",
    "unicode": "U+1F1F8 U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg"
  },
  "SG": {
    "name": "Singapore",
    "emoji": "🇸🇬",
    "unicode": "U+1F1F8 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg"
  },
  "SH": {
    "name": "St. Helena",
    "emoji": "🇸🇭",
    "unicode": "U+1F1F8 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg"
  },
  "SI": {
    "name": "Slovenia",
    "emoji": "🇸🇮",
    "unicode": "U+1F1F8 U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg"
  },
  "SJ": {
    "name": "Svalbard & Jan Mayen",
    "emoji": "🇸🇯",
    "unicode": "U+1F1F8 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg"
  },
  "SK": {
    "name": "Slovakia",
    "emoji": "🇸🇰",
    "unicode": "U+1F1F8 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg"
  },
  "SL": {
    "name": "Sierra Leone",
    "emoji": "🇸🇱",
    "unicode": "U+1F1F8 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg"
  },
  "SM": {
    "name": "San Marino",
    "emoji": "🇸🇲",
    "unicode": "U+1F1F8 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg"
  },
  "SN": {
    "name": "Senegal",
    "emoji": "🇸🇳",
    "unicode": "U+1F1F8 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg"
  },
  "SO": {
    "name": "Somalia",
    "emoji": "🇸🇴",
    "unicode": "U+1F1F8 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg"
  },
  "SR": {
    "name": "Suriname",
    "emoji": "🇸🇷",
    "unicode": "U+1F1F8 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg"
  },
  "SS": {
    "name": "South Sudan",
    "emoji": "🇸🇸",
    "unicode": "U+1F1F8 U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg"
  },
  "ST": {
    "name": "São Tomé & Príncipe",
    "emoji": "🇸🇹",
    "unicode": "U+1F1F8 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg"
  },
  "SV": {
    "name": "El Salvador",
    "emoji": "🇸🇻",
    "unicode": "U+1F1F8 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg"
  },
  "SX": {
    "name": "Sint Maarten",
    "emoji": "🇸🇽",
    "unicode": "U+1F1F8 U+1F1FD",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg"
  },
  "SY": {
    "name": "Syria",
    "emoji": "🇸🇾",
    "unicode": "U+1F1F8 U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg"
  },
  "SZ": {
    "name": "Eswatini",
    "emoji": "🇸🇿",
    "unicode": "U+1F1F8 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg"
  },
  "TA": {
    "name": "Tristan da Cunha",
    "emoji": "🇹🇦",
    "unicode": "U+1F1F9 U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg"
  },
  "TC": {
    "name": "Turks & Caicos Islands",
    "emoji": "🇹🇨",
    "unicode": "U+1F1F9 U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg"
  },
  "TD": {
    "name": "Chad",
    "emoji": "🇹🇩",
    "unicode": "U+1F1F9 U+1F1E9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg"
  },
  "TF": {
    "name": "French Southern Territories",
    "emoji": "🇹🇫",
    "unicode": "U+1F1F9 U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg"
  },
  "TG": {
    "name": "Togo",
    "emoji": "🇹🇬",
    "unicode": "U+1F1F9 U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg"
  },
  "TH": {
    "name": "Thailand",
    "emoji": "🇹🇭",
    "unicode": "U+1F1F9 U+1F1ED",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg"
  },
  "TJ": {
    "name": "Tajikistan",
    "emoji": "🇹🇯",
    "unicode": "U+1F1F9 U+1F1EF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg"
  },
  "TK": {
    "name": "Tokelau",
    "emoji": "🇹🇰",
    "unicode": "U+1F1F9 U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg"
  },
  "TL": {
    "name": "Timor-Leste",
    "emoji": "🇹🇱",
    "unicode": "U+1F1F9 U+1F1F1",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg"
  },
  "TM": {
    "name": "Turkmenistan",
    "emoji": "🇹🇲",
    "unicode": "U+1F1F9 U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg"
  },
  "TN": {
    "name": "Tunisia",
    "emoji": "🇹🇳",
    "unicode": "U+1F1F9 U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg"
  },
  "TO": {
    "name": "Tonga",
    "emoji": "🇹🇴",
    "unicode": "U+1F1F9 U+1F1F4",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg"
  },
  "TR": {
    "name": "Turkey",
    "emoji": "🇹🇷",
    "unicode": "U+1F1F9 U+1F1F7",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg"
  },
  "TT": {
    "name": "Trinidad & Tobago",
    "emoji": "🇹🇹",
    "unicode": "U+1F1F9 U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg"
  },
  "TV": {
    "name": "Tuvalu",
    "emoji": "🇹🇻",
    "unicode": "U+1F1F9 U+1F1FB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg"
  },
  "TW": {
    "name": "Taiwan",
    "emoji": "🇹🇼",
    "unicode": "U+1F1F9 U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg"
  },
  "TZ": {
    "name": "Tanzania",
    "emoji": "🇹🇿",
    "unicode": "U+1F1F9 U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg"
  },
  "UA": {
    "name": "Ukraine",
    "emoji": "🇺🇦",
    "unicode": "U+1F1FA U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg"
  },
  "UG": {
    "name": "Uganda",
    "emoji": "🇺🇬",
    "unicode": "U+1F1FA U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg"
  },
  "UM": {
    "name": "U.S. Outlying Islands",
    "emoji": "🇺🇲",
    "unicode": "U+1F1FA U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg"
  },
  "UN": {
    "name": "United Nations",
    "emoji": "🇺🇳",
    "unicode": "U+1F1FA U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg"
  },
  "US": {
    "name": "United States",
    "emoji": "🇺🇸",
    "unicode": "U+1F1FA U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
  },
  "UY": {
    "name": "Uruguay",
    "emoji": "🇺🇾",
    "unicode": "U+1F1FA U+1F1FE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg"
  },
  "UZ": {
    "name": "Uzbekistan",
    "emoji": "🇺🇿",
    "unicode": "U+1F1FA U+1F1FF",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg"
  },
  "VA": {
    "name": "Vatican City",
    "emoji": "🇻🇦",
    "unicode": "U+1F1FB U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg"
  },
  "VC": {
    "name": "St. Vincent & Grenadines",
    "emoji": "🇻🇨",
    "unicode": "U+1F1FB U+1F1E8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg"
  },
  "VE": {
    "name": "Venezuela",
    "emoji": "🇻🇪",
    "unicode": "U+1F1FB U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg"
  },
  "VG": {
    "name": "British Virgin Islands",
    "emoji": "🇻🇬",
    "unicode": "U+1F1FB U+1F1EC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg"
  },
  "VI": {
    "name": "U.S. Virgin Islands",
    "emoji": "🇻🇮",
    "unicode": "U+1F1FB U+1F1EE",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg"
  },
  "VN": {
    "name": "Vietnam",
    "emoji": "🇻🇳",
    "unicode": "U+1F1FB U+1F1F3",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg"
  },
  "VU": {
    "name": "Vanuatu",
    "emoji": "🇻🇺",
    "unicode": "U+1F1FB U+1F1FA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg"
  },
  "WF": {
    "name": "Wallis & Futuna",
    "emoji": "🇼🇫",
    "unicode": "U+1F1FC U+1F1EB",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg"
  },
  "WS": {
    "name": "Samoa",
    "emoji": "🇼🇸",
    "unicode": "U+1F1FC U+1F1F8",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg"
  },
  "XK": {
    "name": "Kosovo",
    "emoji": "🇽🇰",
    "unicode": "U+1F1FD U+1F1F0",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg"
  },
  "YE": {
    "name": "Yemen",
    "emoji": "🇾🇪",
    "unicode": "U+1F1FE U+1F1EA",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg"
  },
  "YT": {
    "name": "Mayotte",
    "emoji": "🇾🇹",
    "unicode": "U+1F1FE U+1F1F9",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg"
  },
  "ZA": {
    "name": "South Africa",
    "emoji": "🇿🇦",
    "unicode": "U+1F1FF U+1F1E6",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg"
  },
  "ZM": {
    "name": "Zambia",
    "emoji": "🇿🇲",
    "unicode": "U+1F1FF U+1F1F2",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg"
  },
  "ZW": {
    "name": "Zimbabwe",
    "emoji": "🇿🇼",
    "unicode": "U+1F1FF U+1F1FC",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg"
  },
  "ENGLAND": {
    "name": "England",
    "emoji": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg"
  },
  "SCOTLAND": {
    "name": "Scotland",
    "emoji": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg"
  },
  "WALES": {
    "name": "Wales",
    "emoji": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    "unicode": "U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F",
    "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg"
  }
}
