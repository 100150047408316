import { ArticlesContextProvider } from "src/context/articles-context";
import { AuthContextProvider } from "src/context/auth-context";
import { BioContextProvider } from "src/context/bio-context";
import { EditorContextProvider } from "src/context/editor-context";
import { PostsContextProvider } from "src/context/posts-context";
import { RegisterContextProvider } from "src/context/register-context";
import { ShareContextProvider } from "src/context/share-context";

const ContextProvider = ({ children }: any) => {
  return (
    <AuthContextProvider>
      <RegisterContextProvider>
        <BioContextProvider>
          <EditorContextProvider>
            <PostsContextProvider>
              <ArticlesContextProvider>
                <ShareContextProvider>{children}</ShareContextProvider>
              </ArticlesContextProvider>
            </PostsContextProvider>
          </EditorContextProvider>
        </BioContextProvider>
      </RegisterContextProvider>
    </AuthContextProvider>
  );
};

export default ContextProvider;
