import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "src/components/atoms/text-input";
import PanelLayout from "src/components/layouts/panel-layout";
import { useGetFeedbackMutation } from "src/services/user-feedback";
import RatingStars from "src/components/atoms/rating-stars";
import { capitalizeFirstLetter, showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";

const ViewUserFeedback = () => {
  const { id } = useParams();
  const [getFeedback, { data }] = useGetFeedbackMutation();
  const navigate = useNavigate();

  useEffect(() => {
    getFeedback({ id });
  }, []);

  const handleTextareaResize = () => {
    const textarea = document.getElementById("text-area-feedback"); // Replace with your actual ID
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight + 20}px`;
    }
  };

  const viewUserProfile = () => {
    let toastId;
    if (data?.data?.is_user_deleted) {
      toast.dismiss(toastId);
      showToast(
        toast,
        TOASTR_TYPES.WARNING,
        "This user account does not currently exist on the platform",
        "Alert!",
      );
    } else {
      navigate(`/admin/users/${data?.data?.user_id}?activeTab=1`);
    }
  };

  useEffect(() => {
    handleTextareaResize();
  }, [data?.data?.feedback]);

  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <div className="grid gap-5 xl:grid-cols-12">
        <div className="order-2 block xl:order-1 xl:col-span-8 xxl:col-span-9">
          <div className="rounded-xl bg-gray-400">
            <div className="border-b border-border-color p-24">
              <h4 className="text-16 font-semibold leading-24 text-white-heading">
                Feedback
              </h4>
            </div>
            <div className="p-24">
              <form className="flex flex-col gap-5">
                <div className="">
                  <label
                    htmlFor="rating-stars"
                    className="mb-6 block text-14 font-medium leading-20 text-white-heading"
                  >
                    Rate
                  </label>
                  <div className="relative">
                    <RatingStars value={data?.data?.rate} />
                  </div>
                </div>
                <TextInput
                  name="email"
                  type="email"
                  label="Title"
                  value={data?.data?.title}
                  disabled={true}
                />
                <div className="">
                  <label
                    htmlFor="text-area-feedback"
                    className="mb-6 block text-14 font-medium leading-20 text-white-heading"
                  >
                    Feedback
                  </label>
                  <div className="relative">
                    <textarea
                      className="max-h-[500px] w-full cursor-not-allowed resize-none overflow-y-auto rounded border border-border-color bg-input-background px-16 py-11 text-16 font-normal leading-24 text-placeholder-text-color"
                      value={data?.data?.feedback}
                      id="text-area-feedback"
                      onChange={handleTextareaResize}
                      disabled={true}
                    >
                      {data?.data?.feedback}
                    </textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="order-1 xl:order-2 xl:col-span-4 xxl:col-span-3">
          <div className="rounded-xl bg-gray-400">
            <div className="flex flex-wrap items-center justify-between gap-2 border-b border-border-color p-24">
              <h4 className="text-16 font-semibold leading-24 text-white-heading">
                Sender
              </h4>
              <button
                onClick={viewUserProfile}
                className="inline-block cursor-pointer text-16 font-semibold leading-24 text-white-heading"
              >
                See profile
              </button>
            </div>
            <div className="p-24">
              <form className="flex flex-col gap-5">
                <TextInput
                  name="email"
                  type="email"
                  label="Name"
                  value={data?.data?.user_name}
                  disabled={true}
                />
                <TextInput
                  name="email"
                  type="email"
                  label="Role"
                  value={capitalizeFirstLetter(data?.data?.user_role)}
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </PanelLayout>
  );
};

export default ViewUserFeedback;
