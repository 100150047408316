// "use client"
import React from 'react'
import MainComponent from './main.comonent'

const MainContainer = () => {
    return (
        <MainComponent />
    )
}

export default MainContainer