import React, { useState } from "react";
import { IPersonalDetailModalModal } from "./personal-detail-modal.types";
import CustomModal from "src/components/molecules/custom-modal";
import CustomButton from "src/components/atoms/button";
import TextInput from "src/components/atoms/text-input";
import SelectCountry from "src/components/atoms/select-country";
import CustomDatePicker from "src/components/atoms/date-picker/custom-date-picker.component";
import ImageUpload from "src/components/atoms/image-upload";
import dayjs from "dayjs";

const PersonalDetailModalComponent = ({
  isOpen,
  openModal,
  toggle,
  formik,
}: IPersonalDetailModalModal) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <CustomModal
      isOpen={isOpen}
      openModal={openModal}
      toggle={toggle}
      innerClass="xl:w-[903px]"
    >
      <div className={`my-20 w-full rounded-xl bg-gray-400`}>
        <div className="rounded-t-xl border-b border-border-color p-24 text-16 font-semibold leading-24 text-white-heading">
          Edit
        </div>
        <div className="grid-cols-4 xl:grid">
          <div className="col-span-1 border border-b-0 border-t-0 border-border-color p-32 xl:border-r-0">
            <ImageUpload
              name="image"
              value={formik?.values?.image}
              image={formik?.values?.image}
              className="mx-auto mb-39 bg-gray-300"
              onChange={(img: string) => {
                formik?.setFieldValue("image", img);
              }}
              onDelete={() => formik?.setFieldValue("image", "")}
              showError={Boolean(formik?.touched.image)}
              errorMessage={formik?.errors.image}
              setDisabled={setDisabled}
              isOpenModal={isOpen}
              useCropper={true}
              disableAspectRatio={true}
            />
            <div className="text-center text-12 font-normal leading-16 tracking-0.24 text-white-para">
              Support PNG, JPG or <br />
              JPEG (max. 10Mb)
            </div>
          </div>
          <div className="modal-scroll col-span-3 max-h-[390px] overflow-y-auto border border-b-0  border-border-color p-24 xl:border-t-0">
            <form className="grid grid-cols-1 gap-4  md:grid-cols-2">
              <TextInput
                name="firstName"
                type="text"
                label="First name"
                value={formik?.values.firstName}
                showError={Boolean(formik?.touched.firstName)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.firstName}
                required={true}
              />
              <TextInput
                name="lastName"
                type="text"
                label="Last name"
                value={formik?.values.lastName}
                showError={Boolean(formik?.touched.lastName)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.lastName}
                required={true}
              />

              <SelectCountry
                name="country"
                label="Country"
                containerClassName="custom-theme theme-form-select"
                showError={Boolean(formik?.touched.country)}
                errorMessage={formik?.errors.country}
                onSelect={(code: string) => {
                  formik?.setFieldValue("country", code);
                  document.getElementById("rfs-btn")?.click();
                }}
                value={formik?.values?.country}
                required={true}
              />

              <TextInput
                name="profession"
                type="text"
                label="Profession"
                value={formik?.values.profession}
                showError={Boolean(formik?.touched.profession)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.profession}
                required={true}
              />

              <TextInput
                name="officeName"
                type="text"
                label="Office name"
                value={formik?.values.officeName}
                showError={Boolean(formik?.touched.officeName)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.officeName}
                required={false}
              />

              <TextInput
                name="height"
                type="text"
                label="Height (cm)"
                value={formik?.values.height}
                showError={Boolean(formik?.touched.height)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.height}
                required={true}
                className="no-spinners"
                allowNumbersOnly={true}
              />

              <TextInput
                name="education"
                type="text"
                label="Education"
                value={formik?.values.education}
                showError={Boolean(formik?.touched.education)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.education}
                required={false}
              />
              <CustomDatePicker
                name="dob"
                label="Date of birth"
                type="text"
                value={formik?.values?.dob}
                onChange={(date: any, dateString: any) => {
                  formik?.setFieldValue(
                    "dob",
                    date ? dayjs(date).format("YYYY-MM-DD") : "",
                  );
                }}
                errorMessage={formik?.errors?.dob}
                showError={formik?.touched?.dob}
                required={false}
              />
              <TextInput
                name="city"
                type="text"
                label="City of birth"
                value={formik?.values.city}
                showError={Boolean(formik?.touched.city)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.city}
                required={false}
              />
              <TextInput
                name="website"
                type="text"
                label="Website"
                value={formik?.values.website}
                showError={Boolean(formik?.touched.website)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.website}
                required={false}
              />
              <TextInput
                name="linkedinLink"
                type="text"
                label="LinkedIn"
                value={formik?.values.linkedinLink}
                showError={Boolean(formik?.touched.linkedinLink)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.linkedinLink}
                required={false}
              />
              <TextInput
                name="twitterLink"
                type="text"
                label="X (Twitter)"
                value={formik?.values.twitterLink}
                showError={Boolean(formik?.touched.twitterLink)}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                errorMessage={formik?.errors.twitterLink}
                required={false}
              />
            </form>
          </div>
        </div>
        <div className="flex flex-col justify-end gap-3 rounded-b-xl border-b border-t border-border-color p-24 sm:flex-row">
          <CustomButton
            title="Save"
            variant="primary"
            loading={formik?.isValid && formik?.isSubmitting}
            onClick={() => {
              formik?.handleSubmit();
              console.log(formik);
            }}
            disabled={disabled}
          />
          <CustomButton
            title="Cancel"
            variant="secondary"
            onClick={() => {
              toggle();
            }}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default PersonalDetailModalComponent;
