import { validations } from "src/constants";
import * as yup from "yup";

export const changeEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Invalid email address")
    .matches(validations.EMAIL, "Invalid email address"),
});
