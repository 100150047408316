import React, { useState, useLayoutEffect } from "react";
import { CKEditor as CKEditorComponent } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5";
import { IEditor } from "./editor.types";
import ErrorLabel from "src/components/atoms/error-label";
import { getBiographyError } from "src/context/register-context/types";
import { editorConfig } from "./editor.config";

const EditorComponent = ({
  id,
  bio,
  uploadPlugin,
  disabled = false,
  formik,
}: IEditor) => {
  const [editor, setEditor] = useState(
    formik?.values?.sections ? formik?.values?.sections[id].description : "",
  );

  useLayoutEffect(() => {
    setEditor(
      formik?.values?.sections ? formik?.values?.sections[id].description : "",
    );
  }, [formik?.values?.sections]);

  const handleChange = (event: any, editor: any) => {
    console.log(editor.getData());
    setEditor(editor.getData());

    const name = "description";
    if (formik?.values.sections) {
      const sections = [...(formik?.values.sections || [])];
      sections[id][name] = editor.getData();

      formik?.setValues({
        ...formik.values,
        sections: sections,
      });
    }
  };

  const customEditorConfig = {
    ...editorConfig,
    extraPlugins: [uploadPlugin],
  };

  const handleBlur = (e: any) => {
    formik?.setFieldTouched(`sections[${id}].${"description"}`, true);
  };

  return (
    <>
      <div className={bio?.className}>
        <CKEditorComponent
          config={customEditorConfig}
          editor={ClassicEditor}
          onChange={handleChange}
          data={editor}
          onBlur={handleBlur}
          disabled={disabled}
          onReady={() => {}}
        />
      </div>

      {formik?.touched?.sections &&
        formik?.touched?.sections[id]?.description && (
          <ErrorLabel
            message={getBiographyError(formik, id, "description")}
            className="bio-error mb-6 px-10"
          />
        )}
    </>
  );
};

export default EditorComponent;
