import React from 'react'

const IcNotificationBell = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={21}
  viewBox="0 0 20 21"
  fill="none"
>
  <path
    d="M12.2047 17.1668C11.6171 17.6854 10.8453 18.0001 9.99996 18.0001C9.15462 18.0001 8.38278 17.6854 7.79517 17.1668M14.9978 11.4842V8.8335C14.9978 6.06493 12.7685 3.8335 9.99996 3.8335C7.23139 3.8335 4.97193 5.9698 4.97193 8.8335V11.4662C4.97193 11.8689 4.90918 12.2689 4.78608 12.6508L4.17069 14.5604C4.15384 14.6127 4.19147 14.6667 4.24475 14.6667H15.7193C15.7756 14.6667 15.8156 14.6118 15.7983 14.5582L15.1788 12.6356C15.0589 12.2636 14.9978 11.8751 14.9978 11.4842Z"
    stroke="#E0DBDB"
    strokeWidth="1.5"
    strokeLinecap="round"
  />
</svg>


  )
}

export default IcNotificationBell