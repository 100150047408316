import React from "react";

const IcFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.28886 3.75H16.7107C16.832 3.75004 16.9506 3.78533 17.0521 3.85159C17.1536 3.91784 17.2337 4.01219 17.2826 4.12315C17.3314 4.2341 17.3469 4.35687 17.3272 4.47649C17.3076 4.59611 17.2535 4.70743 17.1717 4.79688L12.0389 10.4453C11.932 10.5597 11.8733 10.7107 11.8748 10.8672V15.2891C11.8758 15.3929 11.8505 15.4954 11.8013 15.5869C11.7522 15.6784 11.6807 15.756 11.5936 15.8125L9.09355 17.4766C8.99977 17.5383 8.89109 17.5737 8.77892 17.5789C8.66675 17.5842 8.55524 17.5591 8.45611 17.5063C8.35699 17.4536 8.27391 17.3751 8.21561 17.2791C8.15731 17.1831 8.12594 17.0732 8.1248 16.9609V10.8672C8.12631 10.7107 8.06757 10.5597 7.96074 10.4453L2.82793 4.79688C2.7461 4.70743 2.69204 4.59611 2.67236 4.47649C2.65267 4.35687 2.6682 4.2341 2.71705 4.12315C2.76591 4.01219 2.84597 3.91784 2.9475 3.85159C3.04903 3.78533 3.16763 3.75004 3.28886 3.75V3.75Z"
        stroke="#FAF7F7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcFilter;
