import React, { useState } from "react";
import ShareCardComponent from "./share-card.component";
import copy from "clipboard-copy";
import { useShareContext } from "src/context/share-context";

const ShareCardComponentContainer = () => {
  const [isCopied, setIsCopied] = useState(false);

  const { shareData } = useShareContext();

  const handleCopyClick = async () => {
    const context = document?.querySelector(
      ".share-profile-content",
    )?.textContent;

    const textToCopy = `${context} \n ${shareData?.url}`;

    await copy(textToCopy || "");
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <ShareCardComponent handleCopyClick={handleCopyClick} isCopied={isCopied} />
  );
};

export default ShareCardComponentContainer;
