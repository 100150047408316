import React from "react";
import ShareTwiiterComponent from "./share-twiter.component";
import { SHARE_PLATFORM } from "../share-button/types";

const ShareTwitterContainer = ({ handleShareLink }: any) => {
  const handleClick = () => {
    handleShareLink(SHARE_PLATFORM.TWITTER);
  };
  return <ShareTwiiterComponent handleClick={handleClick} />;
};

export default ShareTwitterContainer;
