import { TODO } from "src/types";

export interface IUseLoadMore {
  initialData: TODO;
  startPage: TODO;
  filter?: TODO;
  mutationType: MUTATION_TYPE;
  userId?: any;
  defaultLoading: boolean;
  itemsPerPage?: number;
}

export enum MUTATION_TYPE {
  MY_POSTS = "MY_POSTS",
  OTHER_USER = "OTHER_USER",
  ALL_MEMBERS = "ALL_MEMBERS",
  ALL_POSTS = "ALL_POSTS",
}
