import React from "react";
import { IDescriptionView } from "./types";

const DescriptionViewComponent = ({
  description,
  className = "",
}: IDescriptionView) => {
  return (
    <div
      className={`description-paragraph ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline text-14 font-normal leading-24 text-white-para ${className}`}
      dangerouslySetInnerHTML={{ __html: description }}
    ></div>
  );
};

export default React.memo(DescriptionViewComponent);
