import React, { useState, ChangeEvent } from "react";

const ContentLeaderToggleComponent = ({
  user,
  handleToggle,
  checked,
  loading,
}: any) => {
  const [isContentLeader, setIsContentLeader] = useState<boolean>(
    user?.content_leader || false,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = !e.target.checked;
    handleToggle(checked);
    setIsContentLeader(checked);
  };

  return (
    <div className="toggle">
      <input
        onChange={handleChange}
        type="checkbox"
        id={`checkbox-${user.id}`} // Ensure a unique ID
        name={user.id}
        checked={!isContentLeader}
        disabled={loading}
      />
      <label htmlFor={`checkbox-${user.id}`}>
        {" "}
        {/* Use the same unique ID */}
        <div className="toggleLabel">
          <span className={`${!isContentLeader ? "text-[#1E1E2C]" : ""}`}>
            No
          </span>
          <span className={`ml-12 ${!isContentLeader ? "" : "text-[#1E1E2C]"}`}>
            Yes
          </span>
        </div>
        <div className="toggler" />
      </label>
    </div>
  );
};

export default ContentLeaderToggleComponent;
