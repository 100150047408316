import React from 'react'
import SettingsFormComponent from './settings-form.component'


const SettingsFormContainer = () => {
  
  return (
    <SettingsFormComponent/>
  )
}

export default SettingsFormContainer