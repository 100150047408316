import React from 'react'
import { Link } from 'react-router-dom'

const SideBarHeaderCompoent = () => {
    return (
        <div className="flex justify-center md:justify-start">
            <Link to={"/"}>
                <img className="mb-54  block"
                    src="/assets/images/PIO_logo.svg" alt="Brand Logo"
                />
            </Link>
        </div>
    )
}

export default SideBarHeaderCompoent