import React from "react";
import ContentLeaderToggleComponent from "./content-leader-toggle.component";
import { IContentLeader } from "./types";
import { useToggleContentLeaderMutation } from "src/services/users";
import { showToast } from "src/utils";
import { TOASTR_TYPES } from "src/types";
import { toast } from "react-toastify";
import useLoadingError from "src/hooks/useLoadingError";

const ContentLeaderToggleContainer = (props: IContentLeader) => {
  const { loading, startLoading, stopLoading } = useLoadingError();
  const [toggleContentLeader] = useToggleContentLeaderMutation();
  const handleToggle = (status: boolean) => {
    startLoading();
    toggleContentLeader({
      user_id: props?.user?.id,
      is_content_leader: status,
    })
      .unwrap()
      .then((res: any) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
      })
      .catch((err: any) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <ContentLeaderToggleComponent
      {...props}
      handleToggle={handleToggle}
      loading={loading}
    />
  );
};

export default ContentLeaderToggleContainer;
