import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { commonRoutes, adminRoutes } from "./routes";
import AppRoute from "./routes/AppRoute";
import "react-toastify/dist/ReactToastify.css";
import ToastContainer from "./components/atoms/toast-container";
import ContextProvider from "./providers/context-provider";

function App() {
  return (
    <ContextProvider>
      <Routes>
        {[...commonRoutes, ...adminRoutes].map((route, i) => {
          return (
            <Route
              key={route?.path}
              path={route?.path}
              element={
                <AppRoute
                  isAuthProtected={route?.isProtected}
                  Component={route?.component}
                ></AppRoute>
              }
            ></Route>
          );
        })}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>

      <ToastContainer />
    </ContextProvider>
  );
}

export default App;
