import React, { useState } from "react";
import { columns } from "./columns";
import DataTableComponent from "./data-table.component";
import usePagination from "src/hooks/usePagination";
import ConfirmDelete from "src/components/organisms/confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";
import CustomButton from "src/components/atoms/button";
import {
  useDashboardStatsMutation,
  useDeleteUserMutation,
  useGetUsersListingMutation,
} from "src/services/users";
import AddUserForm from "../add-user-form";
import { useNavigate } from "react-router-dom";

const DataTableContainer = () => {
  const [getUsersListing] = useGetUsersListingMutation();

  const {
    pagination,
    handlePageChange,
    handleItemsPerPageChange,
    data,
    handleSearch,
    clearSearch,
    fetchData,
  } = usePagination({ key: "users-datatable", mutation: getUsersListing });

  const [getDashboardStats] = useDashboardStatsMutation();

  const navigate = useNavigate();

  // for delete popup
  const { isOpen, toggle } = useModal();

  // for add featured member
  const { isOpen: isOpenAddUser, toggle: toggleAddUser } = useModal();

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [deleteUser] = useDeleteUserMutation();

  const { loading, startLoading, stopLoading } = useLoadingError();

  const showDeletePopup = (id: number) => {
    setDeleteId(id);
    toggle();
  };

  const confirmDelete = () => {
    startLoading();
    deleteUser({ id: deleteId })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        fetchData({ currentPage: 1, query: "" });
        toggle();
        getDashboardStats({});
        console.log(res, "response comes herer");
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        console.log(err, "error comes here");
      })
      .finally(() => {
        stopLoading();
      });
  };

  const onRowClicked = (row: any) => {
    sessionStorage.removeItem("user-tabs");
    navigate(`/admin/users/${row?.id}`);
  };

  // Total ${pagination?.total} ${pagination?.total  > 1? "users": "user"
  const total = pagination?.total;
  let description;

  if (total === 0) {
    description = "No users";
  } else if (total > 1) {
    description = `Total ${total} users`;
  } else {
    description = `Total ${total} user`;
  }

  return (
    <>
      <DataTableComponent
        title={"Users"}
        description={description}
        className="date-picker"
        data={data}
        columns={columns(showDeletePopup)}
        showHeader={true}
        showFooter={true}
        pagination={pagination}
        handleSearch={handleSearch}
        handlePageChange={handlePageChange}
        handleItemsPerPageChange={handleItemsPerPageChange}
        onRowClicked={onRowClicked}
        clearSearch={clearSearch}
        button={
          <CustomButton
            title="Add user"
            variant="primary"
            onClick={toggleAddUser}
          />
        }
      />

      <ConfirmDelete
        title="Are you sure you want to remove this user?"
        message="The data you delete cannot be accessed again"
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
      />

      <AddUserForm
        isOpen={isOpenAddUser}
        toggle={toggleAddUser}
        fetchData={fetchData}
      />

      {/* <AddMember isOpen={isOpenMember} toggle={toggleMember} members={usersData?.data || []} getMembersListing={getMembersListing} fetchData={fetchData}/> */}
    </>
  );
};

export default DataTableContainer;
