import React, { useEffect, useRef, useState } from "react";

import PannelLayoutComponent from "./panel-layout.component";
import SideBar from "src/components/organisms/sidebar";
import BreadCrumbs from "src/components/molecules/breadcrumbs";
import ProfileDropDown from "src/components/molecules/profile-dropdown";
import { Icon } from "src/components/atoms/icons";
import { useAppSelector } from "src/store/hook";
import { alertsSelector } from "src/store/features/alerts/selectors";
import AppLoader from "src/components/atoms/app-loader/app-loader.component";
import { useLocation } from "react-router-dom";

const PanelLayoutContainer = (props: any) => {
  const alerts = useAppSelector(alertsSelector);

  const location = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const paths = [
      "users",
      "admin-users",
      "featured-members",
      "user-feedback",
      "topics",
    ];
    const currentPath = location?.pathname?.split("/");

    paths.forEach((path) => {
      if (!currentPath.includes(path)) {
        sessionStorage.removeItem(`${path}-datatable`);
      }
    });
  }, [location]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024);
    };

    const handleClickOutside = (event: any) => {
      console.log("==event.target", sidebarRef.current);
      console.log(
        "==sidebarRef.current",
        !sidebarRef.current?.contains(event.target),
      );
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      document.body.style.overflow = isSidebarOpen ? "hidden" : "auto";
    }
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="main-container">
      <div className={`relative left-0 top-0 block h-screen  md:pl-[248px]`}>
        <div className="flex w-full flex-wrap items-start gap-y-4 border-b-[0.5px] border-border-color bg-gray-500 px-24 py-19">
          <div ref={sidebarRef} className="mobile-sidebar z-[99]">
            <div
              className={`fixed left-0 top-0 z-10 h-full w-[290px] transition-all duration-300 ease-in-out md:block md:w-[248px] ${
                isSidebarOpen ? "ml-0" : "-ml-[290px] sm:-ml-[290px]"
              } md:ml-0`}
            >
              <div className="h-full bg-gray-400 p-10 px-20 py-27">
                <SideBar
                  isSidebarOpen={isSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  setIsSidebarOpen={setIsSidebarOpen}
                />
              </div>
            </div>
            <button
              onClick={toggleSidebar}
              className={`relative md:hidden ${
                isSidebarOpen ? "hidden" : "block"
              }`}
            >
              <Icon.IcHamburgerMenu />
            </button>
          </div>
          <BreadCrumbs />
          <ProfileDropDown />
        </div>
        <div className={props?.wrapperClass}>
          {alerts?.loading ? (
            <AppLoader />
          ) : (
            <PannelLayoutComponent {...props} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PanelLayoutContainer;
