import { Icon } from "src/components/atoms/icons";
import { usePostsContext } from "src/context/posts-context";
import { TODO } from "src/types";
import React from "react";

const DeletePostBtnComponent = ({ id }: TODO) => {
  const { toggleConfirmDelete, setDeletePostId } = usePostsContext();
  return (
    <button
      onClick={() => {
        setDeletePostId(id);
        toggleConfirmDelete();
      }}
    >
      <Icon.IcDeletePost />
    </button>
  );
};

export default DeletePostBtnComponent;
