import React from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import DataTable from "../elements/data-table";

const Library = () => {
  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <DataTable />
    </PanelLayout>
  );
};

export default Library;
