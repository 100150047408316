import React from 'react'
import CustomButton from 'src/components/atoms/button'
import TextInput from 'src/components/atoms/text-input'
import { userSelector } from 'src/store/features/auth/selectors'
import { setDrawerType } from 'src/store/features/common'
import { useAppDispatch, useAppSelector } from 'src/store/hook'

const SettingsFormComponent = ({formik}:any) => {

  const dispatch =  useAppDispatch();
  const user = useAppSelector(userSelector)

  return (
    <div className="bg-gray-400 rounded-xl">
       <div className="p-24 border-b border-border-color">
            <h4 className="text-white-heading text-16 font-semibold leading-24">Settings</h4>
       </div>
       <div className="p-24 flex flex-col gap-4">
            <form className="sm:max-w-[328px] w-full">
              <TextInput
              name='email'
              type='email'
              label='Email address'

              value={user?.email}
              showError={Boolean(formik?.touched.email)}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              errorMessage={formik?.errors.email}
              disabled={true}
            />
            </form>
            <div>
              <button onClick={() => dispatch(setDrawerType(2))} className="text-white-heading text-14 font-semibold leading-20 text-left">Change password</button>
            </div>
            <div>
              <CustomButton onClick={() => dispatch(setDrawerType(1))} title="Edit"></CustomButton>
            </div>
       </div>
    </div>
  )
}

export default SettingsFormComponent