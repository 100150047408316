import { useEffect, useState } from "react";

interface CustomDialogState {
  activeTab: number;
  handleTabsChange: (event: React.SyntheticEvent, newValue: number) => void;
  setActiveTab: any;
}

const useCustomTabs = (key: string, defaultTab?: number): CustomDialogState => {
  const [activeTab, setActiveTab] = useState<number>(() => {
    const storedTab = sessionStorage.getItem(key);
    return storedTab !== null ? parseInt(storedTab) : (defaultTab ?? 0);
  });

  useEffect(() => {
    sessionStorage.setItem(key, activeTab.toString());
  }, [key, activeTab]);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return {
    activeTab,
    handleTabsChange,
    setActiveTab,
  };
};

export default useCustomTabs;
