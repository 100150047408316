import React from "react";
import PanelLayout from "src/components/layouts/panel-layout";
import SettingsForm from "./elements/settings-form";

const Settings = () => {
  return (
    <PanelLayout wrapperClass={`px-24 py-20`}>
      <SettingsForm />
    </PanelLayout>
  );
};

export default Settings;
