

import { ButtonView, Plugin } from "ckeditor5";

export default class MediaResizePlugin extends Plugin {
    init() {
        const editor = this.editor;

        // Add resize buttons to the toolbar
        editor.ui.componentFactory.add('resizeMedia25', locale => {
            return this._createResizeButton(locale, '25%', '25%');
        });

        editor.ui.componentFactory.add('resizeMedia50', locale => {
            return this._createResizeButton(locale, '50%', '50%');
        });

        editor.ui.componentFactory.add('resizeMedia75', locale => {
            return this._createResizeButton(locale, '75%', '75%');
        });

        editor.ui.componentFactory.add('resizeMedia100', locale => {
            return this._createResizeButton(locale, '100%', '100%');
        });
    }

    _createResizeButton(locale:any, label:any, width:any) {
        const editor:any = this.editor;
        const view:any = new ButtonView(locale);

        view.set({
            label: label,
            tooltip: `Width ${label}`,
            isEnabled:true,
            withText:true,
            isVisible:false,
            class: 'custom-resize-button'
        });

        const updateButtonVisibility = () => {
            const selectedElement:any = editor.model.document.selection.getSelectedElement();   
            view.isVisible = selectedElement && selectedElement.name == 'media' 
            let selectedElementWidth:any  = selectedElement?.getAttribute('width')
            
            if(selectedElementWidth == view.label){
                const toolbarItems:any = editor?.ui?.view?.toolbar.items;
                toolbarItems.forEach((item: any) => {    
                    if (item.class == 'custom-resize-button') {
                        item.element.style.color = '#777777';
                    }
                }); 
                view.element.style.color = '#ffffff'
            }

        };

        this.listenTo(editor.model.document.selection, 'change:range', updateButtonVisibility);

        updateButtonVisibility();

        view.on('execute', () => {
            const mediaWidget = editor.model.document.selection.getSelectedElement();

            if (mediaWidget) {
                editor.model.change((writer:any) => {
                    writer.setAttribute('width', width, mediaWidget);
                });

                const toolbarItems:any = editor?.ui?.view?.toolbar.items;
                toolbarItems.forEach((item: any) => {    
                    if (item.class == 'custom-resize-button') {
                        item.element.style.color = '#777777';
                    }
                }); 
                view.element.style.color = '#ffffff';
            }
        });

        return view;
    }
}