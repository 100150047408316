import React from 'react'

const IcHome = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={16}
  height={16}
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    d="M13.8873 5.34262L9.52063 1.84928C8.66729 1.16928 7.33396 1.16262 6.48729 1.84262L2.12063 5.34262C1.49396 5.84262 1.11396 6.84262 1.24729 7.62928L2.08729 12.6559C2.28063 13.7826 3.32729 14.6693 4.46729 14.6693H11.534C12.6606 14.6693 13.7273 13.7626 13.9206 12.6493L14.7606 7.62262C14.8806 6.84262 14.5006 5.84262 13.8873 5.34262ZM8.50063 12.0026C8.50063 12.2759 8.27396 12.5026 8.00063 12.5026C7.72729 12.5026 7.50063 12.2759 7.50063 12.0026V10.0026C7.50063 9.72928 7.72729 9.50262 8.00063 9.50262C8.27396 9.50262 8.50063 9.72928 8.50063 10.0026V12.0026Z"
    fill="#BAB8B8"
  />
</svg>
  )
}

export default IcHome