import React from 'react'
import SelectInput from 'src/components/atoms/select-input'


const PublishDropdownComponent = ({value,handleOnSelect}:any) => {
  return (
    <SelectInput
    name="isPublish"
    value={value}
    options={[{ value: "0", label: "Hidden" }, { value: "1", label: "Published" }]}
    containerClassName="theme-form-select profle-status-dropdown"
    onSelect={handleOnSelect}
    popupClassName="profile-status-dropdown-listing"
  />
  )
}

export default PublishDropdownComponent