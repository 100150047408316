
import React from 'react'
import PersonalInfoComponent from './personal-info.component'

const PersonalInfoContainer = () => {



    return (

        <PersonalInfoComponent />
    )
}

export default PersonalInfoContainer