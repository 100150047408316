import {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
} from "react";
import { TOASTR_TYPES } from "src/types";
import { showToast } from "src/utils";
import { toast } from "react-toastify";

interface ContextProps {
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setToken: any;
  user: any;
  setUser: any;
  getProfile: () => void;
  handleLogout: () => void;
}

const AuthContext = createContext<ContextProps>({
  userId: "",
  setUserId: (): string => "",
  currentStep: 1,
  setCurrentStep: (): number => 1,
  setToken: (): string => "",
  user: {},
  setUser: {},
  getProfile: () => "",
  handleLogout: () => "",
});

export const AuthContextProvider = ({ children }: any) => {
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState();
  const [user, setUser] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const getProfile = () => {};

  const handleLogout = () => {
    setUser({});
    setTimeout(() => {
      showToast(
        toast,
        TOASTR_TYPES.SUCCESS,
        "You are logged out successfully!",
      );
    }, 200);
  };

  const value = useMemo(
    () => ({
      userId,
      setUserId,
      user,
      setUser,
      currentStep,
      setCurrentStep,
      getProfile,
      handleLogout,
      token,
      setToken,
    }),
    [
      userId,
      setUserId,
      user,
      setUser,
      currentStep,
      setCurrentStep,
      getProfile,
      handleLogout,
      token,
      setToken,
    ],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
