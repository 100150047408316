import React from 'react'
import SideBarComponent from './sidebar.component'
interface SideBarProps {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    ref?: any,
    setIsSidebarOpen?: any
}
const SideBarContainer = (props: SideBarProps) => {
    return (
        <SideBarComponent {...props} />
    )
}

export default SideBarContainer