import { Icon } from "src/components/atoms/icons";
import ShareButton from "src/components/molecules/share-button";
import { SHARE_TYPE } from "src/components/molecules/share-button/types";
import { POST_TYPES, TOASTR_TYPES, TODO } from "src/types";
import { formatDateForPost } from "src/components/molecules/crop-modal-easy/utils";
import { Link, useNavigate } from "react-router-dom";
import ConfirmDelete from "../../confirm-delete";
import useModal from "src/hooks/useModal";
import useLoadingError from "src/hooks/useLoadingError";
import { showToast } from "src/utils";
import { useDeleteArticleMutation } from "src/services/articles";
import { toast } from "react-toastify";

const ArticleHeaderComponent = ({ isPreview, data, isEditable }: TODO) => {
  const navigate = useNavigate();

  const [deleteArticle] = useDeleteArticleMutation();

  // for delete popup
  const { isOpen, toggle } = useModal();
  const { loading, startLoading, stopLoading } = useLoadingError();

  const confirmDelete = () => {
    startLoading();
    deleteArticle({ id: data?.id })
      .unwrap()
      .then((res) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        toggle();
        navigate(`/admin/library`);
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
        console.log(err, "error comes here");
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <div className="justify-between gap-4 md:flex md:p-30">
      <h1 className="break-words text-32 font-semibold text-white-heading">
        {data?.title}
      </h1>

      <div className="flex flex-wrap items-center justify-between gap-4">
        {!isPreview ? (
          <div className="flex gap-1 text-white">
            <p className="whitespace-nowrap text-right text-12 font-medium text-white-para">
              {data?.read_time_minutes} min read
              <span className="px-4 font-normal leading-7">|</span>
              {formatDateForPost(data?.created_at)}
              <span className="px-4 font-normal leading-7">|</span>
              {data?.view_count} views
            </p>

            {isEditable && (
              <>
                <span className="px-4 text-12 font-normal leading-7 text-white-para">
                  |
                </span>
                <button onClick={toggle}>
                  <Icon.IcDeletePost />
                </button>
                <Link to={`/admin/library/article/${data?.id}/edit`}>
                  <Icon.IcEditPost />
                </Link>
              </>
            )}
            {data?.status == POST_TYPES.PUBLISH && (
              <ShareButton
                shareType={SHARE_TYPE.ARTICLE}
                slug={data?.slug}
                image={data?.share_img}
                username={data?.title}
              />
            )}
          </div>
        ) : (
          <div className="flex gap-1 text-white">
            <p className="text-right text-12 font-medium text-white-para">
              {formatDateForPost(data?.created_at)}
            </p>
          </div>
        )}
      </div>
      <ConfirmDelete
        title="Are you sure you want to remove this article?"
        message="The data you delete cannot be accessed again"
        onConfirm={confirmDelete}
        toggle={toggle}
        isOpen={isOpen}
        loading={loading}
      />
    </div>
  );
};

export default ArticleHeaderComponent;
