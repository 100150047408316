import React from "react";

const IcLibrary = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="library_books_24px">
        <path
          id="icon/av/library_books_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66602 1.66797H16.666C17.5827 1.66797 18.3327 2.41797 18.3327 3.33464V13.3346C18.3327 14.2513 17.5827 15.0013 16.666 15.0013H6.66602C5.74935 15.0013 4.99935 14.2513 4.99935 13.3346V3.33464C4.99935 2.41797 5.74935 1.66797 6.66602 1.66797ZM1.66602 5.0013H3.33268V16.668H14.9993V18.3346H3.33268C2.41602 18.3346 1.66602 17.5846 1.66602 16.668V5.0013ZM6.66602 13.3346V3.33464H16.666V13.3346H6.66602ZM14.9993 7.5013H8.33268V9.16797H14.9993V7.5013ZM8.33268 10.0013H11.666V11.668H8.33268V10.0013ZM14.9993 5.0013H8.33268V6.66797H14.9993V5.0013Z"
          fill="#BAB8B8"
        />
      </g>
    </svg>
  );
};

export default IcLibrary;
