import React from 'react'

const IcEdit = () => {
  return (
    <svg
  xmlns="http://www.w3.org/2000/svg"
  width={20}
  height={20}
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M9.16602 1.66406H7.49935C3.33268 1.66406 1.66602 3.33073 1.66602 7.4974V12.4974C1.66602 16.6641 3.33268 18.3307 7.49935 18.3307H12.4993C16.666 18.3307 18.3327 16.6641 18.3327 12.4974V10.8307"
    stroke="#BAB8B8"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M13.3675 2.5203L6.80088 9.08696C6.55088 9.33696 6.30088 9.82863 6.25088 10.187L5.89254 12.6953C5.75921 13.6036 6.40088 14.237 7.30921 14.112L9.81754 13.7536C10.1675 13.7036 10.6592 13.4536 10.9175 13.2036L17.4842 6.63696C18.6175 5.50363 19.1509 4.18696 17.4842 2.5203C15.8175 0.853629 14.5009 1.38696 13.3675 2.5203Z"
    stroke="#BAB8B8"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M12.4258 3.46094C12.9841 5.4526 14.5424 7.01094 16.5424 7.5776"
    stroke="#BAB8B8"
    strokeWidth="1.5"
    strokeMiterlimit={10}
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

  )
}

export default IcEdit