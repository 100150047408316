import React from "react";
import ProfileTabsComponent from "./profile-tabs.component";

const ProfileTabsContainer = ({ handleTabsChange, activeTab }: any) => {
  const tabs = [
    { title: "Account" },
    {
      title: "Biography",
    },
    { title: "Posts" },
    {
      title: "Analytics",
    },
  ];

  return (
    <ProfileTabsComponent
      handleTabsChange={handleTabsChange}
      activeTab={activeTab}
      tabs={tabs}
    />
  );
};

export default ProfileTabsContainer;
