import React, { useEffect, useState } from "react";
import PublishDropdownComponent from "./publish-dropdown.component";
import { useAppSelector } from "src/store/hook";
import { otherUserSelector } from "src/store/features/user/selectors";
import {
  useGetUserMutation,
  usePublishUnpublishBioMutation,
} from "src/services/users";
import { checkForEmptyBioUser, getUserProfileStatus } from "src/helpers";
import { useParams } from "react-router-dom";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { TOASTR_TYPES } from "src/types";

const PublishDropdownContainer = () => {
  const otherUser = useAppSelector(otherUserSelector);

  const [value, setValue] = useState("");
  const { id: userId } = useParams();

  const [publishUnpublishBio] = usePublishUnpublishBioMutation();
  const [getUser] = useGetUserMutation();

  useEffect(() => {
    setValue(
      getUserProfileStatus(
        otherUser?.is_publish,
        otherUser?.admin_publish_hidden,
      ),
    );
  }, [otherUser]);

  const handleOnSelect = (e: string) => {
    if (e == "1" && checkForEmptyBioUser(otherUser)) {
      return;
    }

    if (e !== value) {
      publishUnpublishBio({ id: userId, publish: e })
        .unwrap()
        .then((res: any) => {
          getUser({ id: userId });
          showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        })
        .catch((err) => {
          showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
          console.log(err);
        });
    }
  };

  return (
    <PublishDropdownComponent value={value} handleOnSelect={handleOnSelect} />
  );
};

export default PublishDropdownContainer;
