import React from "react";

const Header = () => {
  return (
    <div className="rounded-t-xl border border-border-color bg-gray-400 p-24">
      <h5 className="mb-8 text-16 font-semibold leading-24 text-white-heading">
        Biography
      </h5>
      <p className="text-14 font-medium leading-24 text-white-heading">
        Fill in your users' professional background, accomplishments, and
        pertinent details to boost recognition.
      </p>
    </div>
  );
};

export default Header;
