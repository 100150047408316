import React from 'react'

const IcEye = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
               <path d="M2.42454 10.9763C2.1399 10.357 2.1399 9.64263 2.42454 9.02337C3.74213 6.15686 6.63878 4.1665 10.0003 4.1665C13.3618 4.1665 16.2585 6.15686 17.5761 9.02337C17.8607 9.64264 17.8607 10.357 17.5761 10.9763C16.2585 13.8428 13.3618 15.8332 10.0003 15.8332C6.63878 15.8332 3.74213 13.8428 2.42454 10.9763Z" stroke="#8C94A4" strokeWidth="1.5"/>
               <path d="M12.5003 9.99984C12.5003 11.3805 11.381 12.4998 10.0003 12.4998C8.6196 12.4998 7.50031 11.3805 7.50031 9.99984C7.50031 8.61913 8.6196 7.49984 10.0003 7.49984C11.381 7.49984 12.5003 8.61913 12.5003 9.99984Z" stroke="#8C94A4" strokeWidth="1.5"/>
</svg>
  )
}

export default IcEye