import { useArticlesContext } from "src/context/articles-context";
import { ARTICLE_ACTIONS, ARTICLE_STATUS, TOASTR_TYPES } from "src/types";
import useLoadingError from "./useLoadingError";
import { scrollToError } from "src/components/molecules/crop-modal-easy/utils";
import {
  useCreateArticleMutation,
  useUpdateArticleMutation,
} from "src/services/articles";
import { showToast } from "src/utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { hasEmptyArticleSections } from "src/helpers";

const useArticle = () => {
  const {
    formik,
    startLoading: startLoadingArticle,
    stopLoading: stopLoadingArticle,
  } = useArticlesContext();
  const { loading, startLoading, stopLoading } = useLoadingError();

  const [createArticle] = useCreateArticleMutation();
  const [updateArticle] = useUpdateArticleMutation();

  const navigate = useNavigate();

  const handleArticleAction = async (action: ARTICLE_ACTIONS) => {
    formik?.submitForm();
    const errors = await formik.validateForm();

    const isValid = Object.keys(errors).length !== 0;
    if (isValid) {
      return scrollToError();
    }

    if (hasEmptyArticleSections(formik?.values)) {
      return showToast(toast, TOASTR_TYPES.ERROR, "Contents is required");
    }

    const payload = {
      ...formik?.values,
      status: [ARTICLE_ACTIONS.PREVIEW, ARTICLE_ACTIONS.SAVE].includes(action)
        ? ARTICLE_STATUS.DRAFT
        : ARTICLE_STATUS.PUBLISH,
    };

    const API_HIT = payload?.id ? updateArticle : createArticle;
    console.log(formik?.values, "formik values comes herer>>>>>");
    startLoading();
    startLoadingArticle();
    API_HIT(payload)
      .unwrap()
      .then((res: any) => {
        showToast(toast, TOASTR_TYPES.SUCCESS, res?.message);
        if (action === ARTICLE_ACTIONS.PREVIEW) {
          navigate(`/admin/library/article/${res?.data?.id}?action=preview`);
          return;
        }
        navigate("/admin/library");
        formik?.resetForm();
      })
      .catch((err) => {
        showToast(toast, TOASTR_TYPES.ERROR, err?.data?.message);
      })
      .finally(() => {
        stopLoadingArticle();
        stopLoading();
      });
  };

  return { loading, handleArticleAction };
};

export default useArticle;
