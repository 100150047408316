"use client";
import React from "react";
import CustomButton from "src/components/atoms/button";
import { POST_ACTIONS } from "src/types";
import usePost from "src/hooks/usePost";

const PreviewPostBtnComponent = () => {
  const { loading, handlePostAction } = usePost();

  const handlePreview = () => {
    handlePostAction(POST_ACTIONS.PREVIEW);
  };

  return (
    <CustomButton
      title="Preview"
      variant="custom"
      className="relative rounded-xl border border-white-button px-24 py-8 text-14 font-medium leading-20 text-white-button transition duration-[0.4s] hover:bg-hover-secondary-button focus:outline-none"
      onClick={handlePreview}
      loading={loading}
    />
  );
};

export default PreviewPostBtnComponent;
