import React from "react";

const IcCross = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
    >
      <g opacity="0.6">
        <path
          d="M15 5.5L5 15.5M15 15.5L5 5.50001"
          stroke="#8C94A4"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default IcCross;
