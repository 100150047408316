import React from "react";
import MyPostsListing from "src/components/organisms/my-posts-listing";
import PersonalDetail from "src/components/organisms/profile/personal-detail";
import { otherUserSelector } from "src/store/features/user/selectors";
import { useAppSelector } from "src/store/hook";
import { TODO } from "src/types";

const PostsComponent = ({ show }: TODO) => {
  const otherUser = useAppSelector(otherUserSelector);

  return (
    <div
      className={`flex flex-col gap-6 bg-gray-500 p-24 px-0 md:px-24 xl:flex-row`}
    >
      <div className="order-3 mb-30 rounded-xl xl:order-2 xl:w-[83%]">
        <MyPostsListing title="All posts" />
      </div>
      <PersonalDetail profile={otherUser} isEditable={false} />
    </div>
  );
};

export default PostsComponent;
